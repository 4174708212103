import React, { useEffect, useState } from "react";
import Header from "./items/Header";
import { StyledBody } from "./styles/main.styles";
// import StepSite from "./items/StepSite";
import StepMeasurement from "./items/StepMeasurement";
import StepCabling from "./items/StepCabeling";
import StepSite from "./items/StepSite";
import StepImages from "./items/StepImages";
import { useLocation } from "react-router-dom";

function HyperOpticForm() {
  const [stepNumber, setStepNumber] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    // console.clear();
    // console.log("data", data);
  }, []);
  return (
    <>
      <Header step_number={stepNumber} />
      {data === null ? null : (
        <StyledBody>
          {stepNumber === 0 ? (
            <StepSite
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 1 ? (
            <StepCabling
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 2 ? (
            <StepMeasurement
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 3 ? (
            <StepImages
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
        </StyledBody>
      )}
    </>
  );
}

export default HyperOpticForm;
