import React, { useEffect, useState } from "react";
import "../../css/ShowUser.css";
import show from "../../image/add_user.svg";
import { Col, Modal, Row, Space, Spin, Switch, Table } from "antd";
// import axios from "axios";
import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import { useNavigate } from "react-router-dom";

function ShowUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [userId, setUserID] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("Active");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [edit, setEdit] = useState(false);

  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };
  const userData = async () => {
    setLoading(true);
    const user = localStorage.getItem("A55_Portal");
    const result = await axios
      .get(baseUrl + "/get_user?user_id=" + user, header)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    userData();
  }, []);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selection: Table.SELECTION_ALL,
  };

  const handleDelete = async () => {
    const user = localStorage.getItem("A55_Portal");

    if (selectedRowKeys.length > 0) {
      let data = { user_ids: selectedRowKeys };
      const result = await axios
        .post(baseUrl + "/deleteUsers", data, header)
        .then((res) => {
          openSweetAlert(res.data, "success");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          } else {
            openSweetAlert("Some thing went wrong", "error");
          }
        });
      userData();
    } else {
      openSweetAlert("No user selected", "error");
    }
  };

  const handleEditData = (user_id) => {
    axios
      .get(baseUrl + "/get_user_details?user_id=" + user_id, header)
      .then((res) => {
        setEmail(res.data.email);
        setName(res.data.name);
        setRole(res.data.role);
        setPassword(res.data.password);
        setStatus(res.data.status);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  };

  const showModal = (e, record = null) => {
    if (record) {
      setEdit(true);
      setUserID(record.user_id);
      handleEditData(record.user_id);
    } else {
      setEdit(false);
      setUserID("");
      setEmail("");
      setName("");
      setRole("");
      // setPassword("");
      setStatus("Active");
    }
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              color: "#45A044",
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontSize: "14px",
              letterSpacing: "0.03em",
              fontWeight: "500",
            },
          },
          children: (
            <div
              onClick={(e) => showModal(e, record)}
              style={{ cursor: "pointer" }}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const uploadData = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem("A55_Portal");
    let data = {
      admin: user,
      user_id: userId,
      user_name: name,
      email,
      password,
      role,
      status,
    };
    const result = await axios
      .post(baseUrl + "/post_user", data, header)
      .then((res) => {
        openSweetAlert(res.data, "success");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        } else if (err.response.status === 500)
          openSweetAlert(err.response.data, "error");
        else openSweetAlert("Some thing went wrong", "error");
      });
    userData();
  };

  return (
    <div className="user-main">
      <div className="User-div">
        <Row>
          {/* <Col xs={24} md={12} lg={1}>
          </Col> */}
          <Col xs={24} md={12} lg={21} style={{ display: "flex" }}>
            <img src={show} width="23px" height="23px" />
            <p className="heading-loc-div">Show User</p>
          </Col>
          <Col xs={12} md={12} lg={3} style={{ textAlign: "right" }}>
            <button id="add-user-btn" onClick={showModal}>
              + Add User
            </button>
          </Col>
        </Row>
        <Modal
          visible={isModalVisible}
          modalOptions={{ dismissible: true }}
          footer={false}
          closable={true}
          width={1000}
          keyboard={true}
          onCancel={handleCancel}
          bodyStyle={{ backgroundColor: "#fff" }}
        >
          <form onSubmit={uploadData}>
            <>
              <Row
                style={{
                  paddingBottom: "15px",
                  borderBottom: " 0.3px solid rgba(0, 0, 0, 0.3)",
                }}
              >
                <Col xs={22} md={24} lg={4}>
                  <div className="header-text">+ Add User</div>
                </Col>
                <Col xs={2} md={1} lg={18}></Col>
                <Col xs={22} md={24} lg={2}>
                  <Switch
                    checked={status === "Active"}
                    onChange={(checked, e) =>
                      setStatus(checked ? "Active" : "Inactive")
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <div className="r1-input">
                    <label className="label-div">User ID</label>
                    <input
                      style={{ padding: "10px" }}
                      id="name"
                      value={userId}
                      type="text"
                      onChange={(e) => setUserID(e.target.value)}
                      disabled={edit}
                      required
                    />
                  </div>
                </Col>
                <Col xs={2} md={2} lg={1}></Col>

                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <div className="r1-input">
                    <label className="label-div">Name</label>
                    <input
                      style={{ padding: "10px" }}
                      id="name"
                      value={name}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      required={true}
                    />
                  </div>
                </Col>

                <Col xs={2} md={2} lg={1}></Col>
                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <div className="r1-input">
                    <label className="label-div">Email Address</label>
                    <input
                      style={{ padding: "10px" }}
                      id="name"
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                    />
                  </div>
                </Col>
                <Col xs={2} md={2} lg={1}></Col>
                <>
                  <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                    <div className="r1-input">
                      <label className="label-div">Password</label>
                      <input
                        style={{ padding: "10px" }}
                        id="name"
                        value={password}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required={true}
                      />
                    </div>
                  </Col>
                  <Col xs={2} md={2} lg={1}></Col>
                </>
                <Col xs={22} md={12} lg={7}>
                  <div className="r1-input">
                    <label className="label-div">Role</label>
                    <select
                      style={{ padding: "5px" }}
                      id="name"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required={true}
                    >
                      <option value="" disabled selected hidden>
                        Select Role
                      </option>
                      <option value="Admin">Admin</option>
                      <option value="User">User</option>
                    </select>
                  </div>
                </Col>
                <Col xs={10} md={10} lg={10}></Col>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <Col xs={2} md={2} lg={10}></Col>
                <Col xs={22} md={12} lg={4}>
                  {!edit ? (
                    <button id="add-user-submit" type="submit">
                      + Add
                    </button>
                  ) : (
                    <button id="add-user-submit" type="submit">
                      + Edit
                    </button>
                  )}
                </Col>
                <Col xs={2} md={2} lg={10}></Col>
              </Row>
            </>
          </form>
        </Modal>
        <button
          style={{
            color: "white",
            background: "#d2042d",
            fontWeight: "bolder",
            padding: "5px 15px",
            border: "none",
            borderRadius: "5px",
            marginBottom: "10px",
            fontSize: "12px",
          }}
          onClick={handleDelete}
        >
          Delete
        </button>

        {/* <div className="table-div"> */}
        <Table
          style={{ borderTop: "0.8px solid rgba(0, 0, 0, 0.12)" }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          size="medium"
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          rowKey="user_id"
          loading={{
            indicator: (
              <Space size="large">
                <Spin size="large" />
              </Space>
            ),
            spinning: loading,
          }}
        />
      </div>
    </div>
  );
}

export default ShowUser;
