import React from "react";
// import logo from "../../../../image/Logo.svg";
import logo from "../../../../image/new_logo.png";
import { StyledHeader, StyledHeading, StyledText } from "../styles/main.styles";
import { Steps } from "antd";

function SiteForm({ step_number }) {
  const items = [
    {
      title: "Site",
    },
    {
      title: "Cabling",
    },
    {
      title: "Measurement",
    },
    {
      title: "Images",
    },
  ];
  return (
    <StyledHeader>
      <img className="map_logo" src={logo} height="201px" width="400px" />
      <StyledHeading>A55 Blockage Form</StyledHeading>
      <StyledText>Please fill in one form per Blockage</StyledText>
      <Steps current={step_number} labelPlacement="vertical" items={items} />
    </StyledHeader>
  );
}

export default SiteForm;
