import React from "react";
import { Form, Input } from "antd";
import {
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";
function TextField({
  onClick,
  number,
  heading,
  description,
  name,
  message,
  star,
  placeholder,
  disabled,
  rule,
  maxLength,
  removeValidation,
  comments,
}) {
  // const maxLength = 10;
  // const validateSpecialCharacters = (rule, value, callback) => {
  //   const specialCharactersRegex = /[!@#$%^&*(),_'/.?":{}|<>[\]]/;
  //   // const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>[\/\+]]/;
  //   if (specialCharactersRegex.test(value)) {
  //     callback(`Special characters are not allowed.`);
  //   } else {
  //     callback();
  //   }
  // };

  rule = rule
    ? rule
    : [
        {
          // required: removeValidation !== "true" ? true : null,
          required: removeValidation == "true" ? false : true,
          message: message,
        },
        // removeValidation !== "true"
        //   ? { validator: validateSpecialCharacters }
        //   : null,

        // {
        //    validator: validateSpecialCharacters
        // }
      ];
  return (
    <StyleTextDiv>
      {comments == "true" ? null : (
        <StyleNumberDiv>
          <StyledNumber>{number}</StyledNumber>
          <StyleNText> {heading}</StyleNText>
          {star ? <StyleRedStar>*</StyleRedStar> : null}
        </StyleNumberDiv>
      )}
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <Form.Item label="" name={name} rules={rule} disabled={disabled}>
        <Input
          size={"large"}
          disabled={disabled}
          allowClear={true}
          placeholder={placeholder}
          onKeyPress={onClick}
          maxLength={maxLength}
        />
      </Form.Item>
    </StyleTextDiv>
  );
}

export default TextField;
