import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import React, {useEffect, useState} from "react";

import * as L from "leaflet";
import mark from "../../image/a55.svg";
import network from "../../image/net.svg";
import dpole from "../../image/dpole.svg";
import axios, {baseUrl, header, removeStorage} from "../../utils/axios";
import {useNavigate} from "react-router-dom";

delete L.Icon.Default.prototype._getIconUrl;

const a55 = new L.Icon({
    iconRetinaUrl: mark,
    iconUrl: mark,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
});

const net = new L.Icon({
    iconRetinaUrl: network,
    iconUrl: network,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
});

const ras = new L.Icon({
    iconRetinaUrl: dpole,
    iconUrl: dpole,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
});

const dl = new L.Icon({
    iconRetinaUrl: dpole,
    iconUrl: dpole,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
});

function getIcons(form) {
    if (form[0] === "A") return a55;
    else if (form[0] === "N") return net;
    else if (form[0] === "D") return dl;
    else return ras;
}

function ReportsMap() {
    const [location, setLocation] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        const getLocations = async () => {
            await axios
                .get(
                    baseUrl +
                    "/get_location?user_id=" +
                    localStorage.getItem("A55_Portal"),
                    header
                )
                .then((res) => {
                    setLocation(res.data);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        removeStorage()
                        navigate('/login')
                    }

                });
        };
        getLocations();
    }, []);
    return (
        <div
            style={{
                width: "100%",
                height: "320px",
                padding: "10px",
                paddingBottom: "20px",
            }}
        >
            <MapContainer
                style={{height: "100%"}}
                center={[53.8008, -3.5491]}
                zoom={5}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {location.map((val, key) => {
                    return (
                        <Marker
                            key={key}
                            position={val["position"]}
                            icon={getIcons(val["form"])}
                        >
                            <Popup>{val["output"]}</Popup>
                        </Marker>
                    );
                })}
            </MapContainer>
        </div>
    );
}

export default ReportsMap;
