import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url("../../../image/BG.png"); */
  background: black;
  /* border: 1px solid red; */
`;

export const StyledDashboardContainer = styled.div`
  /* border: 1px solid green; */
  padding-top: 2.5%;
  margin-top: 5px;
  margin-left: 3%;
  margin-right: 3%;
  /* background: black; */
`;

export const StyledCard = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 120px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
`;

export const StyledCard2 = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 50px 20px;
  margin-bottom: 10px;
`;

export const StyledIcon = styled.div`
  width: 53px;
  height: 53px;
  background: rgba(198, 251, 137, 0.27);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCardText = styled.div`
  /* margin-top: 20px; */
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  /* line-height: 10px; */
  /* letter-spacing: 0.03em; */
  color: #a4a4a4;
`;

export const StyledNumber = styled.div`
  /* margin-top: 5px; */
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  /* letter-spacing: 0.03em; */
  color: #000000;
`;

export const StyledGraphCard = styled.div`
  height: 380px;
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
`;

export const StyledHeading = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.03em;
  margin-top: 3px;
  padding-left: 15px;
`;

export const StyledStatusFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: -30px;
  margin-right: 50px;
`;

export const StyledImg = styled.div`
  line-height: 27px;
`;

export const StyledBlockText = styled.div`
  margin-top: 3px;
  line-height: 27px;
  margin-left: 5%;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #000000;
`;

export const StyledDropDown = styled.select`
  margin-top: 3px;
  line-height: 27px;
  border: none;
  width: 120px;
  height: 25px;
  background: rgba(151, 151, 151, 0.06);
  border-radius: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #979797;
`;

export const StyledTimeContainer = styled.div`
  height: 380px;
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  margin-left: 10px;
`;

export const StyledDropDownTime = styled.select`
  border: none;
  width: 120px;
  height: 25px;
  background: rgba(151, 151, 151, 0.06);
  border-radius: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #979797;
  float: right;
  margin-top: -25px;
  margin-right: 20px;
`;

export const StyledMapContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  margin-top: 30px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  margin-right: 20%;
  float: right;
`;

export const StyledContent1 = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 50px;
  border-left: 2px solid #d9d9d9;
`;

export const StyledContentText = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #000000;
`;

export const StyledContentCount = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0.03em;
  color: #3d9f46;
`;

export const StyledCard3 = styled.div`
  text-align: center;
  padding: 8px;
  margin-bottom: 20px;
  font-size: 13px;
  background-color: ${(props) => (props.selected ? "#effedf" : "transparent")};
  color: ${(props) => (props.selected ? "#7fbe42" : "black")};
  border: ${(props) => (props.selected ? null : "1px solid #E3E3E3")};
  border-radius: 10px;
  font-weight: ${(props) => (props.selected ? "bolder" : "500")};
  cursor: pointer;
`;
