import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Space, Spin } from "antd";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);
const LineChart = ({ data }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (data) {
      
      setLoading(false);
    }
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        height: "280px",
        padding: "10px",
      }}
    >
      {loading ? (
        <Space size="large">
          <Spin size="large" />
        </Space>
      ) : (
        <Line
          options={options}
          data={{
            labels: data?.label,
            datasets: [
              {
                label: "A55",
                data: data["A55 BLOCKAGE FORM"],
                borderColor: "#A1B0FE",
                fill: true,
                backgroundColor: "transparent",
              },
              {
                label: "Network",
                data: data["NETWORK"],
                borderColor: "#ABE590",
                fill: true,
                backgroundColor: "transparent",
              },
              {
                label: "Raise",
                data: data["RAISE A CASE"],
                borderColor: "#38CBAA",
                fill: true,
                backgroundColor: "transparent",
              },
              {
                label: "D Pole",
                data: data["D POLE"],
                borderColor: "#9DD9EC",
                fill: true,
                backgroundColor: "transparent",
              },
            ],
          }}
        />
      )}
    </div>
  );
};
export default LineChart;
