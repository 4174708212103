import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Picture2 from "../../../../image/Pic1.png";
import ImageField from "../items/InnerItems/ImageField";
import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import tick from "../../../../image/animation.gif";
import { baseUrl } from "../../../../utils/axios";
import {
  BackButton,
  BackButtonText,
  NextButton,
} from "../../siteForm/styles/main.styles";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
const StepImagesHyp = ({ data, setStepNumber, setData }) => {
  const formRef = useRef();
  const [userID, setUserID] = useState(localStorage.getItem("A55_Portal"));

  const formatDate = (isoDateString) => {
    return moment(isoDateString).format("YYYY-MM-DD");
  };
  // const formattedData = { ...data };
  // if (formattedData.date) {
  //   formattedData.date = formatDate(formattedData.date);
  // }
  // const formData = {
  //   ...formattedData,
  //   user_id: userID,
  // };
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "state edit data");

  const [pic1, setPic1] = useState(state !== null ? [state["image_1"]] : []);
  const [pic2, setPic2] = useState(state !== null ? [state["image_2"]] : []);
  const [pic3, setPic3] = useState(state !== null ? [state["image_3"]] : []);
  const [pic4, setPic4] = useState(state !== null ? [state["image_4"]] : []);
  const [pic5, setPic5] = useState(state !== null ? [state["image_5"]] : []);
  const [pic6, setPic6] = useState(state !== null ? [state["image_6"]] : []);
  const [pic7, setPic7] = useState(state !== null ? [state["image_7"]] : []);
  const [pic8, setPic8] = useState(state !== null ? [state["image_8"]] : []);
  const [pic9, setPic9] = useState(state !== null ? [state["image_9"]] : []);
  const [pic10, setPic10] = useState(state !== null ? [state["image_10"]] : []);
  const [pic11, setPic11] = useState(state !== null ? [state["image_11"]] : []);

  // console.log("pic1", pic1);

  const [pic1Response, setPic1Response] = useState(["", ""]);
  const [pic2Response, setPic2Response] = useState(["", ""]);
  const [pic3Response, setPic3Response] = useState(["", ""]);
  const [pic4Response, setPic4Response] = useState(["", ""]);
  const [pic5Response, setPic5Response] = useState(["", ""]);
  const [pic6Response, setPic6Response] = useState(["", ""]);
  const [pic7Response, setPic7Response] = useState(["", ""]);
  const [pic8Response, setPic8Response] = useState(["", ""]);
  const [pic9Response, setPic9Response] = useState(["", ""]);
  const [pic10Response, setPic10Response] = useState(["", ""]);
  const [pic11Response, setPic11Response] = useState(["", ""]);

  const [messageApi, contextHolder] = message.useMessage();
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  const edit = localStorage.getItem("edit");

  const respId = localStorage.getItem("respId");
  const handleformClick = () => {
    navigate("/FormFile");
  };

  const nextStep = async (value) => {
    setLoading(true);
    const site = localStorage.getItem("siteSelectedValue");
    const area = localStorage.getItem("selectedValue");

    const formattedData = { ...data };
    if (formattedData.date) {
      formattedData.date = formatDate(formattedData.date);
    }
    try {
      const formValues = await formRef.current.validateFields();
      localStorage.removeItem("edit");

      // ================
      const pictures = [
        pic1,
        pic2,
        pic3,
        pic4,
        pic5,
        pic6,
        pic7,
        pic8,
        pic9,
        pic10,
        pic11,
      ];
      let uploadedCount = 0;

      for (let i = 0; i < pictures.length; i++) {
        if (pictures[i][0] !== undefined) {
          uploadedCount++;
        }
      }

      if (uploadedCount < 1) {
        openSweetAlert("Please upload at least 1 picture", "error");
        setLoading(false);
        return;
      }
      // ===============

      const formData = {
        ...formattedData,
        user_id: userID,
        site,
        area,
      };

      //====== Function to convert image URL to base64 =========
      const convertImageToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          try {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                resolve(reader.result.split(",")[1]); // Remove data URL prefix
              };
              reader.onerror = (error) => {
                reject(error);
              };
            } else {
              resolve("");
            }
          } catch (error) {
            reject(error);
          }
        });
      };

      // Array of pic URLs
      const picUrls = [
        pic1[0]?.originFileObj,
        pic2[0]?.originFileObj,
        pic3[0]?.originFileObj,
        pic4[0]?.originFileObj,
        pic5[0]?.originFileObj,
        pic6[0]?.originFileObj,
        pic7[0]?.originFileObj,
        pic8[0]?.originFileObj,
        pic9[0]?.originFileObj,
        pic10[0]?.originFileObj,
        pic11[0]?.originFileObj,
      ];
      // Convert all images to base64 asynchronously
      const base64Images = await Promise.all(
        picUrls.map((file) => convertImageToBase64(file))
      );

      // Update formData with base64 image strings
      base64Images.forEach((base64, index) => {
        formData[`image_${index + 1}`] = base64;
      });
      //  ====================================================

      // Now you can use formData to send to your API
      if (state) {
        const updateFormData = {
          ...formData,
          resp_id: respId,
        };
        await axios
          .post(baseUrl + `/mobileA55hyperoptic_web`, updateFormData)
          .then((response) => {
            console.log("Form Updated successfully:", response.data);
            openSweetAlert("Form Updated Successfully", "success");
            navigate("/FormFile");
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              navigate("/SelectForm");
              console.log(err);
            }
          });
      } else {
        await axios
          .post(baseUrl + `/mobileA55hyperoptic_web`, formData, {
            headers: {
              "Content-Type": "application/json", // Set content type header for multipart form data
            },
          })
          .then((response) => {
            console.log("Form submitted successfully:", response.data);
            openSweetAlert("Form Submitted Successfully", "success");
            navigate("/FormFile");
          })
          .catch((err) => {
            if (err.response.status === 401) {
              navigate("/SelectForm");
              console.log(err);
              setLoading(false);
            }
          });
      }
    } catch (errorInfo) {
      setLoading(false);
      console.log("Failed:", errorInfo);
    }
  };

  const handleGoBack = () => {
    setStepNumber(1);
  };

  return (
    <>
      {contextHolder}
      {/* <Header form_name={form} /> */}
      {/* <StyledBody> */}
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          onFinish={nextStep}
          onFinishFailed={() => {}}
          labelCol={{ span: 0 }}
          style={{ width: "100%" }}
          wrapperCol={{ span: 24 }}
          initialValues={state ? state : data}
          autoComplete="off"
        >
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={7}
                heading={"Picture 1"}
                description={"Please upload Picture 1"}
                star={true}
                name={`pic1`}
                multiple={false}
                fileList={pic1}
                setFileList={setPic1}
                // api={`/post_hyper_pic1${pic1}/1/${userID}`}
                response={pic1Response}
                setResponse={setPic1Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={8}
                heading={"Picture 2"}
                description={"Please upload Picture 2"}
                star={true}
                name={`pic2`}
                multiple={false}
                fileList={pic2}
                setFileList={setPic2}
                // api={`/post_hyper_pic2${pic2}/2/${userID}`}
                response={pic2Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={9}
                heading={"Picture 3"}
                description={"Please upload Picture 3"}
                star={true}
                name={`pic3`}
                multiple={false}
                fileList={pic3}
                setFileList={setPic3}
                // api={`/post_hyper_pic3${pic3}/3/${userID}`}
                response={pic3Response}
                setResponse={setPic3Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={10}
                heading={"Picture 4"}
                description={"Please upload Picture 4"}
                star={true}
                name={`pic4`}
                multiple={false}
                fileList={pic4}
                setFileList={setPic4}
                // api={`/post_hyper_pic4${pic4}/4/${userID}`}
                response={pic4Response}
                setResponse={setPic4Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={11}
                heading={"Picture 5"}
                description={"Please upload Picture 5"}
                star={true}
                name={`pic5`}
                multiple={false}
                fileList={pic5}
                setFileList={setPic5}
                // api={`/post_hyper_pic5${pic5}/5/${userID}`}
                response={pic5Response}
                setResponse={setPic5Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={12}
                heading={"Picture 6"}
                description={"Please upload Picture 6"}
                star={true}
                name={`pic6`}
                multiple={false}
                fileList={pic6}
                setFileList={setPic6}
                // api={`/post_hyper_pic6${pic6}/6/${userID}`}
                response={pic6Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={13}
                heading={"Picture 7"}
                description={"Please upload Picture 7"}
                star={true}
                name={`pic7`}
                multiple={false}
                fileList={pic7}
                setFileList={setPic7}
                // api={`/post_hyper_pic7${pic7}/7/${userID}`}
                response={pic7Response}
                setResponse={setPic7Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={14}
                heading={"Picture 8"}
                description={"Please upload Picture 8"}
                star={true}
                name={`pic8`}
                multiple={false}
                fileList={pic8}
                setFileList={setPic8}
                // api={`/post_hyper_pic8${pic8}/8/${userID}`}
                response={pic8Response}
                setResponse={setPic8Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>

          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={15}
                heading={"Picture 9"}
                description={"Please upload Picture 9"}
                star={true}
                name={`pic9`}
                multiple={false}
                fileList={pic9}
                setFileList={setPic9}
                // api={`/post_hyper_pic9${pic9}/9/${userID}`}
                response={pic9Response}
                setResponse={setPic9Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={16}
                heading={"Picture 10"}
                description={"Please upload Picture 10"}
                star={true}
                name={`pic10`}
                multiple={false}
                fileList={pic10}
                setFileList={setPic10}
                // api={`/post_hyper_pic10${pic10}/10/${userID}`}
                response={pic10Response}
                setResponse={setPic10Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={17}
                heading={"Picture 11"}
                description={"Please upload Picture 11"}
                star={true}
                name={`pic11`}
                multiple={false}
                fileList={pic11}
                setFileList={setPic11}
                // api={`/post_hyper_pic11${pic11}/11/${userID}`}
                response={pic11Response}
                setResponse={setPic11Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
          </Row>

          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginRight: "30px" }}
            >
              <NextButton>
                <BackButton
                  // onClick={() => {
                  //   console.log("hello");
                  //   navigate(data["link"]);
                  // }}
                  onClick={handleGoBack}
                >
                  <BackButtonText>Back</BackButtonText>
                </BackButton>
                <Form.Item wrapperCol={{}}>
                  <Button
                    // onClick={() => {
                    //     console.log(formData, "formData");
                    //   navigate(data["link"]);
                    // }}
                    className="next_btn_network"
                    // onClick={nextStep}
                    type="primary"
                    size={"large"}
                    htmlType="submit"
                    style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                  >
                    Next
                  </Button>
                </Form.Item>
              </NextButton>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Modal visible={spin} footer={false} closable={false} centered={true}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        visible={success}
        footer={false}
        closable={false}
        bodyStyle={{ backgroundColor: "#fff" }}
      >
        <div className="i-modal-box">
          <img src={tick} width="200px" />
          {/* <div className="i-modal-head-text">{response}</div> */}
          <div className="i-modal-text">Thank you for filling the form</div>
          <Link to="/SelectForm" id="sf-link-tag">
            <button id="i-done-btn">Done</button>
          </Link>
        </div>
      </Modal>
      {/* </StyledBody> */}
    </>
  );
};

export default StepImagesHyp;
