import React from "react";
import { Checkbox, Form, Radio } from "antd";
import {
  StyleCheckDiv,
  StyleDescText,
  StyledNumber,
  StyleLocalDiv,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";

function Surface({
  number,
  heading,
  description,
  name,
  message,
  star,
  surface,
}) {
  console.log(star, "star");
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <Form.Item
        label=""
        name={name}
        rules={[
          {
            required: true,
            message: message,
          },
        ]}
      >
        <Radio.Group
          name="work"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          onChange={(e) =>
            localStorage.setItem("surface_value", e.target.value)
          }
        >
          <StyleLocalDiv style={{ marginBottom: 20 }}>
            <Radio value={"Tarmac"}>Tarmac</Radio>
          </StyleLocalDiv>
          <StyleLocalDiv style={{ marginBottom: 20 }}>
            <Radio value={"Footway Slabs"}>Footway Slabs</Radio>
          </StyleLocalDiv>
          <StyleLocalDiv>
            <Radio value={"Carriageway"}>Carriageway</Radio>
          </StyleLocalDiv>
          <StyleLocalDiv>
            <Radio value={"Soft"}>Soft</Radio>
          </StyleLocalDiv>
        </Radio.Group>
      </Form.Item>
      <StyleCheckDiv>
        <Form.Item
          name="looks"
          valuePropName="checked"
          style={{ marginTop: "25px" }}
        >
          <Checkbox>
            Looks New (Please tick if the above selected surface is new)
          </Checkbox>
        </Form.Item>
      </StyleCheckDiv>
    </StyleTextDiv>
  );
}

export default Surface;
