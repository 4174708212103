import React, { useEffect, useState } from "react";
import { Button, Col, Form, Radio, Row } from "antd";
import TextField from "./InnerItems/TextField";
import {
  BackButton,
  BackButtonText,
  NextButton,
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
} from "../styles/main.styles";
import measureImg from "../../../../image/Line.png";
import Measurement from "./InnerItems/MeasurementField";
import RadioField from "./InnerItems/RadioField";
import { useLocation } from "react-router-dom";

function SiteForm({ data, setStepNumber, setData }) {
  const { state } = useLocation();

  const [form] = Form.useForm();

  const nextStep = (values) => {
    setData({ ...data, ...values });
    window.scrollTo(0, 0);
    setStepNumber(3);
  };

  return (
    <Form
      onFinish={nextStep}
      // onFinishFailed={onFinishFailed}
      labelCol={{ span: 0 }}
      style={{ width: "100%" }}
      wrapperCol={{ span: 24 }}
      initialValues={state ? state : data}
      autoComplete="off"
      form={form}
    >
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={39}
            heading={"Was the customer present? Y/N "}
            name="customer_present"
            description={"Select your answer"}
            message={"Please select your answer"}
            // star={true}
            radioData={["Yes", "No"]}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={40}
            heading={"Traffic management required Y/N:"}
            name="traffic_management_required"
            description={"Select your answer"}
            message={"Please Select your answer"}
            // star={true}
            radioData={["Yes", "No"]}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={41}
            heading={"TFL red route Y/N:"}
            name="tfl_red_route"
            message={"Please enter TFL red route"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={42}
            heading={"Footway closure required:"}
            name="footway_closure_required"
            message={"Please enter Footway closure required"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={43}
            heading={"Temp Footway required:"}
            name="temp_footway_required"
            message={"Please enter Temp Footway required"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={44}
            heading={"Pedestrian crossing affected:"}
            name="pedestrian_crossing_affected"
            message={"Please enter Pedestrian crossing affected"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={45}
            heading={"Lane Closure Required:"}
            name="lane_closure_required"
            message={"Please enter Lane Closure Required"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={46}
            heading={"No of ways for temp lights:"}
            name="no_of_ways_for_temp_lights"
            message={"Please enter No of ways for temp lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={47}
            heading={"Bus stop suspension required:"}
            name="bus_stop_suspension_required"
            message={"Please enter Bus stop suspension required"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={48}
            heading={"Road Closure Required:"}
            name="road_closure_required"
            message={"Please enter Road Closure Required"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={49}
            heading={"Private Property"}
            name="private_property"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={50}
            heading={"Was the customer present?"}
            name="customer_present"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={51}
            heading={"Has verbal conscent been granted?"}
            name="verbal_consent_granted"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={52}
            heading={"OOH Required"}
            name="ooh_required"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={53}
            heading={"Has photo showing parking bay no been attached"}
            name="photo_showing_parking_bay_attached"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={54}
            heading={
              "How many potential UPRNs can be served within the scope of required civils (including customer):"
            }
            name="potential_uprns_can_be_served"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <RadioField
            number={55}
            heading={
              "How many UPRNs are currently served within the scope of required civils:"
            }
            name="uprns_currently_served"
            message={"Please enter Traffic Lights"}
            // star={true}
            radioData={["Yes", "No"]}
            description={"Select your answer"}
          />
        </Col>
      </Row>

      <NextButton>
        <BackButton
          onClick={() => {
            setStepNumber(1);
          }}
        >
          <BackButtonText>Back</BackButtonText>
        </BackButton>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            size={"large"}
            htmlType="submit"
            style={{ minWidth: 175, height: 55 }}
          >
            Next
          </Button>
        </Form.Item>
      </NextButton>
    </Form>
  );
}

export default SiteForm;
