import React from "react";
import { Button, Col, Form, Row } from "antd";
import TextField from "./InnerItems/TextField";
import { BackButton, BackButtonText, NextButton } from "../styles/main.styles";
import DateField from "./InnerItems/DateField";
import RadioField from "./InnerItems/RadioField";
import Surface from "./InnerItems/Surface";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LocationField from "./InnerItems/LocationField";

const phoneRegex = /^[0-9]+$/;
const maxTelephoneLength = 15;

const validatePhoneNumber = (_, value, callback) => {
  if (value && value.length !== maxTelephoneLength) {
    callback(`Telephone number must be ${maxTelephoneLength} digits.`);
  } else {
    callback();
  }
  if (!value) {
    return Promise.reject("Please enter valid Telephone No");
  }
  if (!phoneRegex.test(value)) {
    return Promise.reject("Please enter a valid phone number.");
  }
  return Promise.resolve();
};

function SiteForm({ data, setStepNumber, setData }) {
  data["date"] = data["date"] === "" ? "" : moment(data["date"]);
  console.log(data["looks"]);
  const navigate = useNavigate();
  const nextStep = (values) => {
    console.log(values, "valuEs site");
    try {
      data.date = values.date.format("YYYY-MM-DD");
    } catch (er) {}
    data.exchange = values.exchange;
    data.local_authority = values.local_authority;
    data.onsite_eng = values.onsite_eng;
    data.pia_order_no = values.pia_order_no;
    data.site = values.site;
    data.surface = [values.surface, values.looks === "" ? 0 : 1];
    data.telephone_no = values.telephone_no;
    data.work_required = values.work_required;
    delete data["post_code_lat"];
    delete data["post_code_long"];
    data.lat = values.post_code_lat;
    data.lng = values.post_code_long;
    setData(data);
    window.scrollTo(0, 0);
    setStepNumber(1);
    // console.clear();
    console.log(data, "data after");
  };
  const onFinishFailed = (errorInfo) => {};
  console.log(data, "see data");
  const handleKeyPress = (event) => {
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };
  return (
    <>
      <Form
        onFinish={nextStep}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 0 }}
        style={{ width: "100%" }}
        wrapperCol={{ span: 24 }}
        initialValues={data}
        autoComplete="off"
      >
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={1}
              heading={"site"}
              name="site"
              placeholder={"Enter site here"}
              message={"Please enter site"}
              star={true}
              description={""}
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={2}
              heading={"cluster"}
              name="area"
              placeholder={"Enter cluster here"}
              message={"Please enter cluster"}
              star={true}
              description={""}
              disabled={true}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <RadioField
              number={3}
              heading={"worked Required"}
              name="work_required"
              placeholder={"Enter  here"}
              message={"Please select worked required in this cluster"}
              star={true}
              description={
                "Select whether this A55 is for Ancillary Works or Duct Blockage"
              }
              radioData={["Ancillary", "Blockage", "Desilt"]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={4}
              heading={"Exchange"}
              name="exchange"
              placeholder={"Enter exchange here"}
              message={"Please enter Exchange"}
              star={true}
              description={"Enter exchange name"}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={5}
              heading={"Onsite Engineer"}
              name="onsite_eng"
              placeholder={"Enter onsite engineer here"}
              message={"Please enter Onsite Engineer"}
              star={true}
              description={"Enter details of onsite engineer"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={6}
              heading={"Telephone No"}
              name="telephone_no"
              placeholder={"Enter telephone here"}
              star={true}
              message={"Please enter valid Telephone No"}
              description={"Enter onsite engineers telephone number"}
              maxLength={maxTelephoneLength}
              onClick={handleKeyPress}
              rule={[
                {
                  required: true,
                  message: "Please enter valid Telephone No",
                  validator: validatePhoneNumber,
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={7}
              heading={"PIA Order No"}
              name="pia_order_no"
              placeholder={"Enter PIA order no here"}
              message={"Please enter PIA Order No"}
              star={true}
              description={
                "Enter the Openreach Duct & Pole Sharing order number that this request refers to"
              }
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <DateField
              number={8}
              heading={"Date"}
              name="date"
              message={"Please enter Date"}
              star={true}
              description={"Enter the date you submitted this request"}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Surface
              number={9}
              heading={"Surface"}
              name="surface"
              placeholder={"Enter surface here"}
              message={"Please select a Surface"}
              star={true}
              description={
                "Please enter surface type eg F/W Slabs or C/W Tarmac etc Please also tick the 'Looks New' box if the surface looks newly laid in past 12 months as this may raise potential noticing issues"
              }
              // surface={data.surface}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={10}
              heading={"Local Authority"}
              name="local_authority"
              placeholder={"Enter local authority here"}
              message={"Please enter Local Authority"}
              star={true}
              description={"Enter details of the Local Authority/Council"}
            />
            <div style={{ marginTop: "20px" }}>
              <LocationField
                number={11}
                heading={"Location"}
                name1="post_code_lat"
                name2="post_code_long"
                placeholder1={"Enter latitude of blockage"}
                placeholder2={"Enter Longitude of blockage"}
                message1={"Please enter latitude"}
                message2={"Please enter Longitude"}
                star={true}
                description={"Enter details of blockage location"}
                // onClick={handleKeyPress}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginRight: "30px" }}
          >
            <NextButton>
              <BackButton
                onClick={() => {
                  navigate(data["link"]);
                }}
              >
                <BackButtonText>Back</BackButtonText>
              </BackButton>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  type="primary"
                  size={"large"}
                  htmlType="submit"
                  style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                >
                  Next
                </Button>
              </Form.Item>
            </NextButton>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SiteForm;
