import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Items/Header";
import { StyledBody } from "./styles/main.styles";
import moment from "moment/moment";
import TextField from "./Items/TextField";
import DateField from "./Items/DateField";
import LocationField from "./Items/LocationField";
import RadioField from "./Items/RadioField";
import Picture2 from "../../../image/Pic1.png";
import ImageField from "./Items/ImageField";
import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import tick from "../../../image/animation.gif";
import { baseUrl } from "../../../utils/axios";
import {
  BackButton,
  BackButtonText,
  NextButton,
} from "../siteForm/styles/main.styles";
import Swal from "sweetalert2";

import { BackwardOutlined } from "@ant-design/icons";
import axios from "axios";

const form = localStorage.getItem("form_selected]");
const site = localStorage.getItem("siteSelectedValue");
const area = localStorage.getItem("selectedValue");
// console.log(site, area, "site and area");
const Index = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const location = useLocation();
  // const { site, area } = location.state || {};
  const d1 = new Date();
  const [startTime, setTime] = useState([0, 0, 0]);
  useEffect(() => {
    setTime([d1.getHours(), d1.getMinutes(), d1.getSeconds()]);
  }, []);

  if (state && Object.keys(state).includes("date")) {
    state["date"] = state["date"] === "" ? "" : moment(state["date"]);
  }

  const [data, setData] = useState(
    state ? state : { form_selected: localStorage.getItem("form_selected") }
  );

  console.log(state, "state");
  const [userID, setUserID] = useState(localStorage.getItem("A55_Portal"));
  const [pic1, setPic1] = useState(state ? [state["pic_one"]] : []);
  const [pic2, setPic2] = useState(state ? [state["pic_two"]] : []);
  const [pic3, setPic3] = useState(state ? [state["pic_third"]] : []);
  const [pic4, setPic4] = useState(state ? [state["pic_forth"]] : []);
  console.log(pic1, "pic 1");

  const [pic1Response, setPic1Response] = useState(["", ""]);
  const [pic2Response, setPic2Response] = useState(["", ""]);
  const [pic3Response, setPic3Response] = useState(["", ""]);
  const [pic4Response, setPic4Response] = useState(["", ""]);

  const [messageApi, contextHolder] = message.useMessage();
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(
    state === null || state["date"] === ""
      ? "Successfully submitted your response"
      : "Successfully edited your response"
  );

  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  const formRef = useRef();

  const edit = localStorage.getItem("edit");

  const respId = localStorage.getItem("respId");

  const nextStep = async (value) => {
    // console.log(value, "valuesssss");
    const site = localStorage.getItem("siteSelectedValue");
    const area = localStorage.getItem("selectedValue");
    try {
      const formValues = await formRef.current.validateFields();
      const formattedDate = moment(formValues.date, "YYYY-M-D")
        .utc()
        .format("YYYY-M-D");
      if (state) {
        // updateNetwork?resp_id=44
        console.log(pic1[0], "ppppiccc 1");

        if (pic1[0] == undefined) {
          openSweetAlert("upload picture 1", "error");
        } else if (pic2[0] == undefined) {
          openSweetAlert("upload picture 2", "error");
        } else if (pic2[0] == undefined) {
          openSweetAlert("upload pictur 3", "error");
        } else if (pic3[0] == undefined) {
          openSweetAlert("upload picture 4", "error");
        } else {
          const formData = {
            ...formValues,
            pic_one: pic1[0].response,
            pic_two: pic2[0].response,
            pic_third: pic3[0].response,
            pic_forth: pic4[0].response,
            site: site,
            area: area,
            date: formattedDate,
            user_id: userID,
            resp_id: respId,
          };
          const d1 = new Date(); // End time
          formData["end_time"] = [
            d1.getHours(),
            d1.getMinutes(),
            d1.getSeconds(),
          ];

          const d2 = new Date(); // Start time
          formData["start_time"] = [
            d2.getHours(),
            d2.getMinutes(),
            d2.getSeconds(),
          ];

          await axios
            .post(baseUrl + "/post_network", formData)
            .then((res) => {
              localStorage.removeItem("edit");

              console.log(res, "response");
              openSweetAlert(res.data.out, "success");

              navigate("/SelectForm");
            })
            .catch((err) => {
              if (err.response.status === 401) {
                navigate("/SelectForm");
                console.log(err);
              }
            });
        }
      } else {
        var dt = new Date();
        console.log(pic1[0], "ppppiccc 1");
        if (pic1[0] == undefined) {
          openSweetAlert("upload picture 1", "error");
        } else if (pic2[0] == undefined) {
          openSweetAlert("upload picture 2", "error");
        } else if (pic3[0] == undefined) {
          openSweetAlert("upload pictur 3", "error");
        } else if (pic4[0] == undefined) {
          openSweetAlert("upload picture 4", "error");
        } else {
          const formData = {
            ...formValues,
            pic_one: pic1[0].response,
            pic_two: pic2[0].response,
            pic_third: pic3[0].response,
            pic_forth: pic4[0].response,
            date: formattedDate,
            start_time: startTime,
            end_time: [dt.getHours(), dt.getMinutes(), dt.getSeconds()],
            site: site,
            area: area,
            user_id: userID,
          };
          // if (data.form_selected === "NETWORK ADJUSTMENT") {

          await axios
            .post(baseUrl + "/post_network", formData)
            .then((res) => {
              console.log(res, "response");
              openSweetAlert("Form Submitted Successfully", "success");
              navigate("/SelectForm");
            })
            .catch((err) => {
              if (err.response.status === 401) {
                // removeStorage();
                navigate("/SelectForm");
                console.log(err);
              }
            });
        }
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const error = (errorMsg) => {
    messageApi.open({
      type: "error",
      content: errorMsg,
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // const handleKeyPress = (event) => {
  //   const isValidInput = /^[0-9\b]+$/.test(event.key);

  //   if (!isValidInput) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <>
      <Button className="back_btn" key="cancel" onClick={handleGoBack}>
        <span>Go Back</span>
      </Button>
      {contextHolder}
      <Header form_name={data.form_selected} />
      <StyledBody>
        <Form
          ref={formRef}
          onFinish={nextStep}
          onFinishFailed={() => {}}
          labelCol={{ span: 0 }}
          style={{ width: "100%" }}
          wrapperCol={{ span: 24 }}
          initialValues={data}
          autoComplete="off"
        >
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={1}
                heading={"Exchange"}
                name="exchange"
                placeholder={"Enter exchange of your Project here"}
                message={"Please enter exchange"}
                star={true}
                // description={"Enter Exchange of your Project"}
                // onChange={(value) => handleFieldChange("exchange", value)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={2}
                heading={"CRF"}
                name="crf"
                placeholder={"Enter CRF here"}
                message={"Please enter CRF"}
                star={true}
                // description={"Enter CRF of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={3}
                heading={"OR Reference"}
                name="or_reference"
                placeholder={"Enter OR Reference here"}
                message={"Please enter OR Reference"}
                star={true}
                // description={"Enter OR Reference of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={4}
                heading={"NOI"}
                name="noi"
                placeholder={"Enter NOI here"}
                message={"Please enter NOI"}
                star={true}
                // description={"Enter NOI of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={5}
                heading={"Issue"}
                name="issue"
                placeholder={"Enter issue here"}
                message={"Please enter issue"}
                star={true}
                // description={"Enter issue of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <DateField
                number={6}
                heading={"Date"}
                name="date"
                message={"Please enter Date"}
                star={true}
                // description={"Enter the date you submitted this request"}
                // onChange={(value) => handleFieldChange("date", value)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={7}
                heading={"CP Name"}
                name="cp_name"
                placeholder={"Enter your Project CP Name here"}
                message={"Please enter CP Name"}
                star={true}
                // description={"Enter CP Name of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={8}
                heading={"ID Pole"}
                name="id_pole"
                placeholder={"Enter your Project ID pole here"}
                message={"Please enter ID pole"}
                star={true}
                // description={"Enter ID pole of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={9}
                heading={"Grid Reference"}
                name="grid_reference"
                placeholder={"Enter Grid Reference of your Project here"}
                message={"Please enter Grid Reference"}
                star={true}
                // description={"Enter Grid Reference of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <LocationField
                number={10}
                heading={"Location"}
                name1="post_code_lat"
                name2="post_code_long"
                placeholder1={"Enter latitude of blockage"}
                placeholder2={"Enter Longitude of blockage"}
                message1={"Please enter latitude"}
                message2={"Please enter Longitude"}
                // star={true}
                // onClick={handleKeyPress}
                // description={"Enter details of blockage location"}
                // onChange1={(value) => handleFieldChange("post_code_lat", value)}
                // onChange2={(value) =>
                //   handleFieldChange("post_code_long", value)
                // }
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextField
                number={11}
                heading={"Address"}
                name="address"
                placeholder={"Enter your Project Address here"}
                message={"Please enter Address"}
                star={true}
                // description={"Enter Address of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={12}
                heading={"DP ID"}
                name="dp_id"
                placeholder={"Enter DP ID here"}
                message={"Please enter DP ID"}
                star={true}
                // description={"Enter DP ID of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={13}
                heading={"Second Issue"}
                name="secondIssue"
                placeholder={"Enter second issue here"}
                message={"Please enter second issue"}
                star={true}
                // description={"Enter second issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={14}
                heading={"Issue Note"}
                name="issue_note"
                placeholder={"Enter Issue Note here"}
                message={"Please enter Issue Note"}
                star={true}
                // description={"Enter Issue Note of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={15}
                heading={"Furniture Issue"}
                name="furniture_issue"
                placeholder={"Enter Furniture Issue here"}
                message={"Please enter Furniture Issue"}
                star={true}
                // description={"Enter Furniture Issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={16}
                heading={"Forcost Wires"}
                name="forcost_wires"
                placeholder={"Enter Forcost Wires here"}
                message={"Please enter Forcost Wires"}
                star={true}
                // description={"Enter Forcost Wires of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={17}
                heading={"Exciting Wires"}
                name="exciting_wires"
                placeholder={"Enter Exciting Wires here"}
                message={"Please enter Exciting Wires"}
                star={true}
                // description={"Enter Exciting Wires of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={18}
                heading={"Max Pole Capacity"}
                name="max_pole_capacity"
                placeholder={"Enter max pole capacity here"}
                message={"Please enter max pole capacity"}
                star={true}
                // description={"Enter max pole capacity of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <RadioField
                number={19}
                heading={"Issue"}
                name="third_issue"
                placeholder={"Enter select one issue"}
                message={"Please select one issue"}
                star={true}
                radioData={[
                  "Pole Top Capacity",
                  "Pole to Pole",
                  "DropWire Capacity",
                  "Defective pole",
                ]}
                // description={
                //   "Indicate whether works will be on private property"
                // }
                // onChange={(value) => handleFieldChange("third_issue", value)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={20}
                heading={"Picture 1"}
                description={"Please upload Picture 1"}
                star={true}
                name={`pic1`}
                multiple={false}
                fileList={pic1}
                setFileList={setPic1}
                api={`/post_pic1/${pic1.length + 1}/${userID}`}
                response={pic1Response}
                setResponse={setPic1Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={21}
                heading={"Picture 2"}
                description={"Please upload Picture 2"}
                star={true}
                name={`pic2`}
                multiple={false}
                fileList={pic2}
                setFileList={setPic2}
                api={`/post_pic2/${pic2.length + 1}/${userID}`}
                response={pic2Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={20}
                heading={"Picture 3"}
                description={"Please upload Picture 3"}
                star={true}
                name={`pic3`}
                multiple={false}
                fileList={pic3}
                setFileList={setPic3}
                api={`/post_pic3/${pic3.length + 1}/${userID}`}
                response={pic3Response}
                setResponse={setPic3Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={21}
                heading={"Picture 4"}
                description={"Please upload Picture 4"}
                star={true}
                name={`pic4`}
                multiple={false}
                fileList={pic4}
                setFileList={setPic4}
                api={`/post_pic4/${pic4.length + 1}/${userID}`}
                response={pic4Response}
                setResponse={setPic4Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginRight: "30px" }}
            >
              <NextButton>
                <BackButton
                  // onClick={() => {
                  //   console.log("hello");
                  //   navigate(data["link"]);
                  // }}
                  onClick={handleGoBack}
                >
                  <BackButtonText>Back</BackButtonText>
                </BackButton>
                <Form.Item wrapperCol={{}}>
                  <Button
                    // onClick={() => {
                    //     console.log(formData, "formData");
                    //   navigate(data["link"]);
                    // }}
                    className="next_btn_network"
                    // onClick={nextStep}
                    type="primary"
                    size={"large"}
                    htmlType="submit"
                    style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                  >
                    Next
                  </Button>
                </Form.Item>
              </NextButton>
            </Col>
          </Row>
        </Form>
        <Modal visible={spin} footer={false} closable={false} centered={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        </Modal>
        <Modal
          visible={success}
          footer={false}
          closable={false}
          bodyStyle={{ backgroundColor: "#fff" }}
        >
          <div className="i-modal-box">
            <img src={tick} width="200px" />
            <div className="i-modal-head-text">{response}</div>
            <div className="i-modal-text">Thank you for filling the form</div>
            <Link to="/SelectForm" id="sf-link-tag">
              <button id="i-done-btn">Done</button>
            </Link>
          </div>
        </Modal>
      </StyledBody>
    </>
  );
};

export default Index;
