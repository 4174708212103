import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Modal, Input, Button, Select, Switch } from "antd";
import axios, {baseUrl, removeStorage} from "../../../utils/axios";
import {useNavigate} from "react-router-dom";

const OrgEditModal = ({ isModalVisible, setIsModalVisible, editRecord }) => {
  const { Option } = Select;

  const regex = "^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|[1][012])-[0-9]{4}$";
  let [device, setDevice] = useState(editRecord);
  let selectedOrg = JSON.parse(localStorage.getItem("selected_org"));
  let [organizationName, setOrganizationName] = useState(selectedOrg?.org_name);
  let [userLimit, setUserLimit] = useState(selectedOrg?.user_limit);
  let [adminLimit, setAdminLimit] = useState(selectedOrg?.admin_limit);
  let [status, setStatus] = useState(selectedOrg?.status);
  const navigate = useNavigate()
  const post = async (data) => {
    try {
      await axios
        .post(baseUrl + "/organization/edit", data)
        .then((response) => {
          alert("Updated Successfully");
          setIsModalVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage()
            navigate("/login")
          }
          alert("something went wrong");
        });
    } catch (err) {
      if (err.response.status === 401) {
        removeStorage()
        navigate("/login")
      }
      alert("something went wrong");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      org_id: selectedOrg.org_id,
      admin_user_no: adminLimit,
      user_no: userLimit,
      status,
    };
    post(data);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      style={{
        marginTop: "30px",
        zIndex: "99999",
        background: "white",
        borderRadius: "15px",
      }}
      width="50%"
      title=""
      closable={false}
      visible={isModalVisible}
      footer=""
    >
      <form
        onSubmit={handleSubmit}
        style={{
          marginBottom: "-20px",
        }}
      >
        <Row gutter={15}>
          <Col span={24} style={{ textAlign: "left" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "15px", fontWeight: "bolder" }}>
                Edit Organization
              </p>
              <Switch
                checked={status}
                onChange={(checked, e) => setStatus(checked)}
              />
            </div>
          </Col>
          <Col span={12}>
            <InputWrapper>
              <div>
                Organization Name: &nbsp;<span style={{ color: "red" }}>*</span>
              </div>
              <StyledInput
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                required
                disabled
              />
            </InputWrapper>
          </Col>

          <Col span={6}>
            <InputWrapper>
              <div>
                User Limit: &nbsp;<span style={{ color: "red" }}>*</span>
              </div>
              <Select
                value={userLimit}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setUserLimit(value);
                }}
              >
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="15">15</Option>
              </Select>
            </InputWrapper>
          </Col>
          <Col span={6}>
            <InputWrapper>
              <div>
                Admin Limit: &nbsp;<span style={{ color: "red" }}>*</span>
              </div>
              <Select
                value={adminLimit}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setAdminLimit(value);
                }}
              >
                <Option value="3">3</Option>
                <Option value="5">5</Option>
                <Option value="7">7</Option>
              </Select>
            </InputWrapper>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <div style={{ height: "20px" }}></div>
            <StyledButton color={"red"} onClick={handleCancel}>
              Cancel
            </StyledButton>
            &nbsp; &nbsp;{" "}
            <StyledSubmitButton color={"#3D9F46"} type="submit" value="Edit" />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

const StyledInput = styled(Input)`
  height: 2rem;
`;

const InputWrapper = styled.div`
  font-size: 12px;
  white-space: nowrap;
  /* display: flex;
  justify-content: space-between; */
  padding-bottom: 10px;
`;

const StyledSubmitButton = styled(Input)`
  font-size: 11px;
  font-weight: bolder;
  width: 15%;
  /* font-family: Montserrat-Regular; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;
  &:focus,
  &:hover {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    color: white;
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  height: 27px;
  font-size: 11px;
  font-weight: bolder;
  width: 15%;
  /* font-family: Montserrat-Regular; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;
  &:focus,
  &:hover {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    color: white;
    opacity: 0.8;
  }
`;

export default OrgEditModal;
