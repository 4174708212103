import React, { useEffect, useState } from "react";
// import "../selectArea/selectArea.css";
import logo from "../../../image/new_logo.png";
import { useNavigate } from "react-router-dom";
import axios, { baseUrl, header, removeStorage } from "../../../utils/axios";
import { Modal, Radio, Space, Spin, Button } from "antd";
import { BackwardOutlined } from "@ant-design/icons";
import {
  NextButton,
  StyledHeader,
  StyledLogo,
  StyledSelectInput,
  StyledTitle,
  StyleRadioDiv,
  SubTitle,
} from "./styles/main.styles";

function SelectArea() {
  const navigate = useNavigate();
  const [site, setSite] = useState([]);
  const [chooseForm, setChooseForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [siteValue, setSiteValue] = useState("");
  const [getUserResponseArea, setGetUserResponseArea] = useState();
  // console.log(siteValue, "site value");
  // console.log(areaList, "area value");
  const loginDataString = localStorage.getItem("loginData");
  const loginData = JSON.parse(loginDataString);
  const form = localStorage.getItem("form_selected");
  const user = localStorage.getItem("A55_Portal");
  const [value, setValue] = useState("");
  // testing
  console.log(form);
  const onChangeAreaList = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    async function siteForm() {
      setLoading(true);
      const result = await axios
        .get(
          baseUrl +
            "/get_user_response_sites?user_id=" +
            user +
            "&form=" +
            form,
          header
        )
        .then((res) => {
          setLoading(false);
          setSite(res.data["sites"]);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    }

    siteForm();
  }, []);
  const handleChange = async (e) => {
    setSiteValue(e.target.value);
    setLoading(true);
    await axios
      .get(
        baseUrl +
          "/get_user_response_area?user_id=" +
          user +
          "&form=" +
          form +
          "&site=" +
          e.target.value,
        header
      )
      .then((res) => {
        setGetUserResponseArea(res.data.location_details[0]);
        setAreaList(res.data["area"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  };
  // console.log(value, "siteValuesiteValue");
  const NextPage = () => {
    localStorage.setItem("siteSelectedValue", siteValue);
    localStorage.setItem("selectedValue", value);
    const d1 = new Date();
    console.log("form...", form);
    if (form === "A55 BLOCKAGE FORM") {
      navigate("/SiteForm", {
        state: {
          apx_view: "",
          area: value,
          blockageComments: "",
          bore: "",
          box_A_type: "",
          box_B_type: "",
          chamber1: "",
          chamber2: "",
          cp_def_ref: "",
          date: "",
          duct_section_no: "",
          duct_type: getUserResponseArea?.duct_type,
          end_time: [],
          exchange: getUserResponseArea?.exchange,
          google_satellite_view: "",
          l1_carriageway_type: "Meter",
          l1_carriageway: 0,
          l1_footway: 0,
          l1_footway_type: "Meter",
          l1_soft: 0,
          l1_soft_type: "Meter",
          l2_carriageway_type: "Meter",
          l2_carriageway: 0,
          l2_footway: 0,
          l2_footway_type: "Meter",
          l2_soft: 0,
          l2_soft_type: "Meter",
          l3_carriageway_type: "Meter",
          l3_carriageway: 0,
          l3_footway: 0,
          l3_footway_type: "Meter",
          l3_soft: 0,
          l3_soft_type: "Meter",
          local_authority: getUserResponseArea?.local_auth,
          no_of_cables_in_bore: "",
          onsite_eng: form == "A55 BLOCKAGE FORM" ? loginData.name : "",
          os_ref: "",
          percentage_bore_full: getUserResponseArea?.percentage_bore_full,
          photo_chamber_1_1: "",
          photo_chamber_1_2: "",
          photo_chamber_1_3: "",
          photo_chamber_1_4: "",
          photo_chamber_2_1: "",
          photo_chamber_2_2: "",
          photo_chamber_2_3: "",
          photo_chamber_2_4: "",
          pia_order_no: getUserResponseArea?.pia_noi,
          post_code_lat: "",
          post_code_long: "",
          private_property: "Yes",
          proposed_cable: "",
          proposed_sub_duct_diameter: "",
          site: siteValue,
          start_time: [d1.getHours(), d1.getMinutes(), d1.getSeconds()],
          surface: "Tarmac",
          telephone_no: getUserResponseArea?.tel_no,
          traffic_light: getUserResponseArea?.survey_required,
          user_id: localStorage.getItem("A55_Portal"),
          work_required: "Ancillary",
          link: "/SelectArea",
        },
      });
    } else if (form === "NETWORK ADJUSTMENT") {
      localStorage.setItem("siteSelectedValue", siteValue);
      localStorage.setItem("selectedValue", value);
      navigate(
        "/Network"
        // {
        //   state: {
        //     site: siteValue,
        //     area: value,
        //   },
        // }
      );
    } else if (form === "D - Pole") {
      localStorage.setItem("siteSelectedValue", siteValue);
      localStorage.setItem("selectedValue", value);
      navigate("/d-pole");
    } else if (form === "Tree Cutting") {
      localStorage.setItem("siteSelectedValue", siteValue);
      localStorage.setItem("selectedValue", value);
      navigate("/tree-cutting");
    } else if (form === "Hyper Optics") {
      localStorage.setItem("siteSelectedValue", siteValue);
      localStorage.setItem("selectedValue", value);
      navigate("/hyper-optic");
    } else {
      console.log(form, "form type");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Button
        className="back_btn"
        key="cancel"
        onClick={() => navigate("/SelectForm")}
        // onClick={handleGoBack}
      >
        {/* <BackwardOutlined /> */}
        <span>Go Back</span>
      </Button>
      <StyledHeader>
        <StyledLogo>
          <img className="map_logo" src={logo} height="201px" width="400px" />
        </StyledLogo>
        <StyledTitle>{form}</StyledTitle>
        <SubTitle>Please select your area</SubTitle>

        <StyledSelectInput required={true} onChange={handleChange}>
          <option value="" disabled selected hidden>
            Select Site
          </option>
          {site.map((val, key) => {
            return (
              <option key={key} value={val}>
                {val}
              </option>
            );
          })}
        </StyledSelectInput>
        {/* <br /> */}
        <div
          style={{
            width: "40%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {chooseForm === ""
            ? areaList.map((val, key) => {
                return val !== "" ? (
                  <StyleRadioDiv>
                    <Radio.Group
                      key={key}
                      onChange={onChangeAreaList}
                      value={value}
                      className={"name"}
                    >
                      <Radio value={val}>{val}</Radio>
                    </Radio.Group>
                  </StyleRadioDiv>
                ) : null;
              })
            : null}
        </div>
        {/* {console.log(siteValue, value, "hhhhhhhhhhhhh")} */}
        <div style={{ textDecoration: "none" }}>
          {siteValue !== "" && value !== "" ? (
            <NextButton onClick={NextPage}>Next</NextButton>
          ) : (
            <NextButton disabled onClick={NextPage}>
              Next
            </NextButton>
          )}
        </div>
      </StyledHeader>
      <Modal visible={loading} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </>
  );
}

export default SelectArea;
