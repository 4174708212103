import styled from "styled-components";
export const StyledUserContainer = styled.div`
  margin-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
`;
export const StyledUserHeading = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #2d2f35;
  margin-left: 10px;
`;
export const StyledModalHeading = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #000000;
`;
export const StyledAddUserButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 40px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  border: none;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  float: right;
`;
export const Button = styled.button`
  width: 130px;
  height: 45px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  border: none;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #ffffff;
`;
export const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledLabel = styled.label`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 7px;

  &:after {
    content: "*";
    color: #e90830;
  }
`;
export const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 8px;
  margin-left: 8px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledSelectInput = styled.select`
  padding: 5px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 8px;
  margin-left: 8px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
