import styled from "styled-components";
export const StyledAccessContainer = styled.div`
  margin-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
`;
export const StyledAccHeading = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #2d2f35;
  margin-left: 10px;
`;
export const StyledAccBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  margin-left: 18%;
  margin-right: 18%;
`;
export const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledLabel = styled.label`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.04em;
  margin-bottom: 5px;
  margin-left: 2px;
  color: #2d2f35;
`;
export const StyledSelectInput = styled.select`
  padding-left: 16px;
  padding-right: 16px;
  height: 52px;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(164, 164, 164, 0.4);
  border-radius: 5px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledBox = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 5px;
  flex-direction: row;
  width: 145px;
  align-items: center;
  justify-content: center;
  height: 49px;
  background: #ffffff;
  border: 1px solid rgba(164, 164, 164, 0.4);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;
export const StyledBoxText = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-top: -1px;
  margin-left: 10px;
  // width: 100%;
`;
export const StyledSubmitBtn = styled.button`
  width: 180px;
  height: 50px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: none;
  margin-left: 40%;
  margin-bottom: 20%;
`;
