import React, { useEffect, useState } from "react";
import Header from "./items/Header";
import { StyledBody } from "./styles/main.styles";
import StepSite from "./items/StepSite";
import StepMeasurement from "./items/StepMeasurement";
import StepCabeling from "./items/StepCabeling";
import StepImages from "./items/StepImages";
import { useLocation } from "react-router-dom";

function SiteForm() {
  const { state } = useLocation();
  console.log(state, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
  const [stepNumber, setStepNumber] = useState(0);
  const [data, setData] = useState(state);

  useEffect(() => {
    // console.clear();
    console.log("data", data);
  }, []);
  return (
    <>
      <Header step_number={stepNumber} />
      {data === null ? null : (
        <StyledBody>
          {stepNumber === 0 ? (
            <StepSite
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 1 ? (
            <StepCabeling
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 2 ? (
            <StepMeasurement
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
          {stepNumber === 3 ? (
            <StepImages
              data={data}
              setStepNumber={setStepNumber}
              setData={setData}
            />
          ) : (
            ""
          )}
        </StyledBody>
      )}
    </>
  );
}

export default SiteForm;
