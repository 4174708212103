import React, { useEffect, useState } from "react";
import "../../css/Dashboard.css";
import clipboard from "../../image/clipboard-text.svg";
import info from "../../image/info-circle.svg";
import flag from "../../image/flag-2.svg";
import folder from "../../image/folder-connection.svg";
import clock from "../../image/timer.svg";
import block1 from "../../image/Vector-1.svg";
import block2 from "../../image/Vector-2.svg";
import block3 from "../../image/Vector-3.svg";
import block4 from "../../image/Vector.svg";
import BarChart from "../Admin/BarChart";
import ReportsMap from "./ReportsMap";
import { Col, Row } from "antd";
// import axios from "axios";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import LineChart from "./LineChart";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [userid, setUserid] = useState("");
  const [a55, setA55] = useState(0);
  const [d_pole, setDPole] = useState(0);
  const [raise, setRaise] = useState(0);
  const [net, setNet] = useState(0);
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState("month");
  const [timeSelected, setTimeSelected] = useState("week");
  useEffect(() => {
    async function CardsData() {
      const user = localStorage.getItem("A55_Portal");
      setUserid(user);
      const result = await axios
        // http://127.0.0.1:5001
        .get(baseUrl + "/get_cards?user_id=" + user, header)
        .then((res) => {
          setA55(res.data["a55"]);
          setDPole(res.data["d_pole"]);
          setNet(res.data["network"]);
          setRaise(res.data["rac"]);
          setTime(res.data["avg_time"]);
          setTotal(
            res.data["a55"] +
              res.data["d_pole"] +
              res.data["network"] +
              res.data["rac"]
          );
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    }

    const _ = CardsData();
  }, []);
  return (
    <div className="main">
      <div className="Dashboard-div">
        <Row>
          <Col xs={22} md={10} lg={4}>
            <div className="bb-2">
              <div className="icon">
                <img src={clipboard} height="40px" width="40px" />
              </div>
              <div className="d-text">A55 Blockage Form</div>
              <div className="number">{a55}</div>
            </div>
          </Col>
          <Col xs={2} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <div className="bb-2">
              <div className="icon">
                <img src={info} height="40px" width="40px" />
              </div>
              <div className="d-text">Raise A Case</div>
              <div className="number">{raise}</div>
            </div>
          </Col>
          <Col xs={2} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <div className="bb-2">
              <div className="icon">
                <img src={flag} height="40px" width="40px" />
              </div>
              <div className="d-text">D - Pole</div>
              <div className="number">{d_pole}</div>
            </div>
          </Col>
          <Col xs={2} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <div className="bb-2">
              <div className="icon">
                <img src={folder} height="40px" width="40px" />
              </div>
              <div className="d-text">Network Adjustment</div>
              <div className="number">{net}</div>
            </div>
          </Col>
          <Col xs={2} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <div className="bb-2">
              <div className="icon">
                <img src={clock} height="40px" width="40px" />
              </div>
              <div className="d-text">Average Time</div>
              <div className="number">{time} min</div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px", marginRight: "13px" }}>
          <Col xs={24} md={14} xl={14}>
            <div className={"barChart"}>
              <div className={"header-bar"}>
                <label className={"header-bar-label"}>Status</label>
                <div className={"barName"}>
                  <div className={"barItem"}>
                    <img src={block1} width={13} />
                    <label className={"bar-item-label"}>A55</label>
                  </div>
                  <div className={"barItem"}>
                    <img src={block3} width={13} />
                    <label className={"bar-item-label"}>D Pole</label>
                  </div>
                  <div className={"barItem"}>
                    <img src={block4} width={13} />
                    <label className={"bar-item-label"}>Raise A Case</label>
                  </div>
                  <div className={"barItem"}>
                    <img src={block2} width={13} />
                    <label className={"bar-item-label"}>
                      Network Adjustment
                    </label>
                  </div>
                </div>
                <select
                  id="drop-down"
                  value={statusSelected}
                  onChange={(e) => setStatusSelected(e.target.value)}
                >
                  <option value="week">Week</option>
                  <option value="month"> Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
              <BarChart statusSelected={statusSelected} />
            </div>
          </Col>
          <Col xs={24} md={10} lg={10}>
            <div className="r2-b2" style={{ padding: "20px" }}>
              <div>
                <div className="status">Time Admitted</div>
                <select
                  id="drop-down-time"
                  value={timeSelected}
                  onChange={(e) => setTimeSelected(e.target.value)}
                >
                  <option value="today"> Today</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
              <br />
              <div>
                <LineChart timeSelected={timeSelected} />
              </div>
            </div>
          </Col>
        </Row>

        <div className="r3-box" style={{ padding: "20px" }}>
          <div className="status">Reports By Map</div>
          {/* <br /> */}
          <Row style={{ marginTop: "5px" }}>
            <Col xs={16} md={16} lg={16}>
              <ReportsMap />
            </Col>
            <Col xs={8} md={8} lg={8}>
              <div className="content">
                <div className="content-1">
                  <div className="content-text">Response</div>
                  <div className="content-count">{total}</div>
                </div>
                <div className="content-1" style={{ marginTop: "30px" }}>
                  <div className="content-text">Average Time To Complete</div>
                  <div className="content-count">{time}</div>
                </div>
                <div className="content-1" style={{ marginTop: "30px" }}>
                  <div className="content-text">Status</div>
                  <div className="content-count">Active</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Dashboard;
