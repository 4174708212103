import styled from "styled-components";
export const StyledLocationContainer = styled.div`
  margin-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledLocHeading = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #2d2f35;
  margin-left: 10px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledLocBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  margin-left: 18%;
  margin-right: 18%;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledLabel = styled.label`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.04em;
  margin-bottom: 5px;
  margin-left: 2px;
  color: #2d2f35;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledSelectInput = styled.select`
  padding-left: 16px;
  padding-right: 16px;
  height: 52px;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(164, 164, 164, 0.4);
  border-radius: 5px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledInput = styled.input`
  height: 52px;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(164, 164, 164, 0.4);
  border-radius: 5px;
  padding: 16px;
  color: black;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
  box-shadow: #3d9f46;
`;
export const StyledButton = styled.button`
  width: 110px;
  height: 51px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #3d9f46;
  // margin-top: 10px;
  border: 1px solid #3d9f46;
  padding: 0px;
  border-radius: 5px;
  background: #f5f5f5;
  float: right;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledTagsInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border-radius: 6px;
  margin-top: 10px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledAddLocBtn = styled.button`
  width: 180px;
  height: 50px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: none;
  margin-left: 40%;
  margin-bottom: 10px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
