import React from "react";
import { DatePicker, Form } from "antd";
import {
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../styles/main.styles";

function DateField({ number, heading, description, name, message, star }) {
  console.log(number, "date name");
  const dateFormat = "YYYY-MM-DD";
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <Form.Item
        label=""
        rules={[
          {
            required: true,
            message: message,
          },
        ]}
        name={name}
      >
        <DatePicker
          size={"large"}
          style={{ width: "100%" }}
          format={dateFormat}
          inputReadOnly={true}
        />
      </Form.Item>
      {/*<Form.Item*/}
      {/*    label=""*/}
      {/*    name={"dateOfBirth"}*/}
      {/*    rules={[*/}
      {/*        {*/}
      {/*            required: true,*/}
      {/*            message: message,*/}
      {/*        },*/}
      {/*    ]}*/}
      {/*    valuePropName='date'*/}
      {/*>*/}
      {/*    <DatePicker size={"large"} style={{width: '100%'}} format="YYYY-MM-DD"/>*/}
      {/*</Form.Item>*/}
    </StyleTextDiv>
  );
}

export default DateField;
