import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Chart, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import {Bar} from "react-chartjs-2";
import {Space, Spin} from "antd";

Chart.defaults.datasets.bar.barThickness = 15;
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function BarChart(props) {
    const [loading, setLoading] = useState(true);
    const [barChart, setBarChart] = useState(props?.data);
    useEffect(() => {
        if (props.data) {
            setLoading(false);
        }
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "300px",
                width: "100%",
                padding: "10px",
            }}
        >
            {props.data === undefined ? (
                <Space size="large">
                    <Spin size="large"/>
                </Space>
            ) : (
                <Bar
                    // options={CHARTOPTIONS}
                    data={{
                        labels: Object.keys(props.data),
                        datasets: [
                            {
                                // label: "A55",
                                // data: barChart["a55"],
                                data: Object.values(props.data),
                                backgroundColor: ["#A1B0FE", "#9DD9EC", "#38CBAA", "#ABE690"],
                                borderRadius: "20",
                                minBarLength: 1,
                                barThickness: 40,
                            },
                            // {
                            //   // label: "D Pole",
                            //   // data: barChart["d_pole"],
                            //   data: [0, 2, 0, 0],

                            //   backgroundColor: "#9DD9EC",
                            //   borderRadius: "20",
                            //   minBarLength: 1,
                            // },
                            // {
                            //   // label: "Raise a case",
                            //   // data: barChart["rase"],
                            //   data: [0, 0, 3, 0],

                            //   backgroundColor: "#38CBAA",
                            //   borderRadius: "20",
                            //   minBarLength: 1,
                            // },
                            // {
                            //   // label: "Network",
                            //   // data: barChart["network"],
                            //   data: [0, 0, 0, 4],

                            //   backgroundColor: "#ABE690",
                            //   borderRadius: "20",
                            //   minBarLength: 1,
                            // },
                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                },
                            },
                            y: {
                                grid: {
                                    display: false,
                                },
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 1,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    }}
                />
            )}
        </div>
    );
}

export default BarChart;
