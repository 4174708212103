import React, { useState } from "react";
import { Button, Input, Spin } from "antd";
import { LeftOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/axios";
import Swal from "sweetalert2";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showOTPFields, setShowOTPFields] = useState(false);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const otpString = otpDigits.join("");
  console.log(otpString, "otpString");
  console.log(otpDigits, "otpDigits");
  const handleEmailSubmit = async () => {
    setLoading(true);
    const res = await axios.post(baseUrl + "/forgot-password", {
      email: email,
    });
    console.log(res, "res");
    if (res.status == "200" && res.data.message) {
      setLoading(false);

      setShowOTPFields(true);
    } else {
      setLoading(false);
      Swal.fire({
        title: res.data.error,
        type: "error",
        allowOutsideClick: false,
      });
    }
  };

  const submitOTP = async () => {
    setLoading(true);

    if (otpString !== "") {
      try {
        const res = await axios.post(baseUrl + "/verify-token", {
          token: otpString,
        });
        console.log(res);
        if (res.status === 200 && res.data.message) {
          localStorage.setItem("email", email);
          setLoading(false);
          navigate("/change-password");
        } else {
          setLoading(false);
          Swal.fire({
            title: res.data.error,
            type: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        setLoading(false);

        console.error("Error submitting OTP:", error);
      }
    } else {
      Swal.fire({
        title: "Please! enter OTP",
        type: "error",
        allowOutsideClick: false,
      });
      setLoading(false);
    }
  };
  const handleOtpChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);
  };
  const goBack = () => {
    if (showOTPFields) {
      setShowOTPFields(false);
    } else {
      navigate("/login");
    }
  };
  return (
    <Spin size="large" spinning={loading}>
      <div>
        <Button className="back_btn_forgot_password" onClick={goBack}>
          <LeftOutlined style={{ fontSize: "14px" }} />
        </Button>
        <div>
          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "100px", color: "#2F302F" }}>
              <p style={{ fontSize: "22px", fontWeight: 700 }}>
                Forgot password
              </p>
              {showOTPFields ? (
                <>
                  <p>Tokens code has been sent to email address {email}</p>
                </>
              ) : (
                <p>Please enter your email to reset password</p>
              )}
            </div>
            {!showOTPFields && (
              <Input
                style={{
                  width: "400px",
                  height: "40px",
                  margin: "0 auto",
                  borderRadius: "10px",
                  borderColor: "#6EB544",
                  marginBottom: "80px",
                }}
                type="email"
                name="email"
                placeholder="Email"
                //   value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                suffix={<MailOutlined style={{ color: "#6EB544" }} />}
              />
            )}
            <br />
            {showOTPFields && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  marginBottom: "80px",
                }}
              >
                {otpDigits.map((digit, index) => (
                  <>
                    {index === 0 ? "" : "-"}
                    <Input
                      key={index}
                      style={{
                        width: "60px",
                        height: "50px",
                        margin: "0 5px",
                        borderRadius: "10px",
                        borderColor: "#6EB544",
                        textAlign: "center",
                      }}
                      type="text"
                      name={`otp-${index}`}
                      placeholder="OTP"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      maxLength={"1"}
                    />
                  </>
                ))}
              </div>
            )}
            <br />
            {showOTPFields ? (
              <>
                <Button
                  style={{
                    width: "395px",
                    height: "40px",
                    borderRadius: "10px",
                    fontWeight: 700,
                  }}
                  type="primary"
                  block
                  onClick={submitOTP}
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    width: "395px",
                    height: "40px",
                    borderRadius: "10px",
                    fontWeight: 700,
                  }}
                  type="primary"
                  block
                  onClick={handleEmailSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};
