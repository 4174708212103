import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const MapLocation = ({ setMapLocation }) => {
  const [currentLocation, setCurrentLocation] = useState(center);

  useEffect(() => {
    const fetchData = async () => {
      console.log(currentLocation, "current location");

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setMapLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (err) => console.log(err),
          { enableHighAccuracy: true }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };
    fetchData();
  }, []);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBJXTWPUm_mR90kfdIr7CWCm3QgSrWuons" // Replace with your Google Maps API Key
      async
    ></LoadScript>
  );
};

export default MapLocation;
