import React, {useEffect, useState} from "react";
import {Form, Input, InputNumber, Select} from "antd";
import {
    StyleDescText,
    StyledNumber,
    StyleNText,
    StyleNumberDiv,
    StyleRedStar,
    StyleTextDiv
} from "../../styles/main.styles";


function Measurement({number, heading, description, name, message, star, placeholder, form}) {
    const [measure, setMeasure] = useState("m")
    return (
        <StyleTextDiv>
            <StyleNumberDiv>
                <StyledNumber>{number}</StyledNumber>
                <StyleNText> {heading}</StyleNText>
                {star ? <StyleRedStar>*</StyleRedStar> : null}
            </StyleNumberDiv>
            <StyleNumberDiv>
                <StyleDescText>{description}</StyleDescText>
            </StyleNumberDiv>
            <StyleNumberDiv>
                <Form.Item
                    label=""
                    name={name}
                    style={{marginRight: 5}}
                >
                    <InputNumber style={{width:"100%"}} size={"large"} placeholder={placeholder}/>
                </Form.Item>
                <Form.Item
                    label=""
                    name={name + "_type"}
                    rules={[
                        {
                            required: false
                        },
                    ]}
                >
                    <Select options={[
                        {
                            value: 'Meter',
                            label: 'Meter',
                        }, {
                            value: 'Inches',
                            label: 'Inches',
                        }, {
                            value: 'Foot',
                            label: 'Foot',
                        },
                    ]} size={"large"} style={{width: 120}}/>
                </Form.Item>
            </StyleNumberDiv>
        </StyleTextDiv>
    )
}

export default Measurement;