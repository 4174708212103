import React from "react";
import { Button, Col, Form, Row } from "antd";
import TextField from "./InnerItems/TextField";
import RadioField from "./InnerItems/RadioField";
import BlockageField from "./InnerItems/BlockageField";
import { BackButton, BackButtonText, NextButton } from "../styles/main.styles";
import surface from "./InnerItems/Surface";
import CustomSelector from "./InnerItems/selector";
import { useLocation } from "react-router-dom";

function StepCabling({ data, setStepNumber, setPercentage, setData }) {
  console.log(data, "data vallues in box");
  const { state } = useLocation();

  const nextStep = (values) => {
    console.log("values", values);
    setData({ ...data, ...values });
    window.scrollTo(0, 0);
    setStepNumber(2);
  };
  const onFinishFailed = (errorInfo) => {};

  const handleKeyPress = (event) => {
    // Allow only numbers (0-9) and specific keyboard keys (like backspace)
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];
  return (
    <Form
      onFinish={nextStep}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 0 }}
      style={{ width: "100%" }}
      wrapperCol={{ span: 24 }}
      initialValues={state ? state : data}
      autoComplete="off"
    >
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={19}
            heading={"No of ways for Temp Lights:"}
            name="no_of_ways_for_temp_lights"
            placeholder={"Enter your answer"}
            message={"Please enter your answer"}
            star={true}
            description={"Enter enter your answer"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={20}
            heading={"Distance from chamber 1:"}
            name="distance_from_chamber1"
            placeholder={"Enter distance for chamber 1"}
            message={"Please Enter distance for chamber 1"}
            star={true}
            description={"Enter distance for chamber 1"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={21}
            heading={"Distance from chamber 2:"}
            name="distance_from_chamber2"
            placeholder={"Enter distance for chamber 2"}
            message={"Please Enter distance for chamber 2"}
            star={true}
            description={"Enter distance for chamber 2"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={22}
            heading={"Distance from chamber 3:"}
            name="distance_from_chamber3"
            placeholder={"Enter distance for chamber 3"}
            message={"Please Enter distance for chamber 3"}
            star={true}
            description={"Enter distance for chamber 3"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={23}
            heading={"Distance from chamber 4:"}
            name="distance_from_chamber4"
            placeholder={"Enter distance for chamber 4"}
            message={"Please Enter distance for chamber 4"}
            star={true}
            description={"Enter distance for chamber 4"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={24}
            heading={"Distance from chamber 5:"}
            name="distance_from_chamber5"
            placeholder={"Enter distance for chamber 5"}
            message={"Please Enter distance for chamber 5"}
            star={true}
            description={"Enter distance for chamber 5"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={25}
            heading={"Distance from chamber 6:"}
            name="distance_from_chamber6"
            placeholder={"Enter distance for chamber 6"}
            message={"Please Enter distance for chamber 6"}
            star={true}
            description={"Enter distance for chamber 6"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={26}
            heading={"Distance from chamber 7:"}
            name="distance_from_chamber7"
            placeholder={"Enter distance for chamber 7"}
            message={"Please Enter distance for chamber 7"}
            star={true}
            description={"Enter distance for chamber 7"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={27}
            heading={"Distance from chamber 8:"}
            name="distance_from_chamber8"
            placeholder={"Enter distance for chamber 8"}
            message={"Please Enter distance for chamber 8"}
            star={true}
            description={"Enter distance for chamber 8"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={28}
            heading={"Distance from chamber 9:"}
            name="distance_from_chamber9"
            placeholder={"Enter distance for chamber 9"}
            message={"Please Enter distance for chamber 9"}
            star={true}
            description={"Enter distance for chamber 9"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={29}
            heading={"Distance from chamber 10:"}
            name="distance_from_chamber10"
            placeholder={"Enter distance for chamber 10"}
            message={"Please Enter distance for chamber 10"}
            star={true}
            description={"Enter distance for chamber 10"}
            removeValidation="true"
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={30}
            heading={"Carriageway width:"}
            name="carriageway_width"
            placeholder={"Enter distance for chamber 10"}
            message={"Please Enter distance for chamber 10"}
            star={true}
            description={"Enter distance for chamber 10"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={31}
            heading={"Footpath width:"}
            name="footpath_width"
            placeholder={"Enter distance for chamber 10"}
            message={"Please Enter distance for chamber 10"}
            star={true}
            description={"Enter distance for chamber 10"}
            removeValidation="true"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={32}
            heading={"Road speed limit:"}
            name="road_speed_limit"
            placeholder={"Enter Road speed limit"}
            message={"Please Road speed limit"}
            star={true}
            description={"Enter Road speed limit"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={33}
            heading={"Parking type available:"}
            name="parking_type_available"
            placeholder={"Enter Parking type available"}
            message={"Please enter No Parking type available"}
            star={true}
            description={"Enter Parking type available"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={34}
            heading={"Soft surface type footway:"}
            name="surface_type_footway"
            placeholder={"Enter soft surface type footway"}
            message={"Please enter soft surface type footway"}
            star={true}
            description={"Enter soft surface type footway"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={35}
            heading={"Surface type cariageway:"}
            name="surface_type_carriageway"
            placeholder={"Enter Surface type cariageway"}
            message={"Please enter Surface type cariageway"}
            star={true}
            description={"Enter Surface type cariageway"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={36}
            heading={"No of parking bays suspended:"}
            name="number_of_parking_bays_suspended"
            placeholder={"Enter No of parking bays suspended"}
            message={"Please enter No of parking bays suspended"}
            star={true}
            description={"Enter No of parking bays suspended"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <TextField
            number={37}
            heading={"MOA Task Job No:"}
            name="moa_task_job_number"
            placeholder={"Enter MOA Task Job No"}
            message={"Please MOA Task Job No"}
            star={true}
            description={"Enter MOA Task Job No"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <TextField
            number={38}
            heading={"If void is over 40% then request a new duck:"}
            name="request_new_duct"
            placeholder={"Enter a new duck"}
            message={"Please enter a new duck"}
            star={true}
            description={"Enter a new duck"}
          />
        </Col>
      </Row>

      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <NextButton>
          <BackButton
            onClick={() => {
              setStepNumber(0);
            }}
          >
            <BackButtonText>Back</BackButtonText>
          </BackButton>
          <Form.Item wrapperCol={{}}>
            <Button
              type="primary"
              size={"large"}
              htmlType="submit"
              style={{ minWidth: 150, height: "55px" }}
            >
              Next
            </Button>
          </Form.Item>
        </NextButton>
      </Row>
    </Form>
  );
}

export default StepCabling;
