import React from "react";
import { Form, Input, Collapse } from "antd";
import {
  FlexColImg,
  FlexRowImg,
  StyleDescText,
  StyledNumber,
  StyleImgDiv,
  StyleNText,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";
import TextField from "./TextField";
import blockimg from "../../../../../image/Comments.png";
const { TextArea } = Input;

function BlockageField({
  number,
  heading,
  description,
  name,
  message,
  star,
  placeholder,
  onChange,
}) {
  const { Panel } = Collapse;

  return (
    <StyleTextDiv>
      <FlexRowImg>
        <FlexColImg>
          <StyleImgDiv>
            <StyledNumber>{number}</StyledNumber>
            <StyleNText> {heading}</StyleNText>
            {star ? <StyleRedStar>*</StyleRedStar> : null}
          </StyleImgDiv>
          <StyleImgDiv>
            <StyleDescText>{description}</StyleDescText>
          </StyleImgDiv>
        </FlexColImg>
        <FlexColImg>
          <img src={blockimg} width={250} lg-width={400} />
        </FlexColImg>
      </FlexRowImg>
      <Form.Item
        label=""
        name={name}
        rules={[
          {
            required: true,
            message: message,
          },
        ]}
      >
        {/* <TextArea
          rows={6}
          size={"large"}
          allowClear={true}
          placeholder={placeholder}
        /> */}
        <Collapse accordion className="blockage_comments">
          <Panel header="Google Maps: Blockage 1" key="1">
            <TextField
              name="name"
              placeholder={"e.g, Name: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="street"
              placeholder={"e.g, Street: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="country_code"
              placeholder={"e.g, ISO Country Code: PK"}
              comments="true"
            />
            <TextField
              name="country"
              placeholder={"e.g, Country: Pakistan"}
              comments="true"
            />
            <TextField
              name="postal_code"
              placeholder={"Postal code"}
              comments="true"
            />
            <TextField
              name="administrative_area"
              placeholder={"e.g, Administrative area:Islamabad"}
              comments="true"
            />
            <TextField
              name="sub_administrative_area"
              placeholder={"e.g, Subadministrative area: Pakistan"}
              comments="true"
            />
            <TextField
              name="locality"
              placeholder={"e.g, Locality: Islamabad"}
              comments="true"
            />
            <TextField
              name="sub_locality"
              placeholder={"e.g, Sublocality: xyz"}
              comments="true"
            />
            <TextField
              name="thorough_fare"
              placeholder={"e.g, Thoroughfare: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="sub_thorough_fare"
              placeholder={
                "e.g, 33.71196071013711, 73.05785499020168, X:320030.68682822515, Y:3731912.803403508"
              }
              comments="true"
            />
          </Panel>
          <Panel header="Google Maps: Blockage 2" key="2">
            <TextField
              name="name2"
              placeholder={"e.g, Name: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="street2"
              placeholder={"e.g, Street: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="country_code2"
              placeholder={"e.g, ISO Country Code: PK"}
              comments="true"
            />
            <TextField
              name="country2"
              placeholder={"e.g, Country: Pakistan"}
              comments="true"
            />
            <TextField
              name="postal_code2"
              placeholder={"Postal code"}
              comments="true"
            />
            <TextField
              name="administrative_area2"
              placeholder={"e.g, Administrative area:Islamabad"}
              comments="true"
            />
            <TextField
              name="sub_administrative_area2"
              placeholder={"e.g, Subadministrative area: Pakistan"}
              comments="true"
            />
            <TextField
              name="locality2"
              placeholder={"e.g, Locality: Islamabad"}
              comments="true"
            />
            <TextField
              name="sub_locality2"
              placeholder={"e.g, Sublocality: xyz"}
              comments="true"
            />
            <TextField
              name="thorough_fare2"
              placeholder={"e.g, Thoroughfare: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="sub_thorough_fare2"
              placeholder={
                "e.g, 33.71196071013711, 73.05785499020168, X:320030.68682822515, Y:3731912.803403508"
              }
              comments="true"
            />
          </Panel>
        </Collapse>
      </Form.Item>
    </StyleTextDiv>
  );
}

export default BlockageField;
