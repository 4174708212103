import React from "react";
import { Form, Input, InputNumber } from "antd";
import {
  StyleDescText,
  StyledNumber,
  StyleLat,
  StyleLng,
  StyleLocationDiv,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";

function TextField({
  onClick,
  number,
  heading,
  description,
  name1,
  name2,
  message1,
  message2,
  star,
  placeholder1,
  placeholder2,
}) {
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <StyleLocationDiv>
        <StyleLat>
          <Form.Item
            label=""
            name={name1}
            rules={[
              {
                required: true,
                message: message1,
              },
            ]}
            style={{ width: "100%" }}
          >
            <InputNumber
              style={{ width: "100%" }}
              size={"large"}
              allowClear={true}
              placeholder={placeholder1}
              min={-90}
              // max={90}
              onKeyPress={onClick}
            />
          </Form.Item>
        </StyleLat>
        <StyleLng>
          <Form.Item
            label=""
            name={name2}
            rules={[
              {
                required: true,
                message: message2,
              },
            ]}
            style={{ width: "100%" }}
          >
            <InputNumber
              style={{ width: "100%" }}
              size={"large"}
              allowClear={true}
              placeholder={placeholder2}
              min={-180}
              max={180}
              onKeyPress={onClick}
            />
          </Form.Item>
        </StyleLng>
      </StyleLocationDiv>
    </StyleTextDiv>
  );
}

export default TextField;
