import React, { useEffect, useState } from "react";
// import "../selectForm/selectForm.css";
// import logo from "../../../image/Logo.svg";
import logo from "../../../image/new_logo.png";
import clipboard_black from "../../../image/clipboard-text.svg";
import clipboard_white from "../../../image/A55_white.svg";
import circle_green from "../../../image/info-circle.svg";
import circle_white from "../../../image/circle_white.svg";
import folder from "../../../image/folder-connection.svg";
import flag from "../../../image/flag-2.svg";
import { useNavigate } from "react-router-dom";
import {
  StyledBox,
  StyledButton,
  StyledHead,
  StyledIcon,
  StyledLabel,
  StyledTitle,
} from "./styles/main.styles.js";
import { Row, Col, message } from "antd";
const SelectForm = () => {
  const loginData = localStorage.getItem("loginData");
  const parsedLoginData = JSON.parse(loginData);
  console.log("parsedLoginData...", parsedLoginData);

  let navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [selected, setSelected] = useState("/SelectArea");
  const [formSelected, setFormSelected] = useState("A55 BLOCKAGE FORM");
  const [createdByUser, setCreatedByUser] = useState(null);
  ///
  useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      const storedDataString = localStorage.getItem("loginData");
      if (storedDataString) {
        try {
          const storedDataObject = JSON.parse(storedDataString);
          const createdByValue = storedDataObject.created_by;
          console.log("createdByValue", createdByValue);

          setCreatedByUser(createdByValue);
        } catch (error) {
          console.error("Error parsing stored data:", error);
        }
      } else {
        console.log("No data found ");
      }
    };

    fetchDataFromLocalStorage();
  }, []);

  console.log("created value", createdByUser);

  ////
  const handleFormButtonClick = (formName, naviageTo) => {
    console.log(formName, naviageTo, "yyyyyyyyyyy");
    if (formName === "ALL FORMS") {
      navigate("/FormFile");
    } else {
      setFormSelected(formName);
      setSelected(naviageTo);
      localStorage.setItem("form_selected", formName);
      navigate(naviageTo);
    }
  };

  return (
    <>
      {contextHolder}
      <StyledHead>
        <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
          <img className="map_logo" src={logo} height="201px" width="400px" />
        </div>
        <StyledTitle>Select the form option</StyledTitle>
      </StyledHead>
      <StyledBox>
        <Row
          className="row_margin_bottom"
          justify={"center"}
          style={{ marginBottom: "15px" }}
        >
          {parsedLoginData?.created_by == "hyper-optic" ? (
            <Col xs={24} md={10} lg={8} xl={5}>
              <FormButton
                selected={formSelected}
                setFormSelected={setFormSelected}
                setSelected={setSelected}
                formName={"Tree Form"}
                naviageTo={"/SelectArea"}
                text={"Hyper Optics"}
                icon_white={clipboard_white}
                icon_green={folder}
                onClick={() =>
                  handleFormButtonClick("Hyper Optics", "/SelectArea")
                }
              />
            </Col>
          ) : (
            <Col xs={24} md={10} lg={8} xl={5}>
              <FormButton
                selected={formSelected}
                setFormSelected={setFormSelected}
                setSelected={setSelected}
                formName={"A55 BLOCKAGE FORM"}
                naviageTo={"/SelectArea"}
                text={"A55 Blockage Form"}
                icon_white={clipboard_white}
                icon_green={clipboard_black}
                onClick={() =>
                  handleFormButtonClick("A55 BLOCKAGE FORM", "/SelectArea")
                }
              />
            </Col>
          )}

          {/* <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"A55 BLOCKAGE FORM"}
              naviageTo={"/SelectArea"}
              text={"A55 Blockage Form"}
              icon_white={clipboard_white}
              icon_green={clipboard_black}
              onClick={() =>
                handleFormButtonClick("A55 BLOCKAGE FORM", "/SelectArea")
              }
            /> */}

          {/* <Col xs={0} md={1} lg={3}></Col> */}
          <Col xs={24} md={10} lg={8} xl={5}>
            <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"RAISE A CASE"}
              naviageTo={"/SelectArea"}
              text={"Raise a case"}
              icon_white={circle_white}
              icon_green={circle_green}
              onClick={() =>
                handleFormButtonClick("RAISE A CASE", "/SelectArea")
              }
            />
          </Col>
          {/* <Col xs={0} md={1} lg={3}></Col> */}
          <Col xs={24} md={12} lg={8} xl={5}>
            <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"D-Pole"}
              naviageTo={"/SelectArea"}
              text={"D - Pole"}
              icon_white={clipboard_white}
              icon_green={flag}
              onClick={() => handleFormButtonClick("D - Pole", "/SelectArea")}
            />
          </Col>
        </Row>
        {/* <StyledBox> */}
        <Row justify={"center"}>
          <Col xs={24} md={10} lg={8} xl={5}>
            <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"NETWORK ADJUSTMENT"}
              naviageTo={"/SelectArea"}
              text={"Network Adjustment"}
              icon_white={clipboard_white}
              icon_green={folder}
              onClick={() =>
                handleFormButtonClick("NETWORK ADJUSTMENT", "/SelectArea")
              }
            />
          </Col>
          <Col xs={24} md={10} lg={8} xl={5}>
            <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"Tree Form"}
              naviageTo={"/SelectArea"}
              text={"Tree Cutting"}
              icon_white={clipboard_white}
              icon_green={folder}
              onClick={() =>
                handleFormButtonClick("Tree Cutting", "/SelectArea")
              }
            />
          </Col>

          <Col xs={24} md={10} lg={8} xl={5}>
            <FormButton
              selected={formSelected}
              setFormSelected={setFormSelected}
              setSelected={setSelected}
              formName={"ALL FORMS"}
              naviageTo={"/FormFile"}
              text={"All Forms"}
              icon_white={clipboard_white}
              icon_green={clipboard_black}
              onClick={() => handleFormButtonClick("ALL FORMS", "/SelectArea")}
            />
          </Col>
        </Row>
        <Row justify={"center"}></Row>
      </StyledBox>
      <StyledBox>
        <Row style={{ marginTop: "10px", marginBottom: "30px" }}>
          {/* <Col xs={24} md={24} lg={11}>
            <StyledButton
              onClick={(e) => {
                localStorage.setItem("form_selected", formSelected);
                navigate(selected);
              }}
            >
              <StyledLabel>Next</StyledLabel>
            </StyledButton>
          </Col> */}
        </Row>
      </StyledBox>
    </>
  );
};

const FormButton = ({
  selected,
  setFormSelected,
  setSelected,
  formName,
  naviageTo,
  text,
  icon_white,
  icon_green,
  formSelected,
  onClick,
}) => {
  console.log(selected, "selected form");
  console.log(formSelected, "formSelected");

  let navigate = useNavigate();

  return (
    <button
      onClick={onClick}
      // onClick={(e) => {
      //   console.log(e, "hdufhk");
      //   // localStorage.setItem("form_selected", "A55 BLOCKAGE FORM");
      //   setFormSelected(formName);
      //   // navigate("/SelectArea");
      //   setSelected(naviageTo);
      // }}
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "281px",
        height: "60px",
        margin: "0 auto",
        // background: selected == formName ? "#7FBE42" : "#FFFFFF",
        border: "1px solid #7FBE42",
        borderRadius: "5px",
        transitionDuration: "0.2s",
        cursor: "pointer",
        marginBottom: "15px",
      }}
    >
      <StyledIcon
        src={
          icon_green
          // selected == formName ? icon_white : icon_green
        }
      />
      <p
        style={{
          fontFamily: "Satoshi",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "18px",
          paddingTop: "13px",
          letterSpacing: "0.03em",
          // color: selected == formName ? "white" : "black",
        }}
      >
        {text}
      </p>
    </button>
  );
};

export default SelectForm;
