import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
export const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  // border: 1px solid black;
  height: 64px;
  //  height: ${(props) => (props.extendNavbar ? "100vh" : "80px")};
  background: white;
  box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 25px 25px;
`;
export const NavbarInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  @media (max-width: 750px) {
    display: none;
  }
`;
export const NavbarLinkContainer = styled.div`
  display: flex;
  margin-left: 20px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 65%;
  align-items: center;
  padding-left: 4%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 35%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3%;
`;
export const Ricon = styled.div`
  margin-left: 25px;
`;
export const NavbarLink = styled(Link)`
  color: #8b8b8b;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  margin: 5px;
  &:hover {
    color: #3d9f46;
    font-weight: 700;
  }
  &.active {
    color: #3d9f46;
    font-weight: 700;
    border-bottom: 2px solid #3d9f46;
  }

  @media (max-width: 750px) {
    display: none;
  }
`;

export const NavIcon = styled.div`
  margin-right: 7px;
`;

export const NavText = styled.div`
    margin-top: 2px;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color:#8B8B8B;
    cursor:pointer;
    &:hover{
        color: #3D9F46;
        font-weight: 700;
    }
    // &:active{
    //   margin-bottom:10px;
    //   border-bottom:2px solid #3D9F46;
  }
   
`;
export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 12px;
`;
export const Rtext = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #2f3541;
`;
export const Rprof = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.075em;
  color: rgba(65, 68, 74, 0.36);
`;
export const OpenLinksButton = styled.button`
  width: 70px;
  height: 65px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;
  float: left;

  @media (min-width: 750px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d2f35;

  @media (min-width: 750px) {
    display: none;
  }
`;
export const NavbarLinkExtended = styled(Link)`
  color: #c5c5c5;
  font-size: 12px;
  // font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;
