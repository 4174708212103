import React from "react";
import "../../css/SelectForm.css";
import logo from "../../image/Logo.svg";
import clipboard from "../../image/clipboard-text.svg";
import circle from "../../image/info-circle.svg";
import folder from "../../image/folder-connection.svg";
import flag from "../../image/flag-2.svg";
import { useNavigate } from "react-router-dom";

function SelectForm() {
  let navigate = useNavigate();
  return (
    <div className="sf-main">
      <div className="sf-header-section">
        <div className="sf-Netslogo">
          <img src={logo} width="250px" height="130px" />
        </div>
        <div className="sf-heading-1">Select the form option</div>
      </div>
      <div className="sf-box">
        <div
          className="sf-box-1"
          onClick={(e) => {
            localStorage.setItem("form_selected", "A55 BLOCKAGE FORM");
            navigate("/SelectArea");
          }}
        >
          <div className="sf-icon-div">
            <img src={clipboard} height="28px" width="28px" />
          </div>
          <div className="sf-label-div-1">A55 Blockage Form</div>
        </div>
        <div
          className="sf-box-2"
          onClick={(e) => {
            localStorage.setItem("form_selected", "Raise");
            navigate("/SelectArea");
          }}
        >
          <div className="sf-icon-div">
            <img src={circle} height="28px" width="28px" />
          </div>
          <div className="sf-label-div-1">Raise a case</div>
        </div>
      </div>
      <div className="sf-box">
        <div
          className="sf-box-1"
          onClick={(e) => {
            localStorage.setItem("form_selected", "D-Pole");
            navigate("/SelectArea");
          }}
        >
          <div className="sf-icon-div">
            <img src={flag} height="28px" width="28px" />
          </div>
          <div className="sf-label-div-1">D - Pole</div>
        </div>
        <div
          className="sf-box-2"
          onClick={(e) => {
            localStorage.setItem("form_selected", "NETWORK ADJUSTMENT");
            navigate("/SelectArea");
          }}
        >
          <div className="sf-icon-div">
            <img src={folder} height="28px" width="28px" />
          </div>
          <div className="sf-label-div-1">Network Adjustment</div>
        </div>
      </div>
    </div>
  );
}

export default SelectForm;
