import React, { useEffect, useState } from "react";
import { Button, Col, Form, Radio, Row } from "antd";
import TextField from "./InnerItems/TextField";
import {
  BackButton,
  BackButtonText,
  NextButton,
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
} from "../styles/main.styles";
import measureImg from "../../../../image/Line.png";
import Measurement from "./InnerItems/MeasurementField";

function SiteForm({ data, setStepNumber, setData }) {
  console.log(data, "datatatatatatatat.....");
  const [form] = Form.useForm();
  const restSoft = () => {
    data.l1_soft = 0;
    data.l1_soft_type = "Meter";
    data.l2_soft = 0;
    data.l2_soft_type = "Meter";
    data.l3_soft = 0;
    data.l3_soft_type = "Meter";
  };

  const restFootway = () => {
    data.l1_footway = 0;
    data.l1_footway_type = "Meter";
    data.l2_footway = 0;
    data.l2_footway_type = "Meter";
    data.l3_footway = 0;
    data.l3_footway_type = "Meter";
  };

  const restCar = () => {
    data.l1_carriageway = 0;
    data.l1_carriageway_type = "Meter";
    data.l2_carriageway = 0;
    data.l2_carriageway_type = "Meter";
    data.l3_carriageway = 0;
    data.l3_carriageway_type = "Meter";
  };
  const onChangeMeasurementType = (e) => {
    setMeasurementType(e.target.value);
  };
  const [measurementType, setMeasurementType] = useState("soft");
  const nextStep = (values) => {
    data.bore = values.bore;
    data.box_A_type = values.box_A_type;
    data.box_B_type = values.box_B_type;

    data.l1_soft = values.l1_soft ? values.l1_soft : 0;
    data.l1_soft_type = values.l1_soft_type ? values.l1_soft_type : "Meter";
    data.l2_soft = values.l2_soft ? values.l2_soft : 0;
    data.l2_soft_type = values.l2_soft_type ? values.l2_soft_type : "Meter";
    data.l3_soft = values.l3_soft ? values.l3_soft : 0;
    data.l3_soft_type = values.l3_soft_type ? values.l3_soft_type : "Meter";

    data.l1_footway = values.l1_footway ? values.l1_footway : 0;
    data.l1_footway_type = values.l1_footway_type
      ? values.l1_footway_type
      : "Meter";
    data.l2_footway = values.l2_footway ? values.l2_footway : 0;
    data.l2_footway_type = values.l2_footway_type
      ? values.l2_footway_type
      : "Meter";
    data.l3_footway = values.l2_footway ? values.l3_footway : 0;
    data.l3_footway_type = values.l3_footway_type
      ? values.l3_footway_type
      : "Meter";

    data.l1_carriageway = values.l1_carriageway ? values.l1_carriageway : 0;
    data.l1_carriageway_type = values.l1_carriageway_type
      ? values.l1_Carriageway_type
      : "Meter";
    data.l2_carriageway = values.l2_carriageway ? values.l2_carriageway : 0;
    data.l2_carriageway_type = values.l2_carriageway_type
      ? values.l2_Carriageway_type
      : "Meter";
    data.l3_carriageway = values.l3_carriageway ? values.l3_carriageway : 0;
    data.l3_carriageway_type = values.l3_carriageway_type
      ? values.l3_Carriageway_type
      : "Meter";

    if (measurementType === "soft") {
      restFootway();
      restCar();
    } else if (measurementType === "footway") {
      restSoft();
      restCar();
    } else if (measurementType === "carriageway") {
      restSoft();
      restFootway();
    }

    if (data.l1_soft !== 0 && data.l1_soft_type === "Inches") {
      data.l1_soft = data.l1_soft * 0.0254;
      data.l1_soft_type = "Meter";
    }
    if (data.l2_soft !== 0 && data.l2_soft_type === "Inches") {
      data.l2_soft = data.l2_soft * 0.0254;
      data.l2_soft_type = "Meter";
    }
    if (data.l3_soft !== 0 && data.l3_soft_type === "Inches") {
      data.l3_soft = data.l3_soft * 0.0254;
      data.l3_soft_type = "Meter";
    }
    if (data.l1_soft !== 0 && data.l1_soft_type === "Foot") {
      data.l1_soft = data.l1_soft * 0.3048;
      data.l1_soft_type = "Meter";
    }
    if (data.l2_soft !== 0 && data.l2_soft_type === "Foot") {
      data.l2_soft = data.l2_soft * 0.3048;
      data.l2_soft_type = "Meter";
    }
    if (data.l3_soft !== 0 && data.l3_soft_type === "Foot") {
      data.l3_soft = data.l3_soft * 0.3048;
      data.l3_soft_type = "Meter";
    }

    if (data.l1_footway !== 0 && data.l1_footway_type === "Inches") {
      data.l1_footway = data.l1_footway * 0.0254;
      data.l1_footway_type = "Meter";
    }
    if (data.l2_footway !== 0 && data.l2_footway_type === "Inches") {
      data.l2_footway = data.l2_footway * 0.0254;
      data.l2_footway_type = "Meter";
    }
    if (data.l3_footway !== 0 && data.l3_footway_type === "Inches") {
      data.l3_footway = data.l3_footway * 0.0254;
      data.l3_footway_type = "Meter";
    }
    if (data.l1_footway !== 0 && data.l1_footway_type === "Foot") {
      data.l1_footway = data.l1_footway * 0.3048;
      data.l1_footway_type = "Meter";
    }
    if (data.l2_footway !== 0 && data.l2_footway_type === "Foot") {
      data.l2_footway = data.l2_footway * 0.3048;
      data.l2_footway_type = "Meter";
    }
    if (data.l3_footway !== 0 && data.l3_footway_type === "Foot") {
      data.l3_footway = data.l3_footway * 0.3048;
      data.l3_footway_type = "Meter";
    }

    if (data.l1_carriageway !== 0 && data.l1_carriageway_type === "Inches") {
      data.l1_carriageway = data.l1_carriageway * 0.0254;
      data.l1_carriageway_type = "Meter";
    }
    if (data.l2_carriageway !== 0 && data.l2_carriageway_type === "Inches") {
      data.l2_carriageway = data.l2_carriageway * 0.0254;
      data.l2_carriageway_type = "Meter";
    }
    if (data.l3_carriageway !== 0 && data.l3_carriageway_type === "Inches") {
      data.l3_carriageway = data.l3_carriageway * 0.0254;
      data.l3_carriageway_type = "Meter";
    }
    if (data.l1_carriageway !== 0 && data.l1_carriageway_type === "Foot") {
      data.l1_carriageway = data.l1_carriageway * 0.3048;
      data.l1_carriageway_type = "Meter";
    }
    if (data.l2_carriageway !== 0 && data.l2_carriageway_type === "Foot") {
      data.l2_carriageway = data.l2_carriageway * 0.3048;
      data.l2_carriageway_type = "Meter";
    }
    if (data.l3_carriageway !== 0 && data.l3_carriageway_type === "Foot") {
      data.l3_carriageway = data.l3_carriageway * 0.3048;
      data.l3_carriageway_type = "Meter";
    }

    data["l1_Carriageway_type"] = data["l1_carriageway_type"];
    data["l2_Carriageway_type"] = data["l2_carriageway_type"];
    data["l3_Carriageway_type"] = data["l3_carriageway_type"];

    setData(data);
    window.scrollTo(0, 0);
    setStepNumber(3);
    // console.clear();
    console.log(data);
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (data.l1_soft !== 0) {
      setMeasurementType("soft");
    } else if (data.l1_footway !== 0) {
      setMeasurementType("footway");
    } else if (data.l1_carriageway !== 0) {
      setMeasurementType("carriageway");
    }
  }, []);
  return (
    <Form
      onFinish={nextStep}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 0 }}
      style={{ width: "100%" }}
      wrapperCol={{ span: 24 }}
      initialValues={data}
      autoComplete="off"
      form={form}
    >
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={1}
            heading={"Box A - Type"}
            name="box_A_type"
            placeholder={"Enter Box A - Type here"}
            message={"Please enter Box A - Type"}
            star={true}
            description={
              "Insert box type eg JUF 4 or JRC 12 if duct terminates above ground with a duct bend insert Pole , BLDG or CAB as appropriate."
            }
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={2}
            heading={"Box B - Type"}
            name="box_B_type"
            placeholder={"Enter Box B - Type here"}
            message={"Please enter Box B - Type"}
            star={true}
            description={
              "Insert box type eg JUF 4 or JRC 12 if duct terminates above ground with a duct bend insert Pole, BLDG or CAB as appropriate."
            }
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={3}
            heading={"Bore"}
            name="bore"
            placeholder={"Enter Bore here"}
            message={"Please enter Bore"}
            star={true}
            description={
              "Insert box type eg JUF 4 or JRC 12 if duct terminates above ground with a duct bend insert Pole , BLDG or CAB as appropriate."
            }
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <StyleNumberDiv>
            <StyledNumber>{4}</StyledNumber>
            <StyleNText>Measurement</StyleNText>
            <StyleRedStar>*</StyleRedStar>
          </StyleNumberDiv>
          <StyleNumberDiv>
            <StyleDescText style={{ marginLeft: 0 }}>
              Distance L1 is from first box/pole to blockage and is by surface
              types.The total of these three surface types added together give
              the section length, by entering by surface type replacement cost
              can be estimated.
            </StyleDescText>
          </StyleNumberDiv>
          <Radio.Group
            style={{ marginTop: "15px" }}
            onChange={onChangeMeasurementType}
            value={measurementType}
          >
            <Row>
              <Col span={8}>
                <Radio
                  className="measurement_radio_btns"
                  style={{
                    width: "155px",
                    marginRight: "5px",
                    borderRadius: "8px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    padding: "5px",
                  }}
                  value="soft"
                >
                  Soft
                </Radio>
              </Col>
              <Col span={8}>
                <Radio
                  className="measurement_radio_btns"
                  style={{
                    width: "155px", // marginLeft: "5px",
                    marginRight: "5px",
                    borderRadius: "8px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    padding: "5px",
                  }}
                  value="footway"
                >
                  Footway
                </Radio>
              </Col>
              <Col span={8}>
                <Radio
                  className="measurement_radio_btns"
                  style={{
                    width: "170px",
                    marginRight: "5px",
                    borderRadius: "8px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    padding: "5px",
                  }}
                  value="carriageway"
                >
                  Carriageway
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
          <br />
          <br />
          <img src={measureImg} alt={"measure image"} width="100%" />
        </Col>
      </Row>
      {measurementType === "soft" ? (
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={5}
              heading={"L1 Soft"}
              name="l1_soft"
              placeholder={"Enter L1 Soft"}
              message={"Please enter L1 Soft"}
              star={true}
              description={"Measurements in Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={6}
              heading={"L2 Soft"}
              name="l2_soft"
              placeholder={"Enter L2 soft"}
              message={"Please enter L2 soft"}
              star={true}
              description={"Measurements in Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={7}
              heading={"L3 Soft"}
              name="l3_soft"
              placeholder={"Enter L3 soft"}
              message={"Please enter L3 soft"}
              star={true}
              description={"Measurements in Metres"}
              form={form}
            />
          </Col>
        </Row>
      ) : null}
      {measurementType === "footway" ? (
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={5}
              heading={"L1 Footway"}
              name="l1_footway"
              placeholder={"Enter L1 Footway"}
              message={"Please enter L1 Footway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={6}
              heading={"L2 Footway"}
              name="l2_footway"
              placeholder={"Enter L2 Footway"}
              message={"Please enter L2 Footway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={7}
              heading={"L3 Footway"}
              name="l3_footway"
              placeholder={"Enter L3 Footway"}
              message={"Please enter L3 Footway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
        </Row>
      ) : null}
      {measurementType === "carriageway" ? (
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={5}
              heading={"L1 Carriageway"}
              name="l1_carriageway"
              placeholder={"Enter L1 Carriageway"}
              message={"Please enter L1 Carriageway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={6}
              heading={"L2 Carriageway"}
              name="l2_carriageway"
              placeholder={"Enter L2 Carriageway"}
              message={"Please enter L2 Carriageway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Measurement
              number={7}
              heading={"L3 Carriageway"}
              name="l3_carriageway"
              placeholder={"Enter L3 Carriageway"}
              message={"Please enter L3 Carriageway"}
              star={true}
              description={"Measurements in 'Metres"}
              form={form}
            />
          </Col>
        </Row>
      ) : null}
      <NextButton>
        <BackButton
          onClick={() => {
            setStepNumber(1);
          }}
        >
          <BackButtonText>Back</BackButtonText>
        </BackButton>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            size={"large"}
            htmlType="submit"
            style={{ minWidth: 175, height: 55 }}
          >
            Next
          </Button>
        </Form.Item>
      </NextButton>
    </Form>
  );
}

export default SiteForm;
