import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import logo from "../../Components/Assets/Logo.svg";
import logo from "../../image/new_logo.png";
import sms from "../../image/sms.svg";
import lock from "../../image/lock.svg";
import eye from "../../image/eye.svg";
import eyeSplash from "../../image/eye-slash.svg";
import axios, { baseUrl, header } from "../../utils/axios";
import {
  ButtonContainer,
  EyeIcon,
  Input,
  InputContainer,
  Logo,
  StyledForm,
  StyledIcon,
  SubmitButton,
} from "./styles/main.styles";
import { Col, Modal, Row, Space, Spin, Checkbox } from "antd";

function Login() {
  const [model, showModel] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Ischecked, setIsChecked] = useState(false);
  const [show, setShow] = useState("password");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [img, setImg] = useState(eyeSplash);

  const [rememberMe, setRememberMe] = useState(false);
  console.log(rememberMe, "remember me");

  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberMePass");
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe === "true" && storedEmail) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    } else {
      setEmail("");
      setPassword("");
    }
  }, []);

  const getShow = (e) => {
    if (e === "password") {
      setImg(eye);
      return "text";
    } else {
      setImg(eyeSplash);
      return "password";
    }
  };
  let navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    await event.target;
    setEmail("");
    setPassword("");
    setIsChecked(true);
  }

  async function postData() {
    showModel(true);
    let data = { email: email, password: password };
    const result = await axios
      .post(baseUrl + "/verify_user", data, header)
      .then((res) => {
        showModel(false);
        if (res.data.exist === true) {
          console.log(res.data.type, "res.data.type");
          if (rememberMe) {
            localStorage.setItem("rememberedEmail", email);
            localStorage.setItem("rememberMePass", password);
            localStorage.setItem("rememberMe", true);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberMePass");
            localStorage.removeItem("rememberMe");
          }

          if (res.data.type === "admin") {
            console.log(res.data.user_id, "res.data.user_id");
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            navigate("/Dashboard");
          } else if (res.data.type === "SuperAdmin") {
            console.log(res.data.user_id, "res.data.user_id super admin");
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            navigate("/superadmindashboard");
          } else {
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            localStorage.setItem("loginData", JSON.stringify(res.data));
            navigate("/SelectForm");
          }
        }
      })
      .catch((err) => {
        showModel(false);
        if (err.response.status === 500) {
          setError(err.response.data);
          setShowError(true);
        } else if (err.response.status === 509) {
          setError("Email or password in correct");
          setShowError(true);
        } else {
          setError("Server under maintenance");
          setShowError(true);
        }
      });
  }

  useEffect(() => {
    setTimeout(function () {
      setShowError(false);
    }, 3000);
  }, [error]);
  console.log(password, "password");
  return (
    <StyledForm>
      <div style={{ padding: "4rem 4rem" }}>
        <Logo>
          <img src={logo} height="201px" width="400px" />
        </Logo>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <InputContainer className="email-data">
                <StyledIcon>
                  <img src={sms} height="20px" width="20px" />
                </StyledIcon>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="underline"></div>
                {/* <label>Email</label> */}
              </InputContainer>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <InputContainer className="pass-data">
                <StyledIcon>
                  <img src={lock} height="20px" width="20px" class="lock" />
                </StyledIcon>
                <Input
                  type={show}
                  name="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="false"
                />
                <div className="underline"></div>
                {/* <label>Password</label> */}
                <EyeIcon>
                  <img
                    src={img}
                    height="20px"
                    width="20px"
                    onClick={(e) => {
                      setShow(getShow(show));
                    }}
                  />
                </EyeIcon>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: !showError ? "0" : "1",
                    transition: "all .2s",
                    visibility: !showError ? "hidden" : "visible",
                  }}
                >
                  <label
                    style={{
                      color: "red",
                      fontFamily: "Satoshi",
                      fontStyle: "normal",
                      top: 80,
                      left: 20,
                    }}
                  >
                    {error}
                  </label>
                </div>
              </InputContainer>
            </Col>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "96%",
                margin: "0 auto",
              }}
            >
              <Checkbox
                // style={{ marginLeft: "11px" }}
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember me
              </Checkbox>
              <div>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#7fbe42",
                    fontSize: "14px",
                  }}
                  // href=""
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          </Row>
          <ButtonContainer>
            <SubmitButton type="submit" onClick={postData}>
              Sign In
            </SubmitButton>
          </ButtonContainer>
        </form>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </StyledForm>
  );
}

export default Login;
