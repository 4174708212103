import React from "react";
import { Form, Input, Collapse } from "antd";
import {
  FlexColImg,
  FlexRowImg,
  StyleDescText,
  StyledNumber,
  StyleImgDiv,
  StyleNText,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";
import TextField from "./TextField";
import blockimg from "../../../../../image/Comments.png";
const { TextArea } = Input;

function BlockageField({
  number,
  heading,
  description,
  name,
  message,
  star,
  placeholder,
  onChange,
}) {
  const { Panel } = Collapse;

  return (
    <StyleTextDiv>
      <FlexRowImg>
        <FlexColImg>
          <StyleImgDiv>
            <StyledNumber>{number}</StyledNumber>
            <StyleNText> {heading}</StyleNText>
            {star ? <StyleRedStar>*</StyleRedStar> : null}
          </StyleImgDiv>
          <StyleImgDiv>
            <StyleDescText>{description}</StyleDescText>
          </StyleImgDiv>
        </FlexColImg>
        <FlexColImg>
          <img src={blockimg} width={250} lg-width={400} />
        </FlexColImg>
      </FlexRowImg>
      <Form.Item
        label=""
        name={name}
        rules={[
          {
            required: true,
            message: message,
          },
        ]}
      >
        {/* <TextArea
          rows={6}
          size={"large"}
          allowClear={true}
          placeholder={placeholder}
        /> */}
        <Collapse accordion className="blockage_comments">
          <Panel header="Google Maps: Blockage 1" key="1">
            <TextField
              name="Name"
              placeholder={"e.g, Name: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="Street"
              placeholder={"e.g, Street: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="ISO Country Code"
              placeholder={"e.g, ISO Country Code: PK"}
              comments="true"
            />
            <TextField
              name="Country"
              placeholder={"e.g, Country: Pakistan"}
              comments="true"
            />
            <TextField
              name="Postal code"
              placeholder={"Postal code"}
              comments="true"
            />
            <TextField
              name="Administrative area"
              placeholder={"e.g, Administrative area:Islamabad"}
              comments="true"
            />
            <TextField
              name="Subadministrative area"
              placeholder={"e.g, Subadministrative area: Pakistan"}
              comments="true"
            />
            <TextField
              name="Locality"
              placeholder={"e.g, Locality: Islamabad"}
              comments="true"
            />
            <TextField
              name="Sublocality"
              placeholder={"e.g, Sublocality: xyz"}
              comments="true"
            />
            <TextField
              name="Thoroughfare"
              placeholder={"e.g, Thoroughfare: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="Subthoroughfare"
              placeholder={
                "e.g, 33.71196071013711, 73.05785499020168, X:320030.68682822515, Y:3731912.803403508"
              }
              comments="true"
            />
          </Panel>
          <Panel header="Google Maps: Blockage 2" key="2">
            <TextField
              name="Name2"
              placeholder={"e.g, Name: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="Street2"
              placeholder={"e.g, Street: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="ISO Country Code2"
              placeholder={"e.g, ISO Country Code: PK"}
              comments="true"
            />
            <TextField
              name="Country2"
              placeholder={"e.g, Country: Pakistan"}
              comments="true"
            />
            <TextField
              name="Postal code2"
              placeholder={"Postal code"}
              comments="true"
            />
            <TextField
              name="Administrative area2"
              placeholder={"e.g, Administrative area:Islamabad"}
              comments="true"
            />
            <TextField
              name="Subadministrative area2"
              placeholder={"e.g, Subadministrative area: Pakistan"}
              comments="true"
            />
            <TextField
              name="Locality2"
              placeholder={"e.g, Locality: Islamabad"}
              comments="true"
            />
            <TextField
              name="Sublocality2"
              placeholder={"e.g, Sublocality: xyz"}
              comments="true"
            />
            <TextField
              name="Thoroughfare2"
              placeholder={"e.g, Thoroughfare: Sir Khwaja Nazimuddin Road"}
              comments="true"
            />
            <TextField
              name="Subthoroughfare2"
              placeholder={
                "e.g, 33.71196071013711, 73.05785499020168, X:320030.68682822515, Y:3731912.803403508"
              }
              comments="true"
            />
          </Panel>
        </Collapse>
      </Form.Item>
    </StyleTextDiv>
  );
}

export default BlockageField;
