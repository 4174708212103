import styled from "styled-components";
export const StyledFormData = styled.div`
  margin-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
`;
export const StyledHeading = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #2d2f35;
  margin-left: 10px;
`;
export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const StyledViewButton = styled.a`
  width: 90px;
  height: 35px;
  background: #3d9f46;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  padding-top: 5px;
  text-decoration: none;
  &:hover {
    color: #fff;
    font-weight: 700;
  }
`;
export const StyledDownloadButton = styled(StyledViewButton)`
  background: #3d9f4600;
  color: #3d9f46;
  border: 1px solid #3d9f46;
  &:hover {
    font-weight: 700;
    color: #3d9f46;
    border: 1px solid #3d9f46;
  }
`;
export const StyledButtons = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #3d9f46;
  background-color: #f1ffe1;
`;
