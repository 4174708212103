import React from "react";
import { Button, Col, Form, Row } from "antd";
import TextField from "./InnerItems/TextField";
import RadioField from "./InnerItems/RadioField";
import BlockageField from "./InnerItems/BlockageField";
import { BackButton, BackButtonText, NextButton } from "../styles/main.styles";
import surface from "./InnerItems/Surface";

function SiteForm({ data, setStepNumber, setPercentage, setData }) {
  console.log(data, "data vallues");
  function separateAddresses(obj) {
    const address1 = {};
    const address2 = {};

    for (let key in obj) {
      if (key.endsWith('2')) {
        const modifiedKey = key.slice(0, -1); // Remove the '2' from the end of the key
        address2[modifiedKey] = obj[key];
    }else {
            address1[key] = obj[key];
        }
    }

    return { address1, address2 };
}


  function stringFormatter(obj) {
    return Object.keys(obj).map(key => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${obj[key]}`).join(", ");
  }
  const nextStep = (values) => {
    console.log("values", values);
    // Create a copy of the values object
    const valuesCopy = { ...values };

    // Define the keys to be excluded
    const keysToExclude = [
      "blockageComments",
      "cp_def_ref",
      "duct_section_no",
      "duct_type",
      "no_of_cables_in_bore",
      "os_ref",
      "percentage_bore_full",
      "private_property",
      "proposed_cable",
      "proposed_sub_duct_diameter",
      "traffic_light",
      "no_of_blockages",
    ];

    // Remove the excluded keys from the valuesCopy object
    keysToExclude.forEach((key) => {
      delete valuesCopy[key];
    });

    const Adresses = separateAddresses(valuesCopy);

    // Stringify the remaining values
    // data.blockageComments = JSON.stringify(valuesCopy);
    // data.blockageComments = Object.values(valuesCopy).join(" ");
    data.blockageComments = `Google Maps: Blockage 1: ${stringFormatter(Adresses.address1)}, Google Maps: Blockage 2:  ${stringFormatter(Adresses.address2)}`;
    // data.blockageComments = values.blockageComments;

    data.cp_def_ref = values.cp_def_ref;
    data.duct_section_no = values.duct_section_no;
    data.duct_type = values.duct_type;
    data.no_of_cables_in_bore = values.no_of_cables_in_bore;
    data.os_ref = values.os_ref;
    data.percentage_bore_full = values.percentage_bore_full;
    data.private_property = values.private_property;
    data.proposed_cable = values.proposed_cable;
    data.proposed_sub_duct_diameter = values.proposed_sub_duct_diameter;
    data.traffic_light = values.traffic_light;
    data.no_of_blockages = values.no_of_blockages;
    setData(data);
    window.scrollTo(0, 0);
    setStepNumber(2);
    // console.clear();
    console.log("data after", data);
  };
  const onFinishFailed = (errorInfo) => {};

  const handleKeyPress = (event) => {
    // Allow only numbers (0-9) and specific keyboard keys (like backspace)
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];
  return (
    <Form
      onFinish={nextStep}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 0 }}
      style={{ width: "100%" }}
      wrapperCol={{ span: 24 }}
      initialValues={data}
      autoComplete="off"
    >
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={1}
            heading={"CP DFE Ref"}
            name="cp_def_ref"
            placeholder={"Enter CP DFE Ref here"}
            message={"Please enter CP DFE Ref"}
            star={true}
            description={
              "This field only applies if a DFE has been instigated else enter N/A"
            }
            removeValidation="true"
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={2}
            heading={"O/S Ref"}
            name="os_ref"
            placeholder={"Enter O/S Ref here"}
            message={"Please enter O/S Ref"}
            star={true}
            description={"Enter Ordnance Survey reference if known"}
            removeValidation="true"
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <RadioField
            number={3}
            heading={"Property"}
            name="private_property"
            placeholder={"Enter Private Property here"}
            message={"Please enter Private Property"}
            star={true}
            radioData={["Yes", "No", "Don't Know"]}
            description={"Indicate whether works will be on private property"}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <RadioField
            number={4}
            heading={"Traffic Lights"}
            name="traffic_light"
            placeholder={"Enter Traffic Lights here"}
            message={"Please enter Traffic Lights"}
            star={true}
            radioData={["Survey Required", "2 Way", "3 Way", "4 Way"]}
            description={
              "Indicate whether traffic lights will be required whilst completing the works"
            }
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <RadioField
            number={5}
            heading={"Percentage Bore Full"}
            name="percentage_bore_full"
            placeholder={"Enter Percentage Bore Full here"}
            message={"Please Percentage Bore Full cluster"}
            radioData={["0-50%", "51-90%", "91-100%"]}
            star={true}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={6}
            heading={"No of Cables in Bore"}
            name="no_of_cables_in_bore"
            placeholder={"Enter No of Cables in Bore here"}
            message={"Please enter No of Cables in Bore"}
            star={true}
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={7}
            heading={"Proposed Cable"}
            name="proposed_cable"
            placeholder={"Enter Proposed Cable here"}
            message={"Please enter Proposed Cable"}
            star={true}
            description={"Cable type eg 50/0.5 PET"}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={8}
            heading={"Proposed Sub Duct Diameter"}
            name="proposed_sub_duct_diameter"
            placeholder={"Enter Proposed Sub Duct Diameter here"}
            message={"Please Proposed Sub Duct Diameter cluster"}
            star={true}
            description={"Please provide Diameter in 'millimetres'"}
            // onClick={handleKeyPress}
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={9}
            heading={"Duct Section No"}
            name="duct_section_no"
            placeholder={"Enter Duct Section No here"}
            message={"Please enter Duct Section No"}
            star={true}
            description={"The duct section number where blockage occurred"}
            removeValidation="true"
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <RadioField
            number={10}
            heading={"Duct Type"}
            name="duct_type"
            placeholder={"Enter Duct Type here"}
            message={"Please enter Duct Type"}
            star={true}
            radioData={["PVC", "Steel", "Other..."]}
            description={"Indicate whether works will be on private property"}
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "300px" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextField
            number={9}
            heading={"No of Blockages"}
            name="no_of_blockages"
            placeholder={"Enter No of Blockages here"}
            message={"Please enter No of Blockages"}
            star={true}
            description={"The No of Blockages here"}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <BlockageField
            number={11}
            heading={"Blockage Comments"}
            name="blockageComments"
            placeholder={"Enter Blockage Comments here"}
            message={"Please enter Blockage Comments"}
            star={true}
            description={
              "Please insert the nearest address to the location and provide coordinates of the Blockage point from APX. Please see the example photo for the format."
            }
          />
        </Col>

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col> */}
      </Row>
      <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
        <NextButton>
          <BackButton
            onClick={() => {
              data["surface"] = data["surface"][0];
              setStepNumber(0);
            }}
          >
            <BackButtonText>Back</BackButtonText>
          </BackButton>
          <Form.Item wrapperCol={{}}>
            <Button
              type="primary"
              size={"large"}
              htmlType="submit"
              style={{ minWidth: 150, height: "55px" }}
            >
              Next
            </Button>
          </Form.Item>
        </NextButton>
      </Row>
    </Form>
  );
}

export default SiteForm;
