import React, { useEffect, useState } from "react";
import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import TextField from "./InnerItems/TextField";
import ImageField from "./InnerItems/ImageField";
import Picture1 from "../../../../image/Pic1.png";
import Picture2 from "../../../../image/Pic2.png";
import Picture3 from "../../../../image/Pic3.png";
import Picture4 from "../../../../image/Pic4.png";
import { BackButton, BackButtonText, NextButton } from "../styles/main.styles";
import axios, { baseUrl, removeStorage } from "../../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import tick from "../../../../image/animation.gif";

function SiteForm({ data, setStepNumber, setPercentage, setData }) {
  console.log(data, "datata");
  const [form] = Form.useForm();
  const [gisList, setGisList] = useState([]);
  const [apxList, setApxList] = useState([]);
  const [ch1List, setCh1List] = useState([]);
  const [ch2List, setCh2List] = useState([]);
  const [gisResponse, setGisResponse] = useState("");
  const [apxResponse, setApxResponse] = useState("");
  const [ch1Response, setCh1Response] = useState([]);
  const [ch2Response, setCh2Response] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState("");
  const [userID, setUserID] = useState(localStorage.getItem("A55_Portal"));
  const navigate = useNavigate();

  useEffect(() => {
    setGisList(data["google_sat"] ? data["google_sat"] : []);
    setApxList(data["apx"] ? data["apx"] : []);
    setCh1List(data["pic3"] ? data["pic3"] : []);
    setCh2List(data["pic4"] ? data["pic4"] : []);
    if (data["apx"]) {
      setApxResponse(data["apx"][0]["name"]);
      setGisResponse(data["google_sat"][0]["name"]);
      setCh1Response([
        data["pic3"][0]["name"],
        data["pic3"][1]["name"],
        data["pic3"][2]["name"],
        data["pic3"][3]["name"],
      ]);
      setCh2Response([
        data["pic4"][0]["name"],
        data["pic4"][1]["name"],
        data["pic4"][2]["name"],
        data["pic4"][3]["name"],
      ]);
    }
  }, []);

  useEffect(() => {
    // setCh1Response(ch1List);
    // setCh2Response(ch2List);
    // console.log("ch1 list", ch1List);
    // console.log("ch2List list", ch2List);
  }, [ch1List, ch2List]);

  const edit = localStorage.getItem("edit");
  const rsid = localStorage.getItem("respId");
  console.log("ch1 Response", ch1Response);

  const nextStep = (values) => {
    console.log(ch1List.length, "ch 1 list length");
    // console.log(ch1Response[0], "ch1Response[0]");

    if (gisList.length !== 1) {
      error("Google Satellite View not uploaded");
    } else if (apxList.length !== 1) {
      error("Street View not uploaded");
    } else if (
      ch1List.length !== 4
      // ||
      // ch1Response.some((response) => response === "")
    ) {
      console.log("ch 1 list", ch1List);

      error("Upload 4 images on chamber 1");
    } else if (
      ch2List.length !== 4
      // ||
      // ch2Response.some((response) => response === "")
    ) {
      error("Upload 4 images on chamber 2");
    } else {
      setSpin(true);
      delete data["apx"];
      delete data["google_sat"];
      delete data["pic3"];
      delete data["pic4"];
      // delete data["post_code_lat"];
      // delete data["post_code_long"];
      // data.lat = data["post_colat"];
      // data.lng = data["post_code_long"];
      // JSON.stringify
      data.apx_view = apxResponse;
      data.google_satellite_view = gisResponse;
      data.photo_chamber_1_1 = ch1Response[0];
      data.photo_chamber_1_2 = ch1Response[1];
      data.photo_chamber_1_3 = ch1Response[2];
      data.photo_chamber_1_4 = ch1Response[3];
      data.photo_chamber_2_1 = ch2Response[0];
      data.photo_chamber_2_2 = ch2Response[1];
      data.photo_chamber_2_3 = ch2Response[2];
      data.photo_chamber_2_4 = ch2Response[3];
      data.chamber1 = values.chamber1;
      data.chamber2 = values.chamber2;
      const d1 = new Date();
      data["end_time"] = [d1.getHours(), d1.getMinutes(), d1.getSeconds()];
      setData(data);
      const postData = async () => {
        if (edit === "true") {
          const payload = {
            res_id: rsid,
          };

          await axios
            .post(baseUrl + "/post_a55_resp", data, {
              params: payload,
            })
            .then((res) => {
              localStorage.removeItem("edit");
              setResponse(res.data.out);
              setSpin(false);
              setSuccess(true);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                // removeStorage();
                // navigate("/login");
              }
              error("Error while uploading data try again!");
              setSpin(false);
            });
        } else {
          await axios
            .post(baseUrl + "/post_a55_resp", data)
            .then((res) => {
              // setResponse(res.data.out);
              setResponse("Form Successfully submitted");
              setSpin(false);
              setSuccess(true);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                // removeStorage();
                // navigate("/login");
              }
              error("Error while uploading data try again!");
              setSpin(false);
            });
        }
      };
      postData();
    }
  };
  const onFinishFailed = (errorInfo) => {};
  const error = (errorMsg) => {
    messageApi.open({
      type: "error",
      content: errorMsg,
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        onFinish={nextStep}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 0 }}
        style={{ width: "100%" }}
        wrapperCol={{ span: 24 }}
        initialValues={data}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ImageField
              number={29}
              heading={"Google Satellite View Images"}
              description={"Please upload the requested images."}
              star={true}
              name={"gis_view"}
              multiple={false}
              fileList={gisList}
              setFileList={setGisList}
              api={`/post_a55_gis/${userID}`}
              setResponse={setGisResponse}
              message={
                "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
              }
              image={Picture1}
              maxCount={1}
              setSpin={setSpin}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ImageField
              number={30}
              heading={"Street View Images"}
              description={"Upload an image of the section from APX."}
              star={true}
              name={"apx_view"}
              multiple={false}
              fileList={apxList}
              setFileList={setApxList}
              api={`/post_a55_apx/${userID}`}
              setResponse={setApxResponse}
              message={
                "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
              }
              image={Picture2}
              maxCount={1}
              setSpin={setSpin}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={31}
              heading={"Chamber 1 Number"}
              name="chamber1"
              placeholder={"Enter chamber 1 number"}
              message={"Please enter chamber 1 number"}
              star={true}
              removeValidation="true"
              description={"Enter chamber 1 of your Project"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={32}
              heading={"Chamber 2 Number"}
              name="chamber2"
              placeholder={"Enter chamber 2 number"}
              message={"Please enter chamber 2 number"}
              star={true}
              removeValidation="true"
              description={"Enter chamber 2 of your Project"}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ImageField
              number={33}
              heading={"Chamber 1 Images"}
              description={"Please upload the Chamber 1 Images"}
              star={true}
              name={"ch1_view"}
              multiple={true}
              fileList={ch1List}
              setFileList={setCh1List}
              api={`/post_a55_ch1/${ch1List.length + 1}/${userID}`}
              response={ch1Response}
              setResponse={setCh1Response}
              message={
                "File number limit: 4      Single file size limit: 1GB      Allowed file types: PNG,JPG "
              }
              image={Picture3}
              maxCount={4}
              setSpin={setSpin}
            />
            {/* <ImageField
              number={33}
              heading={"Chamber 1 Images"}
              description={"Please upload the Chamber 1 Images"}
              star={true}
              name={"ch1_view"}
              multiple={true}
              fileList={ch1List}
              setFileList={setCh1List}
              api={`/post_a55_ch1/${userID}`}
              response={ch1Response}
              setResponse={setCh1Response}
              message={
                "File number limit: 4      Single file size limit: 1GB      Allowed file types: PNG,JPG "
              }
              image={Picture3}
              maxCount={4}
              setSpin={setSpin}
            /> */}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ImageField
              number={34}
              heading={"Chamber 2 Images"}
              description={"Please upload the Chamber 2 Images"}
              star={true}
              name={`ch2_view`}
              multiple={true}
              fileList={ch2List}
              setFileList={setCh2List}
              api={`/post_a55_ch2/${ch2List.length + 1}/${userID}`}
              response={ch2Response}
              setResponse={setCh2Response}
              message={
                "File number limit: 4      Single file size limit: 1GB      Allowed file types: PNG,JPG "
              }
              image={Picture4}
              setSpin={setSpin}
              maxCount={4}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginRight: "30px" }}
          >
            <NextButton>
              <BackButton
                onClick={() => {
                  setStepNumber(2);
                }}
              >
                <BackButtonText>Back</BackButtonText>
              </BackButton>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  type="primary"
                  size={"large"}
                  htmlType="submit"
                  style={{ minWidth: 175, height: 55 }}
                >
                  Save
                </Button>
              </Form.Item>
            </NextButton>
          </Col>
        </Row>
      </Form>
      <Modal visible={spin} footer={false} closable={false} centered={true}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        visible={success}
        footer={false}
        closable={false}
        bodyStyle={{ backgroundColor: "#fff" }}
      >
        <div className="i-modal-box">
          <img src={tick} width="200px" />
          <div className="i-modal-head-text">{response}</div>
          <div className="i-modal-text">Thank you for filling the form</div>
          <Link to="/FormFile" id="sf-link-tag">
            <button id="i-done-btn">Done</button>
          </Link>
        </div>
      </Modal>
    </>
  );
}

export default SiteForm;
