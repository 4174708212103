import React, { useEffect, useState } from "react";
import "../../css/AddLocation.css";
import loc from "../../image/location-add.svg";
import { Col, Modal, Row, Space, Spin } from "antd";
import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import { useNavigate } from "react-router-dom";

function AddLocation() {
  const navigate = useNavigate();
  const [model, showModel] = useState(false);
  const [val, setVal] = useState(false);
  const [tags, setTags] = useState([]);
  const [site, setSite] = useState([]);
  const [formSelected, setFormSelected] = useState("");
  const [siteSelected, setSiteSelected] = useState("");
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "" && !tags.includes(event.target.value)) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  async function getArea() {
    const user = localStorage.getItem("A55_Portal");
    const result = await axios
      .get(
        baseUrl +
          "/get_area_by_site?user_id=" +
          user +
          "&form=" +
          formSelected +
          "&site=" +
          siteSelected
      )
      .then((res) => {
        showModel(false);
        const Data = res.data["area"];
        setTags(res.data["area"]);
      })
      .catch((err) => {
        showModel(false);
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    if (formSelected !== "" && siteSelected !== "") getArea();
  }, [siteSelected]);
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  return (
    <div className="user-main">
      <div className="main-loc-div">
        <Row>
          <Col>
            <img src={loc} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <p className="heading-loc-div">Add Location</p>
          </Col>
        </Row>
        <div className="body-loc-div">
          <Row style={{ marginTop: "0px" }}>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1-loc">
                <label id="label-loc">Select Form</label>
                <select
                  id="select-input-loc"
                  name="forms"
                  value={formSelected}
                  onChange={(e) => {
                    showModel(true);
                    e.preventDefault();
                    const form = e.target.value;
                    setFormSelected(form);
                    setSiteSelected("");
                    setTags([]);

                    async function siteForm() {
                      const user = localStorage.getItem("A55_Portal");
                      const result = await axios
                        .get(
                          baseUrl +
                            "/get_sites_by_form?user_id=" +
                            user +
                            "&form=" +
                            form,
                          header
                        )
                        .then((res) => {
                          showModel(false);
                          setSite(res.data["site"]);
                        })
                        .catch((err) => {
                          showModel(false);
                          if (err.response.status === 401) {
                            removeStorage();
                            navigate("/login");
                          }
                        });
                    }

                    siteForm();
                  }}
                >
                  <option value="" disabled selected hidden>
                    Choose Form
                  </option>
                  <option value="A55">A55 Blockage</option>
                  <option value="net">Network Adjustment</option>
                  <option value="D pole">D - Pole</option>
                  <option value="raise">Raise a Case</option>
             
                </select>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1-loc">
                {val !== true ? (
                  <>
                    <label id="label-loc">Site Details</label>
                    <select
                      id="select-input-loc"
                      name="sites"
                      value={siteSelected}
                      onChange={(e) => {
                        e.preventDefault();
                        setSiteSelected(e.target.value);
                        showModel(true);
                        // getArea();
                      }}
                    >
                      <option value="" disabled selected hidden>
                        Choose a Site
                      </option>
                      {site.map((val, key) => {
                        return <option value={val}>{val}</option>;
                      })}
                    </select>
                  </>
                ) : (
                  <div style={{ marginTop: "-40px" }}></div>
                )}
                {val ? (
                  <div className="row-1-loc" style={{ marginTop: "40px" }}>
                    <label id="label-loc">Enter Site</label>
                    <input
                      id="input-loc"
                      type="text"
                      value={siteSelected}
                      placeholder="Enter Site Name"
                      onChange={(e) => {
                        e.preventDefault();
                        setSiteSelected(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {/* {site === [] ? ( */}
              {val !== true ? (
                <button
                  id="add-site-btn"
                  onClick={() => {
                    setVal(true);
                    setTags([]);
                    setSiteSelected("");
                  }}
                >
                  + Add Site
                </button>
              ) : (
                <button id="add-site-btn" onClick={() => setVal(false)}>
                  Cancel
                </button>
              )}
              {/* // ) : ( //{" "} */}
              {/* <button id="add-site-btn" onClick={() => setVal(!val)}>
                // + Add Site //{" "}
              </button> */}
              {/* // )} */}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1-loc">
                <label id="label-loc">Enter Area</label>
                <input
                  id="input-loc"
                  type="text"
                  placeholder="Enter Area Name | Press Enter to add the Tags"
                  onKeyUp={(event) =>
                    event.key === "Enter" ? addTags(event) : null
                  }
                />
                <div className="tags-input">
                  <ul id="tags">
                    {tags.map((tag, index) => (
                      <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span
                          className="tag-close-icon"
                          onClick={() => removeTags(index)}
                        >
                          x
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "3%" }}>
            <Col xs={1} md={1} lg={24}>
              <button
                id="add-btn-loc"
                onClick={(e) => {
                  e.preventDefault();
                  const user = localStorage.getItem("A55_Portal");
                  const postLocation = async () => {
                    if (formSelected === "") {
                      openSweetAlert("Select Form", "error");
                    } else if (siteSelected === "") {
                      openSweetAlert("Add Site", "error");
                    } else if (tags.length === 0) {
                      openSweetAlert("Add tags", "error");
                    } else {
                      let data = {
                        form: formSelected,
                        site: siteSelected,
                        area: tags,
                        user_id: user,
                      };
                      const result = await axios
                        .post(baseUrl + "/post_location", data, header)
                        .then((res) => {
                          openSweetAlert(
                            "Location Added Successfully",
                            "success"
                          );
                        })
                        .catch((err) => {
                          if (err.response.status === 401) {
                            removeStorage();
                            navigate("/login");
                          } else {
                            openSweetAlert("Something Went Wrong!", "error");
                          }
                        });
                      setSite([]);
                      setTags([]);
                      setSiteSelected("");
                      setFormSelected("");
                    }
                  };
                  postLocation();
                }}
              >
                Adding
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default AddLocation;
