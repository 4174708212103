import React from "react";
import { baseUrl } from "../../../../utils/axios/index";
import imp from "../../../../image/upload.svg";
import Dragger from "antd/es/upload/Dragger";
import {
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleTextDiv,
  StyleRedStar,
  StyleDescText,
  ImageDiv,
  ImageUpload,
  ImageUploadButton,
  UploadButton,
  UploadText,
  ImageText,
} from "../styles/main.styles";
import Swal from "sweetalert2";

function ImageField({
  number,
  heading,
  description,
  star,
  name,
  multiple,
  fileList,
  setFileList,
  api,
  setResponse,
  message,
  image,
  maxCount,
  setSpin,
  response,
}) {
  console.log(fileList, "file list up");
  const props = {
    name: name,
    multiple: multiple,
    fileList: fileList,
    listType: "picture",
    maxCount: maxCount,
    action: baseUrl + api,
    onChange(info) {
      // setSpin(true);
      const isImage = info.file.type.startsWith("image/");
      const isAllowedExtension = /\.(jpg|jpeg|png)$/i.test(info.file.name);
      const { status } = info.file;
      let newFileList = [...info.fileList];
      if (status && isAllowedExtension) {
        setFileList(newFileList);

        if (status !== "uploading") {
          setSpin(false);
          if (response) {
            response[fileList.length - 1] = info.file.response;
            setResponse(response);
          } else {
            setResponse(info.file.response);
          }
        }
        if (status === "done") {
          setSpin(false);
        }
      } else if (status === "error" || !isImage || !isAllowedExtension) {
        Swal.fire({
          title: "You can only upload JPG or PNG files!",
          type: "error",
          allowOutsideClick: false,
        });

        setSpin(false);
      }
    },
    // onDrop(e) {},
  };
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv style={{ marginBottom: 15 }}>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <ImageDiv className="imgdiv">
        <img
          src={image}
          alt={"Google"}
          style={{ margin: 10, borderRadius: 10, width: "98%" }}
        />
        <ImageUpload>
          <Dragger {...props} fileList={fileList}>
            <ImageUploadButton>
              <UploadButton>
                <img src={imp} alt={"upload"} />
                <UploadText>Upload</UploadText>
              </UploadButton>
            </ImageUploadButton>
          </Dragger>
        </ImageUpload>
        <ImageText>{message}</ImageText>
      </ImageDiv>
    </StyleTextDiv>
  );
}

export default ImageField;
