import React from "react";
import { Form, Input } from "antd";
import {
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../styles/main.styles";

function TextField({
  number,
  heading,
  description,
  name,
  message,
  star,
  placeholder,
  disabled,
  rule,
}) {
  // const validateSpecialCharacters = (rule, value, callback) => {
  //   const specialCharactersRegex = /[!@#$%^&*(),_'-/.?":{}|<>[\]]/;
  //   // const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>[\/\+]]/;
  //   if (specialCharactersRegex.test(value)) {
  //     callback(`Special characters are not allowed.`);
  //   } else {
  //     callback();
  //   }
  // };

  rule = rule
    ? rule
    : [
        {
          required: true,
          message: message,
        },
        // {
        //   validator: validateSpecialCharacters, // Add the custom validator
        // },
      ];
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <Form.Item label="" name={name} rules={rule} disabled={disabled}>
        <Input
          size={"large"}
          disabled={disabled}
          allowClear={true}
          placeholder={placeholder}
        />
      </Form.Item>
    </StyleTextDiv>
  );
}

export default TextField;
