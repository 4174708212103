import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
  margin-bottom: 80px;
  flex-wrap: wrap;
`;

export const StyledBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  flex-wrap: wrap;
`;

export const StyledHeading = styled.h2`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-top: 40px;

  @media (max-width: 500px) {
    font-size: 17px; // Adjust the font size for screens less than 500 pixels
  }
`;

export const StyledText = styled.h2`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #7fbe42;
  margin-bottom: 40px;
`;

export const StyledNumber = styled.div`
  width: 25px;
  height: 25px;
  background: #7fbe42;
  border-radius: 100%;
  color: #fff;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const StyleTextDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;
export const StyleDescText = styled.span`
  color: #979797;
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
  margin-left: 5%;
  margin-bottom: 5px;
`;
export const StyleNumberDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const StyleImgDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const FlexRowImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const FlexColImg = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 15px;
`;
export const StyleNText = styled.div`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
`;
export const StyleRedStar = styled.span`
  color: red;
`;

export const StyleRadioDiv = styled.div`
  width: auto;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  margin-bottom: 10px;
`;

export const StyleLocalDiv = styled.div`
  width: 48%;
  height: 40px;
  border: 1px solid #e8e8e8;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  flex-wrap: wrap;
`;

export const StyleCheckDiv = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #e8e8e8;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
`;

export const NextButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 90px;
  width: 170px;
  height: 55px;
  border: 1px solid #7fbe42;
  border-radius: 5px;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  width: 43px;
  height: 24px;
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #7fbe42;
`;

export const ImageDiv = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  //justify-content: center;
  align-items: center;
`;

export const ImageUpload = styled.div`
  margin-top: 20px;
  width: 95%;
`;

export const ImageUploadButton = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const UploadButton = styled.div`
  width: 150px;
  border-radius: 5px;
  border: 1px solid #7fbe42;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;
export const StyleLocationDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyleLat = styled.div`
  width: 100%;
  display: flex;
  padding-right: 2px;
`;
export const StyleLng = styled.div`
  width: 100%;
  display: flex;
  padding-left: 2px;
`;

export const UploadText = styled.span`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #7fbe42;
`;

export const ImageText = styled.span`
  margin-top: 20px;
  text-align: center;
  color: #979797;
  margin-bottom: 10px;
`;
