import React from "react";
import logo from "../../../../image/new_logo.png";
import { StyledHeader, StyledHeading, StyledText } from "../styles/main.styles";

function SiteForm({ form_name }) {
  return (
    <StyledHeader>
      <img className="map_logo" src={logo} height="201px" width="400px" />
      <StyledHeading>{form_name}</StyledHeading>
      <StyledText>Please fill the following form</StyledText>
    </StyledHeader>
  );
}

export default SiteForm;
