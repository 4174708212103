import styled from "styled-components";
export const StyledForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Satoshi";
  font-style: normal;
  background-image: url("../image/BG.png");
`;
export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
`;
export const Input = styled.input`
  height: 35px;
  padding-left: 35px;
  padding-bottom: 5px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #b1b1b1;
  background: transparent;
  outline: none;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: medium;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #292d32;
`;
export const StyledIcon = styled.div`
  position: absolute;
  top: 35.35%;
  bottom: 30.38%;
  color: black;
`;
export const EyeIcon = styled.div`
  position: absolute;
  top: 35.35%;
  bottom: 30.38%;
  left: 90%;
  right: 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 100px;
  gap: 20px;
`;
export const SubmitButton = styled.button`
  margin-top: 25px;
  padding: 11px 45px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: none;
`;
