import React, {useState} from "react";
import axios, {baseUrl, removeStorage} from "../../../utils/axios";
import OrganizationModal from "./organizationModal";
import UserModal from "./userModal";
import {useNavigate} from "react-router-dom";

const Index = (props) => {
    const getString = (str) => {
        return str ? str : "";
    };

    const [isOrgModalVisible, setIsOrgModalVisible] = useState(true);
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    let [device, setDevice] = useState(props.editRecord);

    let [organizationName, setOrganizationName] = useState("");
    let [userLimit, setUserLimit] = useState("5");
    let [adminLimit, setAdminLimit] = useState("3");
    let [status, setStatus] = useState(
        device ? getString(device.status) : "Active"
    );
    const navigate = useNavigate()
    const postDevice = async (device) => {
        try {
            await axios
                .post(baseUrl + "/editLicenses", device)
                .then(() => {
                    const promises = [];
                    promises.push(
                        axios
                            .get(baseUrl + "/getAllLicenses")
                            .then((response) => {
                                props.setDataSource(response.data);
                                props.setRowCount(response.data.length);
                                props.excelData = response.data;
                            })
                            .catch((err) => {
                                if (err.response.status === 401) {
                                    removeStorage()
                                    navigate("/login")
                                }
                            })
                    );
                    return Promise.all(promises);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        removeStorage()
                        navigate("/login")
                    }

                });
        } catch (err) {

        }
    };

    return (
        <>
            <OrganizationModal
                isModalVisible={isOrgModalVisible}
                setIsModalVisible={setIsOrgModalVisible}
                setIsUserModalVisible={setIsUserModalVisible}
                setIsSAModalVisible={props.setIsSAModalVisible}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                adminLimit={adminLimit}
                setAdminLimit={setAdminLimit}
                editRecord={null}
            />

            <UserModal
                isModalVisible={isUserModalVisible}
                setIsModalVisible={setIsUserModalVisible}
                setIsOrgModalVisible={setIsOrgModalVisible}
                setIsSAModalVisible={props.setIsSAModalVisible}
                organizationName={organizationName}
                userLimit={userLimit}
                adminLimit={adminLimit}
                editRecord={null}
            />
        </>
    );
};

export default Index;
