import React from "react";
import axios from "axios";
export const baseUrl = "https://test2.nets-x-map.com";
// export const baseUrl = "http://18.169.194.234:5010";
// export const baseUrl = "http://localhost:5010";

export const header = {
  header: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
const instance = axios.create();
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("net_x_map_token");
  if (token) {
    config.headers["X-Auth-Key"] = token;
  }
  return config;
});
export const removeStorage = () => {
  localStorage.removeItem("siteSelectedValue");
  localStorage.removeItem("selectedValue");
  localStorage.removeItem("net_x_map_token");
  localStorage.removeItem("A55_Portal_type");
  localStorage.removeItem("A55_Portal");
  localStorage.removeItem("A55_name");
};

export default instance;
