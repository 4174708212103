import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Radio, Row, Space, Spin, Upload } from "antd";
import "../../css/Network.css";
import logo from "../../image/Logo.svg";
import { UploadOutlined } from "@ant-design/icons";
import satview from "../../image/Satview.png";
import { Link, useNavigate } from "react-router-dom";
import tick from "../../image/animation.gif";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";

function Network() {
  const navigate = useNavigate();
  const d1 = new Date();
  const [startTime, setTime] = useState([0, 0, 0]);
  useEffect(() => {
    setTime([d1.getHours(), d1.getMinutes(), d1.getSeconds()]);
  }, []);
  const [resp_id, setRespId] = useState(-1);
  const [exchange, setExchange] = useState("");
  const [crf, setCrf] = useState("");
  const [orReference, setOrReference] = useState("");
  const [noi, setNoi] = useState("");
  const [issue, setIssue] = useState("");
  const [myDate, setMyDate] = useState("");
  const [cpName, setCpName] = useState("");
  const [idPole, setIdPole] = useState("");
  const [gridReference, setGridReference] = useState("");
  const [postCodeLat, setPostCodeLat] = useState("");
  const [postCodeLong, setPostCodeLong] = useState("");
  const [address, setAddress] = useState("");
  const [dpId, setDpId] = useState("");
  const [secondIssue, setSecondIssue] = useState("");
  const [issueNote, setIssueNote] = useState("");
  const [excitingWires, setExcitingWires] = useState("");
  const [forcostWires, setForcostWires] = useState("");
  const [furnitureIssue, setFurnitureIssue] = useState("");
  const [maxPoleCapacity, setMaxPoleCapacity] = useState("");
  const [thirdIssue, setThirdIssue] = useState("Pole Top Capacity");
  const [picOne, setPicOne] = useState([]);
  const [picTwo, setPicTwo] = useState([]);
  const [picThird, setPicThird] = useState([]);
  const [picFourth, setPicFourth] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [model, showModel] = useState(false);
  const [name, setName] = useState("Network Adjustment");

  useEffect(() => {
    const form = localStorage.getItem("form_selected");
    if (form === "Raise") {
      setName("Raise A Case");
    } else if (form === "D-Pole") {
      setName("D-Pole");
    }
  }, []);

  useEffect(() => {
    const type = localStorage.getItem("A55_Portal_type");
    if (type === "Admin") {
      showModel(true);
      const id_ = localStorage.getItem("resp_id");
      axios
        .get(baseUrl + "/get_network_edit?resp_id=" + id_, header)
        .then((res) => {
          setRespId(id_);
          setExchange(res.data["exchange"]);
          setCrf(res.data["crf"]);
          setOrReference(res.data["or_reference"]);
          setNoi(res.data["noi"]);
          setIssue(res.data["issue"]);
          var now = new Date(res.data["date"]);
          var d_y = ("0" + now.getDate()).slice(-2);
          var mh = ("0" + (now.getMonth() + 1)).slice(-2);
          var td = now.getFullYear() + "-" + mh + "-" + d_y;
          setMyDate(td);
          setCpName(res.data["cp_name"]);
          setIdPole(res.data["id_pole"]);
          setGridReference(res.data["grid_reference"]);
          setPostCodeLat(res.data["post_code_lat"]);
          setPostCodeLong(res.data["post_code_long"]);
          setAddress(res.data["address"]);
          setDpId(res.data["dp_id"]);
          setSecondIssue(res.data["secondIssue"]);
          setIssueNote(res.data["issue_note"]);
          setExcitingWires(res.data["exciting_wires"]);
          setForcostWires(res.data["forcost_wires"]);
          setFurnitureIssue(res.data["furniture_issue"]);
          setMaxPoleCapacity(res.data["max_pole_capacity"]);
          setThirdIssue(res.data["third_issue"]);
          setPicOne(res.data["pic_one"]);
          setPicTwo(res.data["picTwo"]);
          setPicThird(res.data["picThird"]);
          setPicFourth(res.data["picFourth"]);
          showModel(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          showModel(false);
        });
    }
  }, []);

  const handlePicOneUpload = ({ fileList }) => {
    setPicOne(fileList);
  };

  const handlePicTwoUpload = ({ fileList }) => {
    setPicTwo(fileList);
  };
  const handlePicThirdUpload = ({ fileList }) => {
    setPicThird(fileList);
  };
  const handlePicFourthUpload = ({ fileList }) => {
    setPicFourth(fileList);
  };

  const onChange = (e) => {
    setThirdIssue(e.target.value);
  };

  const handleSubmit = async (event) => {
    const user_id = localStorage.getItem("A55_Portal");
    const site = localStorage.getItem("siteSelectedValue");
    const area = localStorage.getItem("selectedValue");
    event.preventDefault();
    var dt = new Date();
    const data = {
      start_time: startTime,
      end_time: [dt.getHours(), dt.getMinutes(), dt.getSeconds()],
      user_id,
      site,
      area,
      exchange,
      crf,
      or_reference: orReference,
      noi,
      issue,
      date: myDate,
      cp_name: cpName,
      id_pole: idPole,
      grid_reference: gridReference,
      post_code_lat: postCodeLat,
      post_code_long: postCodeLong,
      address: address,
      dp_id: dpId,
      secondIssue: secondIssue,
      issue_note: issueNote,
      exciting_wires: excitingWires,
      forcost_wires: forcostWires,
      furniture_issue: furnitureIssue,
      max_pole_capacity: maxPoleCapacity,
      third_issue: thirdIssue,
      pic_one: picOne,
      pic_two: picTwo,
      pic_third: picThird,
      pic_forth: picFourth,
    };
    if (resp_id !== -1) {
      data["resp_id"] = resp_id;
    }
    const result = await axios
      .post(baseUrl + "/post_network", data, header)
      .then((res) => {
        setIsModalVisible(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }

        setIsModalVisible(true);
      });
  };

  const showModal = () => {
    // handleSubmit()
    // setIsModalVisible(true);
  };

  // const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     const data = new FormData();
  //     data.append('image', picOne);
  //     axios({
  //         method: "post", url: baseUrl + "/test_upload", data: data, headers: {"Content-Type": "multipart/form-data"},
  //     })
  //         .then(function (response) {
  //         }).catch(function (response) {
  //     });
  //
  // };

  const handleOk = () => {
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="n-main">
      <div className="n-header-section">
        <div className="n-Netslogo">
          <img src={logo} width="113px" height="66px" />
        </div>
        <div className="n-heading-1">{name}</div>
        <div className="n-heading-2">Please fill in one form per blockage</div>
      </div>
      <div className="n-form-section">
        <form onSubmit={handleSubmit}>
          <div>
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number">1</div>
                  </div>
                  <div className="n-question">Exchange</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={exchange}
                      onChange={(e) => setExchange(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Islamabad"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number">2</div>
                  </div>
                  <div className="n-question">CRF</div>
                  <div className="n-input-fields">
                    <input
                      value={crf}
                      onChange={(e) => setCrf(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Sector F10"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-2">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number">3</div>
                  </div>
                  <div className="n-question">OR Reference</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={orReference}
                      onChange={(e) => setOrReference(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number">4</div>
                  </div>
                  <div className="n-question">NOI</div>
                  <div className="n-input-fields">
                    <input
                      value={noi}
                      onChange={(e) => setNoi(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-3">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number">5</div>
                  </div>
                  <div className="n-question">Issue</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={issue}
                      onChange={(e) => setIssue(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number">6</div>
                  </div>
                  <div className="n-question">Date</div>
                  <div className="n-input-fields">
                    <input
                      value={myDate}
                      onChange={(e) => setMyDate(e.target.value)}
                      id="n-date-input"
                      type="date"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-4">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number">7</div>
                  </div>
                  <div className="n-question">CP Name</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={cpName}
                      onChange={(e) => setCpName(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number">8</div>
                  </div>
                  <div className="n-question">ID Pole</div>
                  <div className="n-input-fields">
                    <input
                      value={idPole}
                      onChange={(e) => setIdPole(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-5">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number">9</div>
                  </div>
                  <div className="n-question">Grid Reference</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={gridReference}
                      onChange={(e) => setGridReference(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">10</div>
                  </div>
                  <div className="n-question">Post Code</div>
                  {/* <div className="n-box"> */}
                  <Row>
                    <Col span={11}>
                      <input
                        value={postCodeLat}
                        onChange={(e) => setPostCodeLat(e.target.value)}
                        id="n-in-1"
                        type="number"
                        step={0.1}
                        placeholder="Latitude"
                        required
                      />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <input
                        value={postCodeLong}
                        onChange={(e) => setPostCodeLong(e.target.value)}
                        id="n-in-2"
                        type="number"
                        step={0.1}
                        placeholder="Latitude"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-6">
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">11</div>
                  </div>
                  <div className="n-question">Address</div>
                  <div className="n-input-fields">
                    <input
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      id="n-in-full"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-7">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">12</div>
                  </div>
                  <div className="n-question">DP ID</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={dpId}
                      onChange={(e) => setDpId(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">13</div>
                  </div>
                  <div className="n-question">Issue</div>
                  <div className="n-input-fields">
                    <input
                      value={secondIssue}
                      onChange={(e) => setSecondIssue(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-8">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">14</div>
                  </div>
                  <div className="n-question">Issue Note</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={issueNote}
                      onChange={(e) => setIssueNote(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">15</div>
                  </div>
                  <div className="n-question">Exciting wires</div>
                  <div className="n-input-fields">
                    <input
                      value={excitingWires}
                      onChange={(e) => setExcitingWires(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-9">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">16</div>
                  </div>
                  <div className="n-question">Forcost Wires</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={forcostWires}
                      onChange={(e) => setForcostWires(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">17</div>
                  </div>
                  <div className="n-question">Furniture Issue</div>
                  <div className="n-input-fields">
                    <input
                      value={furnitureIssue}
                      onChange={(e) => setFurnitureIssue(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-10">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">18</div>
                  </div>
                  <div className="n-question">Max Pole Capacity</div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="n-input-fields"
                  >
                    <input
                      value={maxPoleCapacity}
                      onChange={(e) => setMaxPoleCapacity(e.target.value)}
                      id="n-in"
                      type="text"
                      placeholder="Enter your answer"
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">19</div>
                  </div>
                  <div className="n-question">Issue</div>
                  <div className="n-description">
                    Indicate whether works be on private property
                  </div>
                  <Radio.Group onChange={onChange} value={thirdIssue}>
                    <Row>
                      <Col span={6}>
                        <Radio
                          style={{
                            width: "155px",
                            marginRight: "5px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "5px",
                          }}
                          value="Pole Top Capacity"
                        >
                          Pole Top Capacity
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio
                          style={{
                            width: "155px",
                            marginLeft: "5px",
                            marginRight: "5px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "5px",
                          }}
                          value="Pole to Pole"
                        >
                          Pole to Pole
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio
                          style={{
                            width: "170px",
                            marginRight: "5px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "5px",
                          }}
                          value="Drop Wire Capacity"
                        >
                          Drop Wire Capacity
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio
                          style={{
                            width: "160px",
                            marginLeft: "10px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "5px",
                          }}
                          value="Defective pole"
                        >
                          Defective pole
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: "15px" }} className="n-row-11">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div style={{ marginRight: "10px" }} className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">20</div>
                  </div>
                  <div className="n-question">Picture 1</div>
                  <div className="n-description">
                    Please upload the requested images
                  </div>
                  <div className="n-image-input">
                    <div style={{ padding: 0 }}>
                      <img
                        src={satview}
                        height="150px"
                        width="100%"
                        style={{ padding: 0 }}
                      />
                    </div>
                    <div className="n-upload-file-div">
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        size="large"
                      >
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                          fileList={picOne}
                          //   onPreview={handlePreview}
                          onChange={handlePicOneUpload}
                          beforeUpload={() => false}
                          maxCount={2}
                          multiple
                        >
                          <div style={{ margin: "0 auto" }}>
                            <Button icon={<UploadOutlined />}>
                              Upload File
                            </Button>
                          </div>
                        </Upload>
                      </Space>
                    </div>
                    <div className="n-info-div">
                      <div className="n-info-text">File number limit: 2</div>
                      <div className="n-info-text">
                        Single file size limit: 1GB
                      </div>
                      <div className="n-info-text">
                        Allowed file types: PNG,JPG
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">21</div>
                  </div>
                  <div className="n-question">Picture 2</div>
                  <div className="n-description">
                    Please upload the requested images
                  </div>
                  <div className="n-image-input">
                    <div style={{ padding: 0 }}>
                      <img
                        src={satview}
                        height="150px"
                        width="100%"
                        style={{ padding: 0 }}
                      />
                    </div>
                    <div className="n-upload-file-div">
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        size="large"
                      >
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                          fileList={picTwo}
                          onChange={handlePicTwoUpload}
                          beforeUpload={() => false}
                          maxCount={2}
                          multiple
                        >
                          <Button icon={<UploadOutlined />}>Upload File</Button>
                        </Upload>
                      </Space>
                    </div>
                    <div className="n-info-div">
                      <div className="n-info-text">File number limit: 2</div>
                      <div className="n-info-text">
                        Single file size limit: 1GB
                      </div>
                      <div className="n-info-text">
                        Allowed file types: PNG,JPG
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }} className="n-row-12">
            <Row>
              <Col xs={24} md={12} lg={12}>
                <div style={{ marginRight: "10px" }} className="n-ques-1">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">22</div>
                  </div>
                  <div className="n-question">Picture 3</div>
                  <div className="n-description">
                    Please upload the requested images
                  </div>
                  <div className="n-image-input">
                    <div style={{ padding: 0 }}>
                      <img
                        src={satview}
                        height="150px"
                        width="100%"
                        style={{ padding: 0 }}
                      />
                    </div>
                    <div className="n-upload-file-div">
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        size="large"
                      >
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                          fileList={picThird}
                          //   onPreview={handlePreview}
                          onChange={handlePicThirdUpload}
                          beforeUpload={() => false}
                          maxCount={2}
                          multiple
                        >
                          <Button icon={<UploadOutlined />}>Upload File</Button>
                        </Upload>
                      </Space>
                    </div>
                    <div className="n-info-div">
                      <div className="n-info-text">File number limit: 2</div>
                      <div className="n-info-text">
                        Single file size limit: 1GB
                      </div>
                      <div className="n-info-text">
                        Allowed file types: PNG,JPG
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="n-ques-2">
                  <div className="n-question-number-div">
                    <div className="n-question-number-double">23</div>
                  </div>
                  <div className="n-question">Picture 4</div>
                  <div className="n-description">
                    Please upload the requested images
                  </div>
                  <div className="n-image-input">
                    <div style={{ padding: 0 }}>
                      <img
                        src={satview}
                        height="150px"
                        width="100%"
                        style={{ padding: 0 }}
                      />
                    </div>
                    <div className="n-upload-file-div">
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        size="large"
                      >
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                          fileList={picFourth}
                          //   onPreview={handlePreview}
                          onChange={handlePicFourthUpload}
                          beforeUpload={() => false}
                          maxCount={2}
                          multiple
                        >
                          <Button icon={<UploadOutlined />}>Upload File</Button>
                        </Upload>
                      </Space>
                    </div>
                    <div className="n-info-div">
                      <div className="n-info-text">File number limit: 2</div>
                      <div className="n-info-text">
                        Single file size limit: 1GB
                      </div>
                      <div className="n-info-text">
                        Allowed file types: PNG,JPG
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="n-row-13">
            <div className="n-button-flex">
              <Link id="n-link-tag" to="/SelectForm">
                <div className="n-back-button">
                  <div className="n-back-text">Back</div>
                </div>
              </Link>
              <input id="n-submit-btn" type="submit" value="Submit" />
              <div className="i-modal-box">
                <Modal
                  visible={isModalVisible}
                  footer={false}
                  closable={false}
                  bodyStyle={{ backgroundColor: "#fff" }}
                >
                  <div className="i-modal-box">
                    {/* <div className="i-animation-div"> */}
                    <img src={tick} height="200px" width="200px" />
                    {/* </div> */}
                    <div className="i-modal-head-text">
                      Submitted Successfully
                    </div>
                    <div className="i-modal-text">
                      Thank you for filling the form
                    </div>
                    <Link to="/SelectForm" id="sf-link-tag">
                      <button id="i-done-btn">Done</button>
                    </Link>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default Network;
