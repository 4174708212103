import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Chart, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import axios, {baseUrl, removeStorage} from "../../../../utils/axios";
import {Bar} from "react-chartjs-2";
import {useNavigate} from "react-router-dom";
import {Space, Spin} from "antd";

Chart.defaults.datasets.bar.barThickness = 15;
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function BarChart(props) {
    const nav = useNavigate()
    const [loading, setLoading] = useState(true);
    const [barChart, setBarChart] = useState("");
    useEffect(() => {
        setLoading(true);
        const topSites = async () => {
            try {
                const user_id = localStorage.getItem("A55_Portal")
                console.log("user-id",user_id)

                const res = await axios.get(baseUrl + "/responseBarChart?selected="+props.statusSelected+"&user_id="+user_id);
                console.log("resid",res)
                setBarChart(res.data);
            } catch (err) {
                if (err.response.status === 401) {
                    removeStorage()
                    nav("/login")
                }
            }
            setLoading(false);
        };
        topSites();
    }, [props.statusSelected]);


    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", height: "300px", width: "100%", padding: "10px"}}>
            {(loading)?
                <Space size="large">
                    <Spin size="large" />
                </Space>
                :
                <Bar
                    data={{
                        labels: barChart['label'],
                        datasets: [
                            {
                                label: "A55",
                                data: barChart['A55 BLOCKAGE FORM'],
                                backgroundColor: "#A1B0FE",
                                borderRadius: "20",
                                minBarLength: 1,

                            }, 
                            {
                                label: "HyperOptic",
                                data: barChart['HYPEROPTICFORM'],
                                backgroundColor: "#A1B0FE",
                                borderRadius: "20",
                                minBarLength: 1,
                            },
                            {
                                label: "D Pole",
                                data: barChart['D POLE'],
                                backgroundColor: "#9DD9EC",
                                borderRadius: "20",
                                minBarLength: 1,
                            }, {
                                label: "Raise a case",
                                data: barChart['RAISE A CASE'],
                                backgroundColor: "#38CBAA",
                                borderRadius: "20",
                                minBarLength: 1,
                            }, {
                                label: "Network",
                                data: barChart['NETWORK'],
                                backgroundColor: "#ABE690",
                                borderRadius: "20",
                                minBarLength: 1,
                            }
                        ]
                    }}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                },
                            },
                            y: {
                                grid: {
                                    display: false,
                                },
                                beginAtZero: true,
                                ticks:{
                                    stepSize: 1
                                }
                            },
                        },
                    }}
                />
            }
        </div>
    );
}

export default BarChart;
