import { useEffect, useState } from "react";
import "./showuser.css";
import show from "../../../image/add_user.svg";
import { Col, Modal, Row, Spin, Switch, Table } from "antd";
import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../../utils/axios";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { BackwardOutlined } from "@ant-design/icons";
import {
  Button,
  StyledAddUserButton,
  StyledInput,
  StyledInputBox,
  StyledLabel,
  StyledModalHeading,
  StyledSelectInput,
  StyledUserContainer,
  StyledUserHeading,
} from "./styles/main.styles.js";
import { useNavigate } from "react-router-dom";
const admin = localStorage.getItem("A55_Portal");
console.log(admin, "admin id");
function ShowUser() {
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  console.log(name, "name");
  const [userId, setUserID] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState();
  const [status, setStatus] = useState("Active");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [edit, setEdit] = useState(false);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const navigate = useNavigate();
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  useEffect(() => {
    // Fetch user login credentials or any other initialization logic

    // Clear email and password fields
    setEmail("");
    setPassword("");
  }, []); // Make sure to include any dependencies if needed

  const userData = async () => {
    setLoading(true);
    const user = localStorage.getItem("A55_Portal");
    const result = await axios
      .get(baseUrl + "/get_user?user_id=" + user, header)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    userData();
  }, []);

  const onSelectChange = (user_ids) => {
    console.log(user_ids, "user ids");
    setSelectedRowKeys(user_ids);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selection: Table.SELECTION_ALL,
  };
  const handleDelete = async () => {
    const user = localStorage.getItem("A55_Portal");
    console.log(user, "user");

    if (selectedRowKeys.length > 0) {
      let data = { user_ids: selectedRowKeys, requesting_user_id: user };
      // const idd = Number(data.user_ids);
      console.log(data, "data");
      const result = await axios
        .post(baseUrl + "/deleteUsers", data, header)
        .then((res) => {
          openSweetAlert(res.data, "success");
        })
        .catch((err) => {
          openSweetAlert("Cannot delete own account", "error");
        });
      userData();
    } else {
      openSweetAlert("No user selected", "error");
    }
  };

  const handleEditData = (user_id) => {
    axios
      .get(baseUrl + "/get_user_details?user_id=" + user_id, header)
      .then((res) => {
        setEmail(res.data.email);
        setName(res.data.name);
        setRole(res.data.role);
        setPassword(res.data.password);
        setStatus(res.data.status);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  };

  const showModal = (e, record = null) => {
    if (record) {
      setEdit(true);
      setUserID(record.user_id);
      handleEditData(record.user_id);
    } else {
      setEdit(false);
      setUserID("");
      setEmail("");
      setName("");
      setRole("");
      // setPassword("");
      setStatus("Active");
    }
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              color: "#45A044",
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontSize: "14px",
              letterSpacing: "0.03em",
              fontWeight: "500",
            },
          },
          children: (
            <div
              onClick={(e) => showModal(e, record)}
              style={{ cursor: "pointer" }}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const uploadData = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      openSweetAlert("Invalid email format", "error");
      return; // Stop further execution if email is invalid
    }

    const user = localStorage.getItem("A55_Portal");
    let data = {
      admin: user,
      user_id: userId,
      user_name: name,
      email,
      password,
      role:
        localStorage.getItem("A55_Portal_type") === "Super Admin"
          ? "Admin"
          : role,
      status,
    };
    const result = await axios
      .post(baseUrl + "/post_user", data, header)
      .then((res) => {
        if (res && res.data.length > 0) {
          setEmail("");
          setName("");
          setRole("");
          setPassword("");
          setStatus("");

          handleCancel();
          openSweetAlert(res.data, "success");
        }
      })
      .catch((err) => {
        console.log(err, "errerrerrerrerrerr");
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        } else if (err.response.status === 409) {
          openSweetAlert(err.response.data.message, "error");
        }
        if (err.response.status === 500) {
          openSweetAlert(err.response.data, "error");
        }
      });
    userData();
  };

  const validateEmail = (email) => {
    if (!email.includes("@")) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const allowedDomainExtensions = ["com", "net", "org"]; // Add more extensions as needed
    const [, domain] = email.split("@");
    const validDomainExtension = allowedDomainExtensions.includes(
      domain.split(".").pop().toLowerCase()
    );
    return emailRegex.test(email) && validDomainExtension;
  };

  return (
    <>
      <StyledUserContainer>
        <Row>
          <Col xs={24} md={12} lg={21} style={{ display: "flex" }}>
            <img src={show} width="23px" height="23px" />
            <StyledUserHeading>Show User</StyledUserHeading>
          </Col>
          <Col xs={12} md={12} lg={3} style={{ textAlign: "right" }}>
            <StyledAddUserButton onClick={showModal}>+ Add</StyledAddUserButton>
          </Col>
        </Row>

        <Modal
          visible={isModalVisible}
          modalOptions={{ dismissible: true }}
          footer={false}
          closable={true}
          width={1000}
          keyboard={true}
          onCancel={handleCancel}
          bodyStyle={{ backgroundColor: "#fff" }}
        >
          <form onSubmit={uploadData}>
            <>
              <Row
                style={{
                  paddingBottom: "15px",
                  borderBottom: " 0.3px solid rgba(0, 0, 0, 0.3)",
                }}
              >
                <Col xs={22} md={24} lg={4}>
                  {localStorage.getItem("A55_Portal_type") === "Super Admin" ? (
                    <StyledModalHeading>+ Add Admin</StyledModalHeading>
                  ) : (
                    <StyledModalHeading>+ Add User</StyledModalHeading>
                  )}
                </Col>
                <Col xs={2} md={1} lg={18}></Col>
                <Col xs={22} md={24} lg={2}>
                  <Switch
                    checked={status === "Active"}
                    onChange={(checked, e) =>
                      setStatus(checked ? "Active" : "Inactive")
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <StyledInputBox>
                    <StyledLabel>User ID</StyledLabel>
                    <StyledInput
                      value={userId}
                      type="text"
                      onChange={(e) => setUserID(e.target.value)}
                      disabled={edit}
                      required
                    />
                  </StyledInputBox>
                </Col>
                <Col xs={2} md={2} lg={1}></Col>

                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <StyledInputBox>
                    <StyledLabel>Name</StyledLabel>
                    <StyledInput
                      // value={name}
                      type="text"
                      onChange={(e) => {
                        const inputName = e.target.value;
                        // Regular expression to match only alphabetic characters and spaces
                        const nameRegex = /^[A-Za-z ]+$/;

                        if (nameRegex.test(inputName)) {
                          // Input is valid, set the name
                          setName(inputName);
                        }
                        // You can optionally display an error message here
                      }}
                      required={true}
                    />
                  </StyledInputBox>
                </Col>

                <Col xs={2} md={2} lg={1}></Col>
                <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                  <StyledInputBox>
                    <StyledLabel>Email Address</StyledLabel>
                    <StyledInput
                      value={email}
                      // type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      autoComplete={"off"}
                      style={{ borderColor: isValidEmail ? "#ccc" : "red" }}
                    />
                    {!isValidEmail && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Invalid email format
                      </p>
                    )}
                  </StyledInputBox>
                </Col>
                <Col xs={2} md={2} lg={1}></Col>
                <>
                  <Col xs={22} md={12} lg={7} style={{ marginBottom: "20px" }}>
                    <StyledInputBox>
                      <StyledLabel>Password</StyledLabel>
                      <StyledInput
                        required={true}
                        // type={type}
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete={"off"}
                      />
                      <span
                        class="flex justify-around items-center"
                        onClick={handleToggle}
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <Icon class="mr-10" icon={icon} size={25} />
                      </span>
                    </StyledInputBox>
                  </Col>
                  <Col xs={2} md={2} lg={1}></Col>
                </>
                <Col xs={22} md={12} lg={7}>
                  <StyledInputBox>
                    <StyledLabel>Role</StyledLabel>
                    {localStorage.getItem("A55_Portal_type") ===
                    "Super Admin" ? (
                      <StyledInput
                        placeholder="Admin"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        // required={true}
                        disabled
                      ></StyledInput>
                    ) : (
                      <StyledSelectInput
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required={true}
                      >
                        <option value="" disabled selected hidden>
                          Select Role
                        </option>
                        {localStorage.getItem("A55_Portal_type") ===
                        "Super Admin" ? (
                          <option value="Admin">Admin</option>
                        ) : (
                          <option value="User">User</option>
                        )}
                      </StyledSelectInput>
                    )}

                    {/* <StyledSelectInput
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required={true}
                    >
                      <option value="" disabled selected hidden>
                        Select Role
                      </option>
                      {localStorage.getItem("A55_Portal_type") ===
                      "Super Admin" ? (
                        <option value="Admin">Admin</option>
                      ) : (
                        <option value="User">User</option>
                      )}
                    </StyledSelectInput> */}
                  </StyledInputBox>
                </Col>
                <Col xs={10} md={10} lg={10}></Col>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <Col xs={2} md={2} lg={10}></Col>
                <Col xs={22} md={12} lg={4}>
                  {!edit ? (
                    <Button type="submit">+ Add</Button>
                  ) : (
                    <Button type="submit">+ Edit</Button>
                  )}
                </Col>
                <Col xs={2} md={2} lg={10}></Col>
              </Row>
            </>
          </form>
        </Modal>
        <button
          style={{
            color: "white",
            background: "#d2042d",
            fontWeight: "bolder",
            padding: "5px 15px",
            border: "none",
            borderRadius: "5px",
            marginBottom: "10px",
            fontSize: "12px",
          }}
          onClick={handleDelete}
        >
          Delete
        </button>
        <Table
          style={{ borderTop: "0.8px solid rgba(0, 0, 0, 0.12)" }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          size="medium"
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          rowKey="user_id"
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: loading,
          }}
          scroll={{
            x: 1100,
          }}
        />
      </StyledUserContainer>
    </>
  );
}

export default ShowUser;
