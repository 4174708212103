import React, { useEffect, useState } from "react";
import "../../css/SelectArea.css";
import logo from "../../image/Logo.svg";
import { useNavigate } from "react-router-dom";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import { Button, Radio, Select } from "antd";
import { Modal, Space, Spin } from "antd";

function SelectArea() {
  const navigate = useNavigate();
  const [model, showModel] = useState(true);
  const [chooseForm, setChooseForm] = useState("");
  const [site, setSite] = useState([]);

  const [areaList, setAreaList] = useState([]);
  const [siteValue, setSiteValue] = useState("");

  const form = localStorage.getItem("form_selected");
  const user = localStorage.getItem("A55_Portal");

  const [value, setValue] = useState("");

  const onChangeAreaList = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    async function siteForm() {
      const result = await axios
        .get(
          baseUrl +
            "/get_user_response_sites?user_id=" +
            user +
            "&form=" +
            form,
          header
        )
        .then((res) => {
          setSite(res.data["sites"]);
          showModel(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          showModel(false);
        });
    }
    siteForm();
  }, []);

  const handleChange = async (value) => {
    setSiteValue(value);
    const result = await axios
      .get(
        baseUrl +
          "/get_user_response_area?user_id=" +
          user +
          "&form=" +
          form +
          "&site=" +
          value,
        header
      )
      .then((res) => {
        setAreaList(res.data["area"]);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  };

  const NextPage = () => {
    localStorage.setItem("siteSelectedValue", siteValue);
    localStorage.setItem("selectedValue", value);
    if (form === "A55 BLOCKAGE FORM") {
      navigate("/SiteForm");
    } else {
      navigate("/Network");
    }
  };

  return (
    <div className="sa-main">
      <div className="sa-header-section">
        <div className="sa-Netslogo">
          <img src={logo} width="250px" height="130px" />
        </div>
        <div className="sa-heading-1">{form}</div>
        <div className="sa-heading-2">Please select your area</div>

        <Select
          placeholder="Select"
          style={{
            width: 120,
          }}
          onChange={handleChange}
        >
          {site.map((val, key) => {
            return (
              <option key={key} value={val}>
                {val}
              </option>
            );
          })}
        </Select>
        <br />
        <div style={{ width: "350px", textAlign: "left" }}>
          {chooseForm === ""
            ? areaList.map((val, key) => {
                return val !== "" ? (
                  <Radio.Group
                    key={key}
                    onChange={onChangeAreaList}
                    value={value}
                  >
                    <Radio value={val}>{val}</Radio>
                  </Radio.Group>
                ) : null;
              })
            : null}
        </div>
        <div id="sf-link-tag">
          {(siteValue && value) !== "" ? (
            <Button id="sa-next-btn" onClick={NextPage}>
              Next
            </Button>
          ) : (
            <Button disabled id="sa-next-btn" onClick={NextPage}>
              Next
            </Button>
          )}
        </div>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default SelectArea;
