// import React from "react";
// import {Form, Input, InputNumber} from "antd";
// import {
//     StyleDescText,
//     StyledNumber,
//     StyleLat,
//     StyleLng,
//     StyleLocationDiv,
//     StyleNText,
//     StyleNumberDiv,
//     StyleRedStar,
//     StyleTextDiv
// } from "../styles/main.styles";

// function TextField({number, heading, description, name1, name2, message1, message2, star, placeholder1, placeholder2}) {

//     return (
//         <StyleTextDiv>
//             <StyleNumberDiv>
//                 <StyledNumber>{number}</StyledNumber>
//                 <StyleNText> {heading}</StyleNText>
//                 {star ? <StyleRedStar>*</StyleRedStar> : null}
//             </StyleNumberDiv>
//             <StyleNumberDiv>
//                 <StyleDescText>{description}</StyleDescText>
//             </StyleNumberDiv>
//             <StyleLocationDiv>
//                 <StyleLat>
//                     <Form.Item
//                         label=""
//                         name={name1}
//                         rules={[
//                             {
//                                 required: true,
//                                 message: message1,
//                             },
//                         ]}
//                         style={{width: "100%"}}
//                     >
//                         <InputNumber style={{width: "100%"}} size={"large"} allowClear={true} placeholder={placeholder1}
//                                      min={-90} max={90}/>
//                     </Form.Item>
//                 </StyleLat>
//                 <StyleLng>
//                     <Form.Item
//                         label=""
//                         name={name2}
//                         rules={[
//                             {
//                                 required: true,
//                                 message: message2,
//                             },
//                         ]}
//                         style={{width: "100%"}}
//                     >
//                         <InputNumber style={{width: "100%"}} size={"large"} allowClear={true} placeholder={placeholder2}
//                                      min={-180} max={180}/>
//                     </Form.Item>
//                 </StyleLng>
//             </StyleLocationDiv>
//         </StyleTextDiv>
//     )
// }

// export default TextField;
import React, { useState } from "react";
import { Form, Input, InputNumber } from "antd";
import {
  StyleDescText,
  StyledNumber,
  StyleLat,
  StyleLng,
  StyleLocationDiv,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../styles/main.styles";

function TextField({
  onClick,
  number,
  heading,
  description,
  name1,
  name2,
  message1,
  message2,
  star,
  placeholder1,
  placeholder2,
}) {
  // Custom validator function to check if the input is a valid number
  const isNumber = (value) => {
    if (isNaN(value)) {
      //   return Promise.reject('Please enter a valid number.');
    }
    return Promise.resolve();
  };

  const [validateStatus1, setValidateStatus1] = useState(null);
  const [validateStatus2, setValidateStatus2] = useState(null);

  const handleValueChange1 = (value) => {
    setValidateStatus1(value ? "success" : "error");
  };

  const handleValueChange2 = (value) => {
    setValidateStatus2(value ? "success" : "error");
  };

  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <StyleLocationDiv>
        <StyleLat>
          <Form.Item
            label=""
            name={name1}
            rules={[
              {
                required: true,
                message: message1,
              },
              {
                validator: isNumber,
              },
            ]}
            style={{ width: "100%" }}
            hasFeedback={true}
            validateStatus={validateStatus1}
          >
            <InputNumber
              style={{ width: "100%" }}
              size={"large"}
              allowClear={true}
              placeholder={placeholder1}
              onChange={handleValueChange1}
              onKeyPress={onClick}
              step={1}
              // min={1}
              // max={10}
              // defaultValue={3}
            />
          </Form.Item>
        </StyleLat>
        <StyleLng>
          <Form.Item
            label=""
            name={name2}
            rules={[
              {
                required: true,
                message: message2,
              },
              {
                validator: isNumber,
              },
            ]}
            style={{ width: "100%" }}
            hasFeedback={true}
            validateStatus={validateStatus2}
          >
            <InputNumber
              style={{ width: "100%" }}
              size={"large"}
              allowClear={true}
              placeholder={placeholder2}
              onChange={handleValueChange2}
              onKeyPress={onClick}
            />
          </Form.Item>
        </StyleLng>
      </StyleLocationDiv>
    </StyleTextDiv>
  );
}

export default TextField;
