import styled from "styled-components";
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
`;
export const StyledLogo = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
`;
export const StyledTitle = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #292d32;
`;
export const SubTitle = styled(StyledTitle)`
  margin-top: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7fbe42;
  margin-bottom: 20px;
`;
export const NextButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 90px;
  gap: 20px;
  width: 170px;
  height: 55px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 122.18%);
  border-radius: 5px;
  transition-duration: 0.2s;
  cursor: pointer;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: none;
  margin-top: 40px;
`;

export const StyledSelectInput = styled.select`
  padding-left: 16px;
  height: 52px;
  width: 40%;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(164, 164, 164, 0.4);
  border-radius: 5px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;

export const StyleRadioDiv = styled.div`
  width: auto;
  margin: 1%;
  min-height: 60px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  overflow: hidden; /* Prevents content overflow */
  white-space: nowrap; /* Prevents text from wrapping */
  text-overflow: ellipsis; /* Shows ellipsis (...) for truncated text */
`;
