import React, { useEffect, useState } from "react";
import "../../css/Login.css";
import { useNavigate } from "react-router-dom";
import logo from "../../Components/Assets/Logo.svg";
import sms from "../../image/sms.svg";
import lock from "../../image/lock.svg";
import eye from "../../image/eye.svg";
import eyeSplash from "../../image/eye-slash.svg";
import axios, { baseUrl, header } from "../../utils/axios";
import { Modal, Space, Spin } from "antd";

function Login() {
  const [model, showModel] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Ischecked, setIsChecked] = useState(false);
  const [show, setShow] = useState("password");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [img, setImg] = useState(eyeSplash);
  const getShow = (e) => {
    if (e === "password") {
      setImg(eye);
      return "text";
    } else {
      setImg(eyeSplash);
      return "password";
    }
  };
  let navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    await event.target;
    // navigate("/Dashboard", { replace: true });
    setEmail("");
    setPassword("");
    setIsChecked(true);
  }

  async function postData() {
    showModel(true);
    let data = { email: email, password: password };
    const result = await axios
      .post(baseUrl + "/verify_user", data, header)
      .then((res) => {
        showModel(false);
        if (res.data.exist === true) {
          if (res.data.type === "admin") {
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            navigate("/Dashboard");
          } else if (res.data.type === "SuperAdmin") {
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            navigate("/superadmindashboard");
          } else {
            localStorage.setItem("A55_Portal", res.data.user_id);
            localStorage.setItem("A55_Portal_type", res.data.type);
            localStorage.setItem("A55_name", res.data.name);
            localStorage.setItem("net_x_map_token", res.data.token);
            navigate("/SelectForm");
          }
        }
      })
      .catch((err) => {
        showModel(false);
        if (err.response.status === 500) {
          setError(err.response.data);
          setShowError(true);
        } else {
          setError("Server under maintenance");
          setShowError(true);
        }
      });
  }

  useEffect(() => {
    setTimeout(function () {
      setShowError(false);
    }, 3000);
  }, [error]);

  return (
    <div className="app1">
      <div className="login-form1">
        <div className="logo">
          <img src={logo} height="120px" width="200px" />
        </div>
        <div className="form1">
          <form onSubmit={handleSubmit}>
            <div className="input-container1">
              <div className="icon-left">
                <img src={sms} height="20px" width="20px" />
              </div>
              <input
                id="in1"
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-container1">
              <div className="icon-left">
                <img src={lock} height="20px" width="20px" />
              </div>
              <input
                id="in1"
                type={show}
                name="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="icon-right">
                <img
                  src={img}
                  height="20px"
                  width="20px"
                  onClick={(e) => {
                    setShow(getShow(show));
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: !showError ? "0" : "1",
                  transition: "all .2s",
                  visibility: !showError ? "hidden" : "visible",
                }}
              >
                <label
                  style={{
                    color: "red",
                    fontFamily: "Satoshi",
                    fontStyle: "normal",
                  }}
                >
                  {error}
                </label>
              </div>
            </div>
            <div className="button-container1">
              <input id="b1" type="submit" value="Sign In" onClick={postData} />
            </div>
          </form>
        </div>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default Login;
