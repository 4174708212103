import { useEffect, useState } from "react";
import tick from "../../../image/routing_1.svg";
import { Col, Modal, Row, Space, Spin, Button, Select } from "antd";
import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../../utils/axios";
import "./grantaccess.css";
import { BackwardOutlined } from "@ant-design/icons";
import { Checkbox, Divider } from "antd";

import {
  StyledAccBody,
  StyledAccessContainer,
  StyledAccHeading,
  StyledBox,
  StyledBoxText,
  StyledInputBox,
  StyledLabel,
  StyledSelectInput,
  StyledSubmitBtn,
} from "./styles/main.styles";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

// const defaultCheckedList = ["Apple", "Orange"];
function GrantAccess() {
  const [userList, setUserList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [userAreaList, setUserAreaList] = useState([]);
  const [formSelected, setFormSelected] = useState("");
  const [userSelected, setUserSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState([]);
  const [area, setArea] = useState([]);
  const [loading, setLoading] = useState(false);
  const locationDataString = localStorage.getItem("locationData");
  const locationData = JSON.parse(locationDataString); // Parsing the retrieved string back to an object
  const [checkedList, setCheckedList] = useState([]);

  const plainOptions = ["Apple", "Pear", "Orange"];
  const [areaList, setAreaList] = useState([]);
  console.log(siteSelected, "siteSelected");
  const checkAll = areaList.length === checkedList.length;
  const CheckboxGroup = Checkbox.Group;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < areaList.length;
  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? areaList : []);
  };

  const removeArea = (val) => {
    setArea([...area.filter((_, index) => area[index] !== val)]);
  };

  useEffect(() => {
    // console.log(area);
  }, [area]);

  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("A55_Portal");
    const getUsers = async () => {
      // setLoading(true);
      const result = await axios
        .get(baseUrl + "/get_user_id?user_id=" + user)
        .then((res) => {
          setLoading(false);
          setUserList(res.data["user_ids"]);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    };
    getUsers();
  }, []);

  const HandleFormSelected = (e) => {
    setLoading(true);
    const form = e.target.value;
    setFormSelected(form);
    const siteForm = async () => {
      const user = localStorage.getItem("A55_Portal");
      const result = await axios
        .get(
          baseUrl + "/get_sites_by_form?user_id=" + user + "&form=" + form,
          header
        )
        .then((res) => {
          setLoading(false);
          setSiteList(res.data["site"]);
          setAreaList([]);
          // setSiteSelected("");
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    };

    siteForm();
  };
  const HandleSiteSelected = (e) => {
    if (e[0] == "selectAll") {
      setSiteSelected(siteList.map((val, key) => val));
    } else {
      setSiteSelected(e);
    }
    // const val = e.target.value;
  };
  const SubmitData = (e) => {
    e.preventDefault();
    if (userSelected == "") {
      openSweetAlert("Select user", "error");
    } else if (formSelected == "") {
      openSweetAlert("Select a form", "error");
    } else if (siteSelected == "") {
      openSweetAlert("Select at least 1 site", "error");
    } else if (area.length < 1) {
      openSweetAlert("Select atleast 1 area", "error");
    } else {
      const postAccess = async () => {
        setLoading(true);
        let data = {
          form: formSelected,
          sites: siteSelected,
          area: area,
          user_ids: userSelected,
          local_auth: locationData?.local_auth,
          exchange: locationData?.exchange,
          percentage_bore_full: locationData?.percentage_bore_full,
          pia_noi: locationData?.pia_noi,
          survey_required: locationData?.survey_required,
          tel_no: locationData?.tel_no,
          duct_type: locationData?.duct_type,
          onsite_engineer: "",
        };

        const result = await axios
          .post(baseUrl + "/post_access-test-userids-sites", data, header)
          .then((res) => {
            setLoading(false);
            openSweetAlert(res.data.message, "success");
            // setUserList([]);
            setSiteList([]);
            setAreaList([]);
            setUserSelected([]);
            setSiteSelected([]);
            setFormSelected("");
            setArea([]);
          })
          .catch((err) => {
            console.log(err, "error in catch");
            setLoading(false);

            if (err.response?.status === 401) {
              removeStorage();
              navigate("/login");
            } else {
              openSweetAlert("Something Went Wrong!", "error");
            }
          });
      };
      postAccess();
    }
  };

  const optionsSites = [
    { value: "selectAll", label: "Select All" }, // "Select All" option
    ...siteList.map((val) => ({ value: val, label: `${val}` })),
  ];

  const options = [
    { value: "selectAll", label: "Select All" },
    ...userList.map((val, key) => ({
      value: val.id,
      label: `(${val.id}) ${val.name}`,
    })),
  ];

  const handleChange = (value) => {
    if (value == "selectAll") {
      const allUserIds = userList.map((val) => val.id);
      setUserSelected(allUserIds);
    } else {
      setUserSelected(value);
    }
  };

  const sendSites = async () => {
    const user = localStorage.getItem("A55_Portal");

    if (siteSelected == "") {
      openSweetAlert("Please! select a site", "error");
    } else {
      const areaForm = async () => {
        setLoading(true);
        const user = localStorage.getItem("A55_Portal");
        const sites = JSON.stringify(siteSelected);
        // await axios
        //   .get(
        //     baseUrl +
        //       "/get_user_response_area?user_id=" +
        //       userSelected +
        //       "&form=" +
        //       formSelected +
        //       "&site=" +
        //       val,
        //     header
        //   )
        //   .then((res) => {
        //     setUserAreaList(res.data["area"]);
        //     setArea(res.data["area"]);
        //   })
        //   .catch((err) => {
        //     if (err.response?.status === 401) {
        //       removeStorage();
        //       navigate("/login");
        //     }
        //   });

        if (formSelected != 0 || formSelected != "") {
          await axios
            .get(
              baseUrl +
                "/get_area_by_site_multiple?user_id=" +
                user +
                "&form=" +
                formSelected +
                "&site=" +
                siteSelected,
              header
            )
            .then((res) => {
              setAreaList(res.data["area"]);
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                removeStorage();
                navigate("/login");
              }
            });
        } else {
        }
      };
      areaForm().then(() => {
        setLoading(false);
      });
    }
  };
  return (
    <>
      <StyledAccessContainer>
        <Row>
          <Col>
            <img src={tick} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <StyledAccHeading>Grant Access</StyledAccHeading>
          </Col>
        </Row>
        <StyledAccBody>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <StyledInputBox>
                <StyledLabel>Assign To</StyledLabel>
                <Select
                  size="large"
                  name="userid"
                  required={true}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  value={userSelected}
                  placeholder="Please select"
                  defaultValue={[]}
                  onChange={handleChange}
                  options={options}
                  className="custom-select"
                />

                {/* <StyledSelectInput
                  name="userid"
                  required={true}
                  value={userSelected}
                  onChange={(e) => {
                    setUserSelected(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Select User Id
                  </option>
                  {userList.map((val, key) => {
                    return (
                      <option key={key} value={val.id}>
                        ({val.id}) {val.name}
                      </option>
                    );
                  })}
                </StyledSelectInput> */}
              </StyledInputBox>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <StyledInputBox>
                <StyledLabel>Select Form</StyledLabel>
                <StyledSelectInput
                  name="forms"
                  required={true}
                  value={formSelected}
                  onChange={HandleFormSelected}
                >
                  <option value="" disabled selected hidden>
                    Choose Form
                  </option>
                  <option value="A55">A55 Blockage</option>
                  <option value="net">Network Adjustment</option>
                  <option value="d pole">D - Pole</option>
                  <option value="r case">Raise a Case</option>
                  <option value="tree-cutting">Tree Cutting</option>
                  <option value="hyper-optic-form">Hyper Optic Form</option>
                </StyledSelectInput>
              </StyledInputBox>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <StyledInputBox>
                <StyledLabel>Site Details</StyledLabel>
                <div style={{ display: "flex" }}>
                  <Select
                    size="large"
                    name="userid"
                    required={true}
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    value={siteSelected}
                    placeholder="Please select a site"
                    defaultValue={[]}
                    onChange={HandleSiteSelected}
                    options={optionsSites}
                    className="custom-select"
                  />
                  <Button
                    style={{
                      height: "50px",
                      width: "70px",
                      borderRadius: "6px",
                    }}
                    onClick={sendSites}
                  >
                    Get Area
                  </Button>
                </div>
                {/* <StyledSelectInput
                  name="sites"
                  required={true}
                  value={siteSelected}
                  onChange={HandleSiteSelected}
                >
                  <option value="" disabled selected hidden>
                    Choose a Site
                  </option>
                  {siteList.map((val, key) => {
                    return (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </StyledSelectInput>  */}
              </StyledInputBox>
            </Col>
          </Row>
          {areaList.length > 0 ? (
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              >
                <StyledLabel style={{}}>Enter Area</StyledLabel>
                {/* <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Check all
                </Checkbox> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="selectAllCheckbox"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setArea(isChecked ? areaList : []); // Update area based on "Select All" state
                      // Optionally, update individual checkboxes visually
                      document
                        .querySelectorAll('input[type="checkbox"]')
                        .forEach((checkbox) => {
                          if (checkbox.id !== "selectAllCheckbox") {
                            // Avoid infinite loop
                            checkbox.checked = isChecked;
                          }
                        });
                    }}
                  />
                  <label
                    style={{ width: "150px", marginLeft: "-20px" }}
                    htmlFor="selectAllCheckbox"
                  >
                    Select All
                  </label>
                </div>
              </div>

              <Row>
                {areaList.map((val, key) => {
                  return (
                    <>
                      <Col xs={22} md={6} lg={4}>
                        <StyledBox>
                          <input
                            type="checkbox"
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                            value={val}
                            defaultChecked={userAreaList?.includes(val)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setArea([...area, val]);
                              } else {
                                setArea(area.filter((e) => e !== val));
                              }
                            }}
                          />
                          <StyledBoxText
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {val}
                          </StyledBoxText>
                        </StyledBox>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          ) : null}
          <Row style={{ marginTop: "10%" }}>
            <Col xs={1} md={1} lg={24}>
              <StyledSubmitBtn type="submit" onClick={SubmitData}>
                Submit
              </StyledSubmitBtn>
            </Col>
          </Row>
        </StyledAccBody>
      </StyledAccessContainer>
      <Modal visible={loading} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </>
  );
}

export default GrantAccess;
