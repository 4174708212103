import { useEffect, useRef, useState } from "react";
import "./formdata.css";
import {
  Button,
  Col,
  Input,
  message,
  Row,
  Form,
  Modal,
  Spin,
  Switch,
  Table,
} from "antd";
import clipboard from "../../../image/clipboard-text.svg";
import axios, { baseUrl, header, removeStorage } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import {
  StyledActions,
  StyledButtons,
  StyledFormData,
  StyledHeading,
} from "./styles/main.styles.js";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CommentOutlined,
  ShareAltOutlined,
  BackwardOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import Highlighter from "react-highlight-words";
import React from "react";

function FormFile() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [commentData, setCommentData] = useState({});
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [formId, setFormId] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    // console.log(commentData);
  }, [commentData]);

  const onOk = async (values) => {
    // console.log(values);
    await axios
      .post(baseUrl + "/comments", values)
      .then(() => {
        messageApi.open({
          type: "success",
          content: "Comment added",
        });
        setModalLoading(false);
        setIsCommentModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        messageApi.open({
          type: "err",
          content: err,
        });
        setModalLoading(false);
      });
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      setModalLoading(true);
      setTimeout(() => {
        form.resetFields();
        onOk(Object.assign({}, commentData, values));
      }, 2000);
    });
  };

  const error = (errorMsg) => {
    messageApi.open({
      type: "error",
      content: errorMsg,
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    confirm();
    setSearchedColumn(dataIndex);
  };
  const type = localStorage.getItem("A55_Portal_type");

  const formData = async () => {
    const user = localStorage.getItem("A55_Portal");
    const type = localStorage.getItem("A55_Portal_type");
    console.log(user, "user id");
    setLoading(true);
    const result = await axios
      .get(baseUrl + "/get_forms?user_id=" + user, header)
      .then((res) => {
        console.log(res.data, "datatatata");

        const out = [];
        for (let i = 0; i < res.data.length; i++) {
          let obj = res.data[i];
          out.push({
            key: i,
            userid: obj["user_id"],
            form: obj["form"],
            site: obj["site"],
            area: obj["area"],
            duct_section_no: obj["duct_section_number"],
            date: obj["date"],
            resp_id: obj["resp_id"],
            action: (
              <StyledActions>
                {type !== "User" ? (
                  <StyledButtons
                    // href={
                    //   obj["form"] === "D Pole"
                    //     ? baseUrl + "/downloadExcel?resp_id=" + obj["resp_id"]
                    //     : baseUrl + "/viewPDF?resp_id=" + obj["resp_id"]
                    // }
                    href={
                      obj["form"] === "D Pole"
                        ? baseUrl + "/downloadExcel?resp_id=" + obj["resp_id"]
                        : obj["form"] === "hyperopticform"
                        ? baseUrl + "/generate_doc?resp_id=" + obj["resp_id"]
                        : baseUrl + "/viewPDF?resp_id=" + obj["resp_id"]
                    }
                    target="_blank"
                    style={{ marginRight: "5px" }}
                  >
                    <EyeOutlined style={{ color: "#3D9F46" }} />
                  </StyledButtons>
                ) : null}
                <StyledButtons
                  // href={
                  //   obj["form"] === "Tree Cutting Form"
                  //     ? baseUrl + "/viewPDF?resp_id=" + obj["resp_id"]
                  //     : baseUrl + "/downloadExcel?resp_id=" + obj["resp_id"]
                  // }
                  href={
                    obj["form"] === "Tree Cutting Form"
                      ? baseUrl + "/viewPDF?resp_id=" + obj["resp_id"]
                      : obj["form"] === "hyperopticform"
                      ? baseUrl + "/generate_doc?resp_id=" + obj["resp_id"]
                      : baseUrl + "/downloadExcel?resp_id=" + obj["resp_id"]
                  }
                  target="_blank"
                  style={{ textDecoration: "none", marginRight: "5px" }}
                >
                  <DownloadOutlined style={{ color: "#3D9F46" }} />
                </StyledButtons>
                <StyledButtons
                  onClick={(event) => {
                    console.log(obj["form"], "form user");

                    if (type === "User" && obj["approved"] === "Yes") {
                      error("Contact your admin to edit form");
                    } else {
                      setLoading(true);
                      if (obj["form"] === "Networking") {
                        axios
                          .get(
                            baseUrl +
                              "/get_network_edit?resp_id=" +
                              obj["resp_id"]
                          )
                          .then((res) => {
                            console.log(res.data, "network edit form data");

                            localStorage.setItem("respId", obj["resp_id"]);

                            const data = res.data;
                            localStorage.setItem("edit", "true");

                            navigate("/Network", {
                              state: data,
                              res_id: event.resp_id,
                              idres: obj["resp_id"],
                            });
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              removeStorage();
                              navigate("/login");
                            }
                            console.log(err);
                          });
                      } else if (obj["form"] === "A55 Form") {
                        axios
                          .get(
                            baseUrl +
                              "/get_a55_record_edit?resp_id=" +
                              obj["resp_id"]
                          )
                          .then((res) => {
                            res.data["link"] = "/FormFile";
                            res.data["l1_soft"] = Number(res.data["l1_soft"]);
                            res.data["l1_footway"] = Number(
                              res.data["l1_footway"]
                            );
                            res.data["l1_carriageway"] = Number(
                              res.data["l1_carriageway"]
                            );
                            res.data["l2_soft"] = Number(res.data["l2_soft"]);
                            res.data["l2_footway"] = Number(
                              res.data["l2_footway"]
                            );
                            res.data["l2_carriageway"] = Number(
                              res.data["l2_carriageway"]
                            );
                            res.data["l3_soft"] = Number(res.data["l3_soft"]);
                            res.data["l3_footway"] = Number(
                              res.data["l3_footway"]
                            );
                            res.data["l3_carriageway"] = Number(
                              res.data["l3_carriageway"]
                            );

                            res.data["l1_soft_type"] = "Meter";
                            res.data["l1_footway_type"] = "Meter";
                            res.data["l1_carriageway_type"] = "Meter";
                            res.data["l2_soft_type"] = "Meter";
                            res.data["l2_footway_type"] = "Meter";
                            res.data["l2_carriageway_type"] = "Meter";
                            res.data["l3_soft_type"] = "Meter";
                            res.data["l3_soft_type"] = "Meter";
                            res.data["l3_footway_type"] = "Meter";
                            res.data["l3_carriageway_type"] = "Meter";
                            res.data["area"] = res.data["cluster"];
                            res.data["looks"] =
                              res.data["surface"].length === 2;
                            res.data["surface"] = res.data["surface"][0];

                            res.data["post_code_lat"] = res.data["lat"];
                            res.data["post_code_long"] = res.data["lng"];
                            res.data["resp_id"] = obj["resp_id"];

                            localStorage.setItem("respId", obj["resp_id"]);
                            localStorage.setItem("edit", "true");
                            const data = res.data;

                            navigate("/SiteForm", {
                              state: data,
                              res_id: event.resp_id,
                              idres: obj["resp_id"],

                              edit: "true",
                            });
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              removeStorage();
                              navigate("/login");
                            }
                            console.log(err);
                          });
                      } else if (obj["form"] === "D Pole") {
                        axios
                          .get(
                            baseUrl +
                              "/get_dpole_edit?resp_id=" +
                              obj["resp_id"]
                          )
                          .then((res) => {
                            // console.log(res.data, "d pole edit form data");

                            localStorage.setItem("respId", obj["resp_id"]);

                            const data = res.data;
                            localStorage.setItem("edit", "true");

                            navigate("/d-pole", {
                              state: data,
                              res_id: event.resp_id,
                              idres: obj["resp_id"],
                            });
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              removeStorage();
                              navigate("/login");
                            }
                            console.log(err);
                          });
                      } else if (obj["form"] === "Tree Cutting Form") {
                        axios
                          .get(
                            baseUrl +
                              "/get_treecutting_edit?resp_id=" +
                              obj["resp_id"]
                          )
                          .then((res) => {
                            console.log(
                              res.data,
                              "tree cutting edit form data"
                            );

                            localStorage.setItem("respId", obj["resp_id"]);

                            const data = res.data;
                            localStorage.setItem("edit", "true");

                            navigate("/tree-cutting", {
                              state: data,
                              res_id: event.resp_id,
                              idres: obj["resp_id"],
                            });
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              removeStorage();
                              navigate("/login");
                            }
                            console.log(err);
                          });
                      } else if (obj["form"] === "hyperopticform") {
                        axios
                          .get(
                            baseUrl +
                              "/getA55hyperopticForms?resp_id=" +
                              obj["resp_id"]
                          )
                          .then((res) => {
                            console.log(res.data, "hyperoptic edit form data");

                            localStorage.setItem("respId", obj["resp_id"]);

                            localStorage.setItem("edit", "true");

                            if (res?.status === 200) {
                              navigate("/hyper-optic", {
                                state: res?.data,
                                res_id: event.resp_id,
                                idres: obj["resp_id"],
                              });
                            }
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              removeStorage();
                              navigate("/login");
                            }
                            console.log(err);
                          });
                      } else {
                        console.log(obj["form"], "form");
                      }
                    }
                  }}
                >
                  <EditOutlined style={{ color: "#3D9F46" }} />
                </StyledButtons>
                {type !== "User" ? (
                  <>
                    <StyledButtons style={{ marginLeft: "5px" }}>
                      <ShareAltOutlined style={{ color: "#3D9F46" }} />
                    </StyledButtons>
                    <StyledButtons
                      onClick={() => {
                        setCommentData({
                          user_id: obj["user_id"],
                          admin_id: user,
                          resp_id: obj["resp_id"],
                        });
                        setIsCommentModalVisible(true);
                      }}
                      style={{ marginLeft: "5px" }}
                    >
                      <CommentOutlined style={{ color: "#3D9F46" }} />
                    </StyledButtons>
                    <StyledButtons
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                        border: "1px solid red",
                        backgroundColor: "#ffcccb",
                      }}
                      onClick={() => handleDeleteClick(obj["resp_id"])}
                    >
                      <DeleteOutlined style={{ color: "red" }} />
                    </StyledButtons>
                    <Switch
                      defaultChecked={obj["approved"] === "Yes"}
                      onChange={(e) => handleApproval(e, obj["resp_id"])}
                    />
                  </>
                ) : null}
              </StyledActions>
            ),
          });
        }
        setData(out);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    formData();
  }, []);
  const handleApproval = (e, id) => {
    // console.log(e, id);
    if (e) {
      axios
        .get(baseUrl + "/a55_record_approve?form_id=" + id)
        .then((res) => {
          Swal.fire({
            title: "Approved!",
            text: "Form is successfully approved!",
            icon: "success",
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          Swal.fire({
            title: "Something went wrong!",
            type: "warning",
            // icon: "success",
          });
          console.log(err);
        });
    } else {
      axios
        .get(baseUrl + "/a55_record_disapprove?form_id=" + id)
        .then((res) => {
          Swal.fire({
            title: "Disapproved!",
            text: "Form is successfully disapproved!",
            icon: "success",
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          Swal.fire({
            title: "Something went wrong!",
            type: "warning",
            // icon: "success",
          });
          console.log(err);
        });
    }
  };
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#ba321a",
    });
  };
  console.log(formId, "form ids");

  const handleDelete = (formId) => {
    // openSweetAlert("Are you sure, you want to delete this form?", "Warning");
    if (formId.length <= 0) {
      Swal.fire({
        title: "Please select a form",
        type: "warning",
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        title: "Are you sure, you want to delete this form?",
        type: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, I am sure!",
        cancelButtonText: "No, cancel it!",
      }).then(function (isConfirm) {
        if (isConfirm["value"]) {
          console.log(formId, "form id before");

          const formIdsArray = formId.map((form) => ({
            form_id: form.form_id,
          }));
          axios
            .delete(baseUrl + "/a55_record_delete", {
              data: formIdsArray,
              headers: {
                "Content-Type": "application/json", // Make sure to set the Content-Type header
              },
            })
            .then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Form is successfully deleted!",
                icon: "success",
              });
              formData();
              setFormId([]);
              setSelectedRowKeys([]);
            })
            .catch((err) => {
              // console.clear();
              console.log(err);
              if (err.response.status === 401) {
                removeStorage();
                navigate("/login");
              }
              Swal.fire({
                title: `${err.response.data}`,
                type: "warning",
                // icon: "success",
              });
            });
        }
      });
    }
  };
  const handleDeleteClick = (respId) => {
    console.log(respId, "iddd");
    setFormId((prevFormId) => {
      const newFormId = [...prevFormId, { form_id: respId }];
      console.log(newFormId, "form ids in setFormId callback");
      // return newFormId;
      handleDelete(newFormId);
    });
  };

  let uniqueUserIds = [];
  let uniqueForm = [];
  let uniqueSite = [];
  let uniqueArea = [];
  let uniqueDuctSection = [];
  uniqueUserIds = data.reduce((acc, curr) => {
    if (!acc.some((item) => item.value === curr.userid)) {
      acc.push({ text: curr.userid, value: curr.userid });
    }
    return acc;
  }, []);
  uniqueForm = data.reduce((acc, curr) => {
    if (!acc.some((item) => item.value === curr.form)) {
      acc.push({ text: curr.form, value: curr.form });
    }
    return acc;
  }, []);
  uniqueSite = data.reduce((acc, curr) => {
    if (!acc.some((item) => item.value === curr.site)) {
      acc.push({ text: curr.site, value: curr.site });
    }
    return acc;
  }, []);
  uniqueArea = data.reduce((acc, curr) => {
    if (!acc.some((item) => item.value === curr.area)) {
      acc.push({ text: curr.area, value: curr.area });
    }
    return acc;
  }, []);
  uniqueDuctSection = data.reduce((acc, curr) => {
    if (!acc.some((item) => item.value === curr.duct_section_no)) {
      acc.push({ text: curr.duct_section_no, value: curr.duct_section_no });
    }
    return acc;
  }, []);
  const columns = [
    {
      title: "User Id",
      dataIndex: "userid",
      align: "center",
      filters: uniqueUserIds,
      filterSearch: true,
      onFilter: (value, record) => record.userid.includes(value),
      render(text, record) {
        return {
          props: {
            style: {
              color: "#45A044",
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontSize: "14px",
              letterSpacing: "0.03em",
              fontWeight: "500",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("userid"),
    },
    {
      title: "Form",
      dataIndex: "form",
      align: "center",
      filters: uniqueForm,
      filterSearch: true,
      onFilter: (value, record) => record.form.includes(value),
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("form"),
    },
    {
      title: "Site",
      dataIndex: "site",
      align: "center",
      filters: uniqueSite,
      filterSearch: true,
      onFilter: (value, record) => record.site.includes(value),
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("site"),
    },
    {
      title: "Area",
      dataIndex: "area",
      align: "center",
      filters: uniqueArea,
      filterSearch: true,
      onFilter: (value, record) => record.area.includes(value),

      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("area"),
    },
    {
      title: "Duct Section No/Id Pole",
      dataIndex: "duct_section_no",
      align: "center",
      filters: uniqueDuctSection,
      filterSearch: true,
      onFilter: (value, record) => record.duct_section_no.includes(value),

      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("duct_section_no"),
    },
    {
      title: "Date/Time",
      dataIndex: "date",
      align: "center",
      filterSearch: true,
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
      // ...getColumnSearchProps("date"),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render(text, record) {
        // console.log(text, "record text");
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      console.log(record.userid, "user id from record");
      console.log(record, "record data");
      const newFormId = { form_id: record.resp_id };
      setFormId((prevFormId) => [...prevFormId, newFormId]);
    },
    onSelectAll: (record, selected, selectedRows) => {
      // console.log(record.userid, "user id from record");
      // console.log(selected, "selected data");
      // const newFormId = { form_id: record.resp_id };
      // setFormId((prevFormId) => [...prevFormId, newFormId]);
    },
  };
  return (
    <>
      {contextHolder}
      {type == "User" ? (
        <Button className="back_btn" onClick={() => navigate("/selectForm")}>
          Go Back
        </Button>
      ) : null}
      <StyledFormData>
        <Row>
          <Col>
            <img src={clipboard} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <StyledHeading>Form Data</StyledHeading>
          </Col>
        </Row>

        <button
          style={{
            color: "white",
            background: "#d2042d",
            fontWeight: "bolder",
            padding: "5px 15px",
            border: "none",
            borderRadius: "5px",
            marginTop: "10px",
            fontSize: "12px",
          }}
          onClick={() => handleDelete(formId)}
        >
          Delete
        </button>
        <Table
          style={{
            borderTop: "0.8px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "10px",
            marginTop: "15px",
          }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          size="medium"
          columns={columns}
          dataSource={data}
          rowSelection={{
            ...rowSelection,
          }}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: loading,
          }}
          scroll={{
            x: 1100,
          }}
        />
      </StyledFormData>
      <Modal
        title="Add Comment"
        visible={isCommentModalVisible}
        bodyStyle={{ backgroundColor: "#fff" }}
        onCancel={() => {
          setIsCommentModalVisible(false);
        }}
        footer={false}
      >
        <Form form={form} layout="vertical" name="addCommentForm">
          <Form.Item
            name="comment"
            label="Comment"
            rules={[{ required: true, message: "Please enter your comment" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter your comment" />
          </Form.Item>
        </Form>
        <Button
          key="cancel"
          onClick={() => {
            setIsCommentModalVisible(false);
          }}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          key="submit"
          type="primary"
          loading={modalLoading}
          onClick={handleOk}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
}

export default FormFile;
