import React, { useEffect, useState } from "react";

import "../../css/FormData.css";
import { Col, Row, Space, Spin, Table } from "antd";
import clipboard from "../../image/clipboard-text.svg";
import share from "../../image/share.svg";
import edit from "../../image/edit-2.svg";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import { useNavigate } from "react-router-dom";

function FormFile() {
  const [loading, setLoading] = useState(true);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    async function formData() {
      setLoading(true);
      const user = localStorage.getItem("A55_Portal");
      const result = await axios
        .get(baseUrl + "/get_forms?user_id=" + user, header)
        .then((res) => {
          const out = [];
          for (let i = 0; i < res.data.length; i++) {
            let obj = res.data[i];
            out.push({
              key: i,
              userid: obj["user_id"],
              form: obj["form"],
              site: obj["site"],
              area: obj["area"],
              date: obj["date"],
              action: (
                <div className="action-btns">
                  <a
                    style={{
                      width: "90px",
                      height: "35px",
                      background: "#3D9F46",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                      border: "none",
                      marginRight: "5px",
                      fontWeight: 500,
                      fontSize: "15px",
                      letterSpacing: "0.02em",
                      textDecoration: "none",
                      paddingTop: "5px",
                    }}
                    // href={baseUrl + "/viewExcel?resp_id=" + obj["resp_id"]}
                    target="_blank"
                  >
                    View
                  </a>
                  <a
                    style={{
                      width: "90px",
                      height: "35px",
                      background: "#3D9F4600",
                      borderRadius: "5px",
                      color: "#3D9F46",
                      border: "1px solid #3D9F46",
                      marginRight: "5px",
                      fontWeight: 500,
                      fontSize: "15px",
                      letterSpacing: "0.02em",
                      textDecoration: "none",
                      paddingTop: "5px",
                    }}
                    href={baseUrl + "/downloadExcel?resp_id=" + obj["resp_id"]}
                    target="_blank"
                  >
                    Download
                  </a>
                  <button
                    id="share-btn"
                    onClick={(event) => {
                      localStorage.setItem("resp_id", obj["resp_id"]);
                      if (obj["form"] === "A55 BLOCKAGE FORM") {
                        navigate("/SiteForm");
                      } else {
                        navigate("/Network");
                      }
                    }}
                  >
                    <img src={edit} />
                  </button>
                  <button style={{ marginLeft: "5px" }} id="share-btn">
                    <img src={share} />
                  </button>
                </div>
              ),
            });
          }
          setData(out);
        })
        .catch((err) => {
          setLoading(false);

          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
      setLoading(false);
    }

    formData();
  }, []);
  const columns = [
    {
      title: "User Id",
      dataIndex: "userid",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              color: "#45A044",
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontSize: "14px",
              letterSpacing: "0.03em",
              fontWeight: "500",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "form",
      dataIndex: "form",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "site",
      dataIndex: "site",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "area",
      dataIndex: "area",
      align: "center",

      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "date",
      dataIndex: "date",
      align: "center",

      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",

      render(text, record) {
        return {
          props: {
            style: {
              fontFamily: "Satoshi",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              letterSpacing: "0.045em",
              color: "#263238",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  return (
    <div className="user-main">
      <div className="ShowUser-div">
        <Row>
          <Col>
            <img src={clipboard} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <p className="heading-loc-div">Form Data</p>
          </Col>
        </Row>
        <div className="table-div">
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            size="medium"
            columns={columns}
            dataSource={data}
            loading={{
              indicator: (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ),
              spinning: loading,
            }}
            // rowSelection={{
            //     type: selectionType,
            // }}
          />
        </div>
      </div>
    </div>
  );
}

export default FormFile;
