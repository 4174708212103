import React, { useState } from "react";
import { Alert, Form, Input, Button, Spin } from "antd";
import {
  LeftOutlined,
  MailOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/axios";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
const ChangePassword = ({ state }) => {
  const location = useLocation();
  const email = localStorage.getItem("email");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const validatePassword = (rule, value) => {
    // Use a regular expression to enforce password requirements
    const regex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!regex.test(value)) {
      return Promise.reject(
        "Password must have at least 8 characters, one capital letter, one number, and one special character."
      );
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const res = await axios.post(baseUrl + "/set-new-password", {
      email: email,
      password: values.password,
      confirm_password: values.password2,
    });
    console.log(res.status, "res.data.status");
    if (res.status == "200" && res.data.message) {
      setLoading(false);
      navigate("/change-password");
      Swal.fire({
        title: res.data.message,
        type: "success",
        allowOutsideClick: false,
      }).then(() => {
        // Navigate to login page after Swal is closed
        setLoading(false);
        navigate("/login");
      });
    } else {
      Swal.fire({
        title: res.data.error,
        type: "error",
        allowOutsideClick: false,
      });
      setLoading(false);
    }
    console.log(res, "res");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const goBack = () => {
    navigate("/forgot-password");
  };
  return (
    <Spin size="large" spinning={loading}>
      <div>
        <Button className="back_btn_forgot_password" onClick={goBack}>
          <LeftOutlined style={{ fontSize: "14px" }} />
        </Button>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "100px", color: "#2F302F" }}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              Change password
            </p>
            <p
              style={{
                marginBottom: "60px",
              }}
            >
              Enter your new password
            </p>

            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="dependencies"
              autoComplete="off"
              style={{
                maxWidth: 600,
                margin: "0 auto",
              }}
              layout="vertical"
            >
              <Form.Item
                style={{
                  width: "400px",
                  margin: "0 auto",
                }}
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password
                  style={{
                    height: "40px",
                    borderRadius: "10px",
                    borderColor: "#6EB544",
                  }}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: "#6EB544" }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: "#6EB544" }} />
                    )
                  }
                />
              </Form.Item>

              {/* Field */}
              <Form.Item
                className="password_field_item"
                style={{
                  width: "400px",
                  margin: "0 auto",
                }}
                label="Confirm Password"
                name="password2"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{
                    height: "40px",
                    borderRadius: "10px",
                    borderColor: "#6EB544",
                  }}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: "#6EB544" }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: "#6EB544" }} />
                    )
                  }
                />
              </Form.Item>

              <Button
                style={{
                  width: "395px",
                  height: "40px",
                  borderRadius: "10px",
                  fontWeight: 700,
                }}
                type="primary"
                block
                htmlType="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default ChangePassword;
