import React, { useEffect, useState } from "react";
import "../../css/GrantAccess.css";
import tick from "../../image/routing_1.svg";
import { Col, Modal, Row, Space, Spin, Select } from "antd";
// import axios from "axios";
import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import { useNavigate } from "react-router-dom";

function GrantAccess() {
  const navigate = useNavigate();
  const [model, showModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [userAreaList, setUserAreaList] = useState([]);
  const [formSelected, setFormSelected] = useState("");
  const [userSelected, setUserSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState("");
  const [area, setArea] = useState([]);
  console.log(userSelected, "userSelecteduserSelected");
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  useEffect(() => {
    showModel(true);
    const user = localStorage.getItem("A55_Portal");

    async function getUsers() {
      const result = await axios
        .get(baseUrl + "/get_user_id?user_id=" + user)
        .then((res) => {
          showModel(false);
          setUserList(res.data["user_ids"]);
        })
        .catch((err) => {
          showModel(false);
          if (err.response?.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    }

    getUsers();
  }, []);

  const options = [
    { value: "selectAll", label: "Select All" },
    ...userList.map((val, key) => ({
      value: val.id,
      label: `(${val.id}) ${val.name}`,
    })),
  ];
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value == "selectAll") {
      const allUserIds = userList.map((val) => val.id);
      setUserSelected(allUserIds);
    } else {
      setUserSelected(value);
    }
  };

  return (
    <div className="user-main">
      <div className="main-div">
        <Row>
          <Col>
            <img src={tick} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <p className="heading-div">Grant Access</p>
          </Col>
        </Row>
        <div className="body-div">
          <Row>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1">
                <label id="label">Assign To</label>
                <Select
                  size="large"
                  name="userid"
                  required={true}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  defaultValue={[]}
                  onChange={handleChange}
                  options={options}
                />

                {/* <select
                  mode="multiple"
                  allowClear
                  id="select-input"
                  name="userid"
                  required={true}
                  value={userSelected}
                  onChange={(e) => {
                    setUserSelected(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Select User Id
                  </option>
                  {userList.map((val, key) => {
                    return (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select> */}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1">
                <label id="label">Select Form</label>
                <select
                  id="select-input"
                  name="forms"
                  required={true}
                  value={formSelected}
                  onChange={(e) => {
                    showModel(true);
                    const form = e.target.value;
                    setFormSelected(form);

                    async function siteForm() {
                      const user = localStorage.getItem("A55_Portal");
                      const result = await axios
                        .get(
                          baseUrl +
                            "/get_sites_by_form?user_id=" +
                            user +
                            "&form=" +
                            form,
                          header
                        )
                        .then((res) => {
                          showModel(false);
                          setSiteList(res.data["site"]);
                          setAreaList([]);
                          setSiteSelected("");
                        })
                        .catch((err) => {
                          showModel(false);
                          if (err.response?.status === 401) {
                            removeStorage();
                          }
                        });
                    }

                    siteForm();
                  }}
                >
                  <option value="" disabled selected hidden>
                    Choose Form
                  </option>
                  <option value="A55">A55 Blockage</option>
                  <option value="net">Network Adjustment</option>
                  <option value="d pole">D - Pole</option>
                  <option value="r case">Raise a Case</option>
                </select>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <div className="row-1">
                <label id="label">Site Details</label>
                <select
                  id="select-input"
                  name="sites"
                  required={true}
                  value={siteSelected}
                  disabled={formSelected === ""}
                  onChange={(e) => {
                    const val = e.target.value;

                    async function areaForm() {
                      setSiteSelected(val);
                      const user = localStorage.getItem("A55_Portal");

                      await axios
                        .get(
                          baseUrl +
                            "/get_user_response_area?user_id=" +
                            userSelected +
                            "&form=" +
                            formSelected +
                            "&site=" +
                            val,
                          header
                        )
                        .then((res) => {
                          setUserAreaList(res.data["area"]);
                          setArea(res.data["area"]);
                        })
                        .catch((err) => {
                          if (err.response?.status === 401) {
                            removeStorage();
                          }
                        });

                      await axios
                        .get(
                          baseUrl +
                            "/get_area_by_site_multiple?user_id=" +
                            user +
                            "&form=" +
                            formSelected +
                            "&site=" +
                            val,
                          header
                        )
                        .then((res) => {
                          setAreaList(res.data["area"]);
                        })
                        .catch((err) => {
                          if (err.response?.status === 401) {
                            removeStorage();
                          }
                        });
                    }

                    areaForm();
                  }}
                >
                  <option value="" disabled selected hidden>
                    Choose a Site
                  </option>
                  {siteList.map((val, key) => {
                    return (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
          {areaList.length > 0 ? (
            <div
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <label id="label" style={{ marginTop: "30px" }}>
                Enter Area
              </label>
              <Row>
                {areaList.map((val, key) => {
                  return (
                    <>
                      <Col xs={22} md={10} lg={6}>
                        <div className="b-2">
                          <input
                            type="checkbox"
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                            value={val}
                            defaultChecked={userAreaList?.includes(val)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                area.push(val);
                              } else {
                                setArea(area.filter((e) => e !== val));
                              }
                            }}
                          />
                          <div className="text">{val}</div>
                        </div>
                      </Col>
                      {/* <Col xs={2} md={2} lg={1}></Col> */}
                    </>
                  );
                })}
              </Row>
            </div>
          ) : null}
          <Row style={{ marginTop: "10%" }}>
            <Col xs={1} md={1} lg={24}>
              <button
                type="submit"
                id="sub-btn"
                onClick={(e) => {
                  e.preventDefault();

                  async function postAccess() {
                    let data = {
                      form: formSelected,
                      sites: siteSelected,
                      area: area,
                      user_ids: userSelected,
                    };
                    const result = await axios
                      .post(
                        baseUrl + "/post_access-test-userids-sites",
                        data,
                        header
                      )
                      .then((res) => {
                        openSweetAlert(res.data, "success");
                        setSiteList([]);
                        setAreaList([]);
                        setUserAreaList([]);
                        setFormSelected("");
                        setUserSelected("");
                        setSiteSelected("");
                        setArea([]);
                      })
                      .catch((err) => {
                        if (err.response?.status === 401) {
                          removeStorage();
                          navigate("/login");
                        } else {
                          openSweetAlert(err.response.data, "error");
                        }
                      });
                  }

                  if (formSelected === "") {
                    openSweetAlert("Select Form", "error");
                  } else if (siteSelected === "") {
                    openSweetAlert("Selected site", "error");
                  } else if (userSelected === "") {
                    openSweetAlert("Selected user", "error");
                  } else {
                    postAccess();
                  }
                }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default GrantAccess;
