import React from "react";
import { Form, Radio } from "antd";
import {
  Style4RadioDiv,
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRadioDiv,
  StyleRedStar,
  StyleTextDiv,
} from "../../styles/main.styles";

function RadioField({
  number,
  heading,
  description,
  name,
  message,
  star,
  radioData,
}) {
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <Form.Item
        label=""
        name={name}
        rules={[
          {
            // required: true,
            message: message,
          },
        ]}
      >
        <Radio.Group
          name="work"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {radioData.map((val, key) => {
            return radioData.length === 3 ? (
              <StyleRadioDiv key={key}>
                <Radio value={val}>{val}</Radio>
              </StyleRadioDiv>
            ) : (
              <Style4RadioDiv key={key}>
                <Radio value={val}>{val}</Radio>
              </Style4RadioDiv>
            );
          })}
        </Radio.Group>
      </Form.Item>
    </StyleTextDiv>
  );
}

export default RadioField;
