import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Items/Header";
import { StyledBody } from "./styles/main.styles";
import moment from "moment/moment";
import TextField from "./Items/TextField";
import DateField from "./Items/DateField";
import LocationField from "./Items/LocationField";
import RadioField from "./Items/RadioField";
import Picture2 from "../../../image/Pic1.png";
import ImageField from "./Items/ImageField";
import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import tick from "../../../image/animation.gif";
import { baseUrl } from "../../../utils/axios";
import MapLocation from "./Items/mapLocation";
import {
  BackButton,
  BackButtonText,
  NextButton,
} from "../siteForm/styles/main.styles";
import Swal from "sweetalert2";

import { BackwardOutlined } from "@ant-design/icons";
import axios from "axios";
import { Input } from "antd";
import { StyledNumber, StyleNText } from "./styles/main.styles";

const { TextArea } = Input;

const form = localStorage.getItem("form");
const site = localStorage.getItem("siteSelectedValue");
const area = localStorage.getItem("selectedValue");
// const form = localStorage.getItem("form");

// console.log(site, area, "site and area");
const TreeCuttingForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const location = useLocation();
  // const { site, area } = location.state || {};
  const d1 = new Date();
  const [startTime, setTime] = useState([0, 0, 0]);
  useEffect(() => {
    setTime([d1.getHours(), d1.getMinutes(), d1.getSeconds()]);
  }, []);

  if (state && Object.keys(state).includes("date_evidence_collected")) {
    state["date_evidence_collected"] =
      state["date_evidence_collected"] === ""
        ? ""
        : moment(state["date_evidence_collected"]);
  }

  const [data, setData] = useState(
    state ? state : { form_selected: localStorage.getItem("form_selected") }
  );

  console.log(state, "state");
  const [userID, setUserID] = useState(localStorage.getItem("A55_Portal"));
  const [pic1, setPic1] = useState(state ? [state["pic_one"]] : []);
  const [pic2, setPic2] = useState(state ? [state["picTwo"]] : []);
  const [pic3, setPic3] = useState(state ? [state["picThird"]] : []);
  const [pic4, setPic4] = useState(state ? [state["picFourth"]] : []);
  const [pic5, setPic5] = useState(state ? [state["picFifth"]] : []);
  const [pic6, setPic6] = useState(state ? [state["picSixth"]] : []);
  const [pic7, setPic7] = useState(state ? [state["picSeventh"]] : []);
  const [pic8, setPic8] = useState(state ? [state["picEight"]] : []);
  const [pic9, setPic9] = useState(state ? [state["pic_Ninth"]] : []);
  const [pic10, setPic10] = useState(state ? [state["picTenth"]] : []);
  const [pic11, setPic11] = useState(state ? [state["picEleven"]] : []);
  const [pic12, setPic12] = useState(state ? [state["picTwelve"]] : []);
  const [pic13, setPic13] = useState(state ? [state["picThirteen"]] : []);
  const [pic14, setPic14] = useState(state ? [state["picFourteen"]] : []);
  const [pic15, setPic15] = useState(state ? [state["picFifteen"]] : []);

  const [pic1Response, setPic1Response] = useState(["", ""]);
  const [pic2Response, setPic2Response] = useState(["", ""]);
  const [pic3Response, setPic3Response] = useState(["", ""]);
  const [pic4Response, setPic4Response] = useState(["", ""]);
  const [pic5Response, setPic5Response] = useState(["", ""]);
  const [pic6Response, setPic6Response] = useState(["", ""]);
  const [pic7Response, setPic7Response] = useState(["", ""]);
  const [pic8Response, setPic8Response] = useState(["", ""]);
  const [pic9Response, setPic9Response] = useState(["", ""]);
  const [pic10Response, setPic10Response] = useState(["", ""]);
  const [pic11Response, setPic11Response] = useState(["", ""]);
  const [pic12Response, setPic12Response] = useState(["", ""]);
  const [pic13Response, setPic13Response] = useState(["", ""]);
  const [pic14Response, setPic14Response] = useState(["", ""]);
  const [pic15Response, setPic15Response] = useState(["", ""]);

  const [mapLocation, setMapLocation] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(
    state === null || state["date"] === ""
      ? "Successfully submitted your response"
      : "Successfully edited your response"
  );

  function mercatorProjection(lat, lng) {
    const x = lng;
    const y =
      Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360)) / (Math.PI / 180);
    return { x, y };
  }
  const lat = mapLocation?.lat;
  const lng = mapLocation?.lng;

  const { x, y } = mercatorProjection(lat, lng);
  const formattedLocation = `${mapLocation?.lat}/${mapLocation?.lng}`;

  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };
  const formRef = useRef();

  const edit = localStorage.getItem("edit");

  const respId = localStorage.getItem("respId");
  console.log(pic1, "pic 1");
  const location = `${lat}/${lng} X:${x}  Y:${y}`;

  const nextStep = async (value) => {
    const site = localStorage.getItem("siteSelectedValue");
    const area = localStorage.getItem("selectedValue");

    try {
      const formValues = await formRef.current.validateFields();

      const formattedDate = moment(
        formValues.date_evidence_collected,
        "YYYY-M-D"
      )
        .utc()
        .format("YYYY-M-D");
      console.log(value, "valuesssss");

      if (state) {
        localStorage.removeItem("edit");
        console.log(edit, "edit");
        console.log(pic1[0], "ppppiccc 1");
        const pictures = [
          pic1,
          pic2,
          pic3,
          pic4,
          pic5,
          pic6,
          pic7,
          pic8,
          pic9,
          pic10,
          pic11,
          pic12,
          pic13,
          pic14,
          pic15,
        ];
        let uploadedCount = 0;

        for (let i = 0; i < pictures.length; i++) {
          if (pictures[i][0] !== undefined) {
            uploadedCount++;
          }
        }

        if (uploadedCount < 3) {
          openSweetAlert("Please upload at least three pictures", "error");
        } else {
          const formData = {
            ...formValues,
            pic_one: pic1[0] ? pic1[0].response : null,
            picTwo: pic2[0] ? pic2[0].response : null,
            picThird: pic3[0] ? pic3[0].response : null,
            picFourth: pic4[0] ? pic4[0].response : null,
            picFifth: pic5[0] ? pic5[0].response : null,
            picSixth: pic6[0] ? pic6[0].response : null,
            picSeventh: pic7[0] ? pic7[0].response : null,
            picEight: pic8[0] ? pic8[0].response : null,
            pic_Ninth: pic9[0] ? pic9[0].response : null,
            picTenth: pic10[0] ? pic10[0].response : null,
            picEleven: pic11[0] ? pic11[0].response : null,
            picTwelve: pic12[0] ? pic12[0].response : null,
            picThirteen: pic13[0] ? pic13[0].response : null,
            picFourteen: pic14[0] ? pic14[0].response : null,
            picFifteen: pic15[0] ? pic15[0].response : null,
            site: site,
            area: area,
            // date: formattedDate,
            user_id: userID,
            resp_id: respId,
            post_code_lat: lat,
            post_code_long: lng,
            location: location,
          };
          const d1 = new Date(); // End time
          formData["end_time"] = [
            d1.getHours(),
            d1.getMinutes(),
            d1.getSeconds(),
          ];

          const d2 = new Date(); // Start time
          formData["start_time"] = [
            d2.getHours(),
            d2.getMinutes(),
            d2.getSeconds(),
          ];

          await axios
            .post(baseUrl + "/post_tree_cutting", formData)
            .then((res) => {
              localStorage.removeItem("edit");

              console.log(res, "response");
              openSweetAlert(res.data.out, "success");

              navigate("/SelectForm");
            })
            .catch((err) => {
              if (err.response.status === 401) {
                navigate("/SelectForm");
                console.log(err);
              }
            });
        }
        // }
      } else {
        var dt = new Date();
        console.log(pic1[0], "ppppiccc 1");
        const pictures = [
          pic1,
          pic2,
          pic3,
          pic4,
          pic5,
          pic6,
          pic7,
          pic8,
          pic9,
          pic10,
          pic11,
          pic12,
          pic13,
          pic14,
          pic15,
        ];
        let uploadedCount = 0;

        for (let i = 0; i < pictures.length; i++) {
          if (pictures[i][0] !== undefined) {
            uploadedCount++;
          }
        }

        if (uploadedCount < 3) {
          openSweetAlert("Please upload at least three pictures", "error");
        } else {
          const formData = {
            // ...value,
            ...formValues,
            pic_one: pic1[0] ? pic1[0].response : null,
            picTwo: pic2[0] ? pic2[0].response : null,
            picThird: pic3[0] ? pic3[0].response : null,
            picFourth: pic4[0] ? pic4[0].response : null,
            picFifth: pic5[0] ? pic5[0].response : null,
            picSixth: pic6[0] ? pic6[0].response : null,
            picSeventh: pic7[0] ? pic7[0].response : null,
            picEight: pic8[0] ? pic8[0].response : null,
            pic_Ninth: pic9[0] ? pic9[0].response : null,
            picTenth: pic10[0] ? pic10[0].response : null,
            picEleven: pic11[0] ? pic11[0].response : null,
            picTwelve: pic12[0] ? pic12[0].response : null,
            picThirteen: pic13[0] ? pic13[0].response : null,
            picFourteen: pic14[0] ? pic14[0].response : null,
            picFifteen: pic15[0] ? pic15[0].response : null,
            start_time: startTime,
            end_time: [dt.getHours(), dt.getMinutes(), dt.getSeconds()],
            site: site,
            area: area,
            user_id: userID,
            post_code_lat: lat,
            post_code_long: lng,
            location: location,
          };
          console.log(formData, "form data");

          await axios
            .post(baseUrl + "/post_tree_cutting", formData)
            .then((res) => {
              console.log(res, "response");
              openSweetAlert("Form Submitted Successfully", "success");
              navigate("/SelectForm");
            })
            .catch((err) => {
              // if (err.response.status === 401) {
              //   navigate("/SelectForm");
              //   console.log(err);
              // }
              console.log(err);
            });
        }
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const error = (errorMsg) => {
    messageApi.open({
      type: "error",
      content: errorMsg,
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleKeyPress = (event) => {
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };
  console.log(pic1, "picture 1 ");
  return (
    <>
      <Button className="back_btn" key="cancel" onClick={handleGoBack}>
        <span>Go Back</span>
      </Button>
      {contextHolder}
      <Header form_name={form} />
      <StyledBody>
        <Form
          ref={formRef}
          onFinish={nextStep}
          onFinishFailed={() => {}}
          labelCol={{ span: 0 }}
          style={{ width: "100%" }}
          wrapperCol={{ span: 24 }}
          initialValues={data}
          autoComplete="off"
        >
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={1}
                heading={"PIANOI No"}
                name="pia_noi"
                placeholder={"Enter pianoi no of your Project here"}
                message={"Please enter pianoi no"}
                star={true}
                // description={"Enter Exchange of your Project"}
                // onChange={(value) => handleFieldChange("exchange", value)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={2}
                heading={"AG"}
                name="ag"
                placeholder={"Enter pole number here"}
                message={"Please enter pole number"}
                star={true}
                // description={"Enter OR Reference of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={3}
                heading={"Pole ID"}
                name="pole_id"
                placeholder={"Enter pole number here"}
                message={"Please enter pole number"}
                star={true}
                // description={"Enter OR Reference of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={4}
                heading={"Exchange"}
                name="exchange"
                placeholder={"Enter exchange here"}
                message={"Please enter exchange"}
                star={true}
                // description={"Enter NOI of your Project"}
              />
            </Col>
          </Row>

          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label="">
                <TextField
                  number={5}
                  heading={"Adress"}
                  name="adress"
                  placeholder={"Enter your Project adress here"}
                  message={"Please enter adress"}
                  star={true}
                  // description={"Enter Address of your Project"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* <LocationField
                number={6}
                heading={"Location"}
                name1="post_code_lat"
                name2="post_code_long"
                placeholder1={"Enter latitude of blockage"}
                placeholder2={"Enter Longitude of blockage"}
                message1={"Please enter latitude"}
                message2={"Please enter Longitude"}
                // star={true}
                onClick={handleKeyPress}
                // description={"Enter details of blockage location"}
                // onChange1={(value) => handleFieldChange("post_code_lat", value)}
                // onChange2={(value) =>
                //   handleFieldChange("post_code_long", value)
                // }
              /> */}
              {/* <TextField
                number={6}
                heading="Location"
                name="formattedLocation"
                value={formattedLocation}
                placeholder="Enter your location here"
                autoSize={{
                  minRows: 2,
                  maxRows: 6,
                }}
                message={"Please enter location"}
                star={true}
              /> */}
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  gap: "0px",
                  marginBottom: "5px",
                }}
              >
                <StyledNumber>6</StyledNumber>
                <StyleNText style={{ marginBottom: "5px" }}>
                  Location
                </StyleNText>
              </div>
              <Form.Item label="">
                {/* <Input size={"large"} value={formattedLocation} readOnly /> */}
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div>X: {x}</div>
                  <div>Y: {y}</div>
                </div> */}
                <TextArea
                  // disabled
                  readOnly
                  value={`${formattedLocation}\n X:${x}   Y: ${y}`}
                  // onChange={(e) => setValue(e.target.value)}
                  placeholder="Controlled autosize"
                  autoSize={{
                    // minRows: 3,
                    maxRows: 2,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={7}
                heading={"Picture 1"}
                description={"Please upload Picture 1"}
                star={true}
                name={`pic1`}
                multiple={false}
                fileList={pic1}
                setFileList={setPic1}
                api={`/post_treecutting_pic1/${0}/${userID}`}
                response={pic1Response}
                setResponse={setPic1Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={8}
                heading={"Picture 2"}
                description={"Please upload Picture 2"}
                star={true}
                name={`pic2`}
                multiple={false}
                fileList={pic2}
                setFileList={setPic2}
                api={`/post_treecutting_pic2/${pic2.length + 1}/${userID}`}
                response={pic2Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={9}
                heading={"Picture 3"}
                description={"Please upload Picture 3"}
                star={true}
                name={`pic3`}
                multiple={false}
                fileList={pic3}
                setFileList={setPic3}
                api={`/post_treecutting_pic3/${pic3.length + 1}/${userID}`}
                response={pic3Response}
                setResponse={setPic3Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={10}
                heading={"Picture 4"}
                description={"Please upload Picture 4"}
                star={true}
                name={`pic4`}
                multiple={false}
                fileList={pic4}
                setFileList={setPic4}
                api={`/post_treecutting_pic4/${pic4.length + 1}/${userID}`}
                response={pic4Response}
                setResponse={setPic4Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={11}
                heading={"Picture 5"}
                description={"Please upload Picture 5"}
                star={true}
                name={`pic5`}
                multiple={false}
                fileList={pic5}
                setFileList={setPic5}
                api={`/post_treecutting_pic5/${pic5.length + 1}/${userID}`}
                response={pic5Response}
                setResponse={setPic5Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={12}
                heading={"Picture 6"}
                description={"Please upload Picture 6"}
                star={true}
                name={`pic6`}
                multiple={false}
                fileList={pic6}
                setFileList={setPic6}
                api={`/post_treecutting_pic6/${pic6.length + 1}/${userID}`}
                response={pic6Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={13}
                heading={"Picture 7"}
                description={"Please upload Picture 7"}
                star={true}
                name={`pic7`}
                multiple={false}
                fileList={pic7}
                setFileList={setPic7}
                api={`/post_treecutting_pic7/${pic7.length + 1}/${userID}`}
                response={pic7Response}
                setResponse={setPic7Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={14}
                heading={"Picture 8"}
                description={"Please upload Picture 8"}
                star={true}
                name={`pic8`}
                multiple={false}
                fileList={pic8}
                setFileList={setPic8}
                api={`/post_treecutting_pic8/${pic8.length + 1}/${userID}`}
                response={pic8Response}
                setResponse={setPic8Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>

          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={15}
                heading={"Picture 9"}
                description={"Please upload Picture 9"}
                star={true}
                name={`pic9`}
                multiple={false}
                fileList={pic9}
                setFileList={setPic9}
                api={`/post_treecutting_pic9/${pic9.length + 1}/${userID}`}
                response={pic9Response}
                setResponse={setPic9Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={16}
                heading={"Picture 10"}
                description={"Please upload Picture 10"}
                star={true}
                name={`pic10`}
                multiple={false}
                fileList={pic10}
                setFileList={setPic10}
                api={`/post_treecutting_pic10/${pic10.length + 1}/${userID}`}
                response={pic10Response}
                setResponse={setPic10Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={17}
                heading={"Picture 11"}
                description={"Please upload Picture 11"}
                star={true}
                name={`pic11`}
                multiple={false}
                fileList={pic11}
                setFileList={setPic11}
                api={`/post_treecutting_pic11/${pic11.length + 1}/${userID}`}
                response={pic11Response}
                setResponse={setPic11Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={18}
                heading={"Picture 12"}
                description={"Please upload Picture 12"}
                star={true}
                name={`pic12`}
                multiple={false}
                fileList={pic12}
                setFileList={setPic12}
                api={`/post_treecutting_pic12/${pic12.length + 1}/${userID}`}
                response={pic12Response}
                setResponse={setPic12Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={19}
                heading={"Picture 13"}
                description={"Please upload Picture 13"}
                star={true}
                name={`pic13`}
                multiple={false}
                fileList={pic13}
                setFileList={setPic13}
                api={`/post_treecutting_pic13/${pic13.length + 1}/${userID}`}
                response={pic13Response}
                setResponse={setPic13Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={20}
                heading={"Picture 14"}
                description={"Please upload Picture 14"}
                star={true}
                name={`pic14`}
                multiple={false}
                fileList={pic14}
                setFileList={setPic14}
                api={`/post_treecutting_pic14/${pic14.length + 1}/${userID}`}
                response={pic14Response}
                setResponse={setPic14Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={21}
                heading={"Picture 15"}
                description={"Please upload Picture 15"}
                star={true}
                name={`pic15`}
                multiple={false}
                fileList={pic15}
                setFileList={setPic15}
                api={`/post_treecutting_pic15/${pic15.length + 1}/${userID}`}
                response={pic15Response}
                setResponse={setPic15Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginRight: "30px" }}
            >
              <NextButton>
                <BackButton
                  // onClick={() => {
                  //   console.log("hello");
                  //   navigate(data["link"]);
                  // }}
                  onClick={handleGoBack}
                >
                  <BackButtonText>Back</BackButtonText>
                </BackButton>
                <Form.Item wrapperCol={{}}>
                  <Button
                    // onClick={() => {
                    //     console.log(formData, "formData");
                    //   navigate(data["link"]);
                    // }}
                    className="next_btn_network"
                    // onClick={nextStep}
                    type="primary"
                    size={"large"}
                    htmlType="submit"
                    style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                  >
                    Next
                  </Button>
                </Form.Item>
              </NextButton>
            </Col>
          </Row>
        </Form>
        <Modal visible={spin} footer={false} closable={false} centered={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        </Modal>
        <Modal
          visible={success}
          footer={false}
          closable={false}
          bodyStyle={{ backgroundColor: "#fff" }}
        >
          <div className="i-modal-box">
            <img src={tick} width="200px" />
            <div className="i-modal-head-text">{response}</div>
            <div className="i-modal-text">Thank you for filling the form</div>
            <Link to="/SelectForm" id="sf-link-tag">
              <button id="i-done-btn">Done</button>
            </Link>
          </div>
        </Modal>
      </StyledBody>
      <MapLocation setMapLocation={setMapLocation} />
    </>
  );
};

export default TreeCuttingForm;
