import React, { useEffect, useState } from "react";
import "./dashboard.css";
import clipboard from "../../../image/clipboard-text.svg";
import info from "../../../image/info-circle.svg";
import flag from "../../../image/flag-2.svg";
import folder from "../../../image/folder-connection.svg";
import clock from "../../../image/timer.svg";
import BarChart from "./components/barChart";
import ReportsMap from "./components/reportsMap";
import { Col, Row, Space, Spin } from "antd";
import axios, { baseUrl, removeStorage } from "../../../utils/axios";
import {
  A55Block,
  DPole,
  Network,
  Raise,
  StyledBlockText,
  StyledCard,
  StyledCardText,
  StyledContent,
  StyledContent1,
  StyledContentCount,
  StyledContentText,
  StyledDashboardContainer,
  StyledDropDown,
  StyledDropDownTime,
  StyledGraphCard,
  StyledHeading,
  StyledIcon,
  StyledMapContainer,
  StyledNumber,
  StyledTimeContainer,
  StyleGraphCardHeading,
  StyleGraphCardItem1,
  TextDiv,
} from "./styles/main.styles.js";
import LineChart from "./components/LineChart";
import { useNavigate } from "react-router-dom";

function Index() {
  const [userId, setUserId] = useState("");
  const [a55, setA55] = useState(0);
  const [hyperOp, setHyperOp] = useState(0);
  const [dPole, setDPole] = useState(0);
  const [treeCutting, setTreeCutting] = useState(0);
  const [net, setNet] = useState(0);
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState("month");
  const [timeSelected, setTimeSelected] = useState("week");
  const [cardLoading, setCardLoading] = useState(false);
  const [type, setType] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const cardsData = async () => {
      setCardLoading(true);
      const user = localStorage.getItem("A55_Portal");
      const typeName = localStorage.getItem("A55_Portal_type");
      console.log("hyperType", typeName);
      // console.log("statusSelected", statusSelected);
      setType(typeName)
      setUserId(user);
      const result = await axios
        .get(baseUrl + "/get_cards?user_id=" + user)
        .then((res) => {
          console.log(res.data, "tree");
          setA55(res.data["a55"]);
          setHyperOp(res.data["hyperopticform"]);//Render new cards 
          setDPole(res.data["d_pole"]);
          setNet(res.data["network"]);
          setTreeCutting(res.data["treecutting"]);
          setTime(res.data["avg_time"]);
          setTotal(
            res.data["a55"] +
            res.data["d_pole"] +
            res.data["hyperopticform"] +
            res.data["network"] +
            res.data["rac"]
          );
          setCardLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          setCardLoading(false);
        });
    };
    cardsData();
  }, []);
  return (
    <>
      <StyledDashboardContainer>
        <Row justify={"center"}>
          <Col xs={22} md={10} lg={4}>
            <StyledCard>
              {cardLoading ? (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ) : (
                <>
                  <StyledIcon>
                    <img src={clipboard} height="40px" width="40px" />
                  </StyledIcon>
                  {type === "Admin" && hyperOp ? (
                    <>
                      <StyledCardText>Hyper Optic Form</StyledCardText>
                      <StyledNumber>{hyperOp}</StyledNumber>
                    </>
                  ) : (
                    <>
                      <StyledCardText>A55 Blockage Form</StyledCardText>
                      <StyledNumber>{a55}</StyledNumber>
                    </>
                  )}



                </>
              )}
            </StyledCard>
          </Col>
          <Col xs={22} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <StyledCard>
              {cardLoading ? (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ) : (
                <>
                  <StyledIcon>
                    <img src={info} height="40px" width="40px" />
                  </StyledIcon>
                  <StyledCardText>Tree Cutting</StyledCardText>
                  <StyledNumber>{treeCutting}</StyledNumber>
                </>
              )}
            </StyledCard>
          </Col>
          <Col xs={22} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <StyledCard>
              {cardLoading ? (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ) : (
                <>
                  <StyledIcon>
                    <img src={flag} height="40px" width="40px" />
                  </StyledIcon>
                  <StyledCardText>D - Pole</StyledCardText>
                  <StyledNumber>{dPole}</StyledNumber>
                </>
              )}
            </StyledCard>
          </Col>
          <Col xs={22} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <StyledCard>
              {cardLoading ? (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ) : (
                <>
                  <StyledIcon>
                    <img src={folder} height="40px" width="40px" />
                  </StyledIcon>
                  <StyledCardText>Network Adjustment</StyledCardText>
                  <StyledNumber>{net}</StyledNumber>
                </>
              )}
            </StyledCard>
          </Col>
          <Col xs={22} md={2} lg={1}></Col>
          <Col xs={22} md={10} lg={4}>
            <StyledCard>
              {cardLoading ? (
                <Space size="large">
                  <Spin size="large" />
                </Space>
              ) : (
                <>
                  <StyledIcon>
                    <img src={clock} height="40px" width="40px" />
                  </StyledIcon>
                  <StyledCardText>Average Time</StyledCardText>
                  <StyledNumber>{time} min</StyledNumber>
                </>
              )}
            </StyledCard>
          </Col>
        </Row>
        <Row
          className="status_time_row"
          style={{ marginTop: "30px" }}
          gutter={[10, 10]}
        >
          <Col xs={24} md={14} xl={14} className="ant_col">
            <StyledGraphCard style={{ padding: "20px" }}>
              <StyleGraphCardHeading>
                <StyleGraphCardItem1>Status</StyleGraphCardItem1>
                <TextDiv>
                  {type === "Admin" && hyperOp ? (
                    <StyledBlockText>
                      <A55Block />
                      Hper
                    </StyledBlockText>
                  ) : (
                    <StyledBlockText>
                      <A55Block />
                      A55
                    </StyledBlockText>
                  )}

                  {/* <StyledBlockText>
                    <A55Block />
                    A55
                  </StyledBlockText> */}
                  <StyledBlockText>
                    <DPole />D Pole
                  </StyledBlockText>
                  <StyledBlockText>
                    <Raise />
                    Raise a Case
                  </StyledBlockText>
                  <StyledBlockText>
                    <Network />
                    Network
                  </StyledBlockText>
                </TextDiv>
                <StyledDropDown
                  value={statusSelected}
                  onChange={(e) => setStatusSelected(e.target.value)}
                  className="bar-dropdown"
                >
                  <option value="week">Week</option>
                  <option value="month"> Month</option>
                  <option value="year">Year</option>
                </StyledDropDown>
              </StyleGraphCardHeading>
              <br />
              <div>
                <BarChart statusSelected={statusSelected} />
              </div>
            </StyledGraphCard>
          </Col>
          <Col xs={24} md={10} lg={10}>
            <StyledTimeContainer style={{ padding: "20px" }}>
              <div>
                <StyledHeading>Time Admitted</StyledHeading>
                <StyledDropDownTime
                  value={timeSelected}
                  onChange={(e) => setTimeSelected(e.target.value)}
                >
                  <option value="today"> Today</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </StyledDropDownTime>
              </div>
              <br />
              <div>
                <LineChart timeSelected={timeSelected} />
              </div>
            </StyledTimeContainer>
          </Col>
        </Row>

        <StyledMapContainer style={{ padding: "20px" }}>
          <StyledHeading>Reports By Map</StyledHeading>
          <Row style={{ marginTop: "5px" }}>
            <Col xs={16} md={16} lg={16}>
              <ReportsMap />
            </Col>
            <Col xs={8} md={8} lg={8}>
              <StyledContent>
                <StyledContent1>
                  <StyledContentText>Response</StyledContentText>
                  <StyledContentCount>{total}</StyledContentCount>
                </StyledContent1>
                <StyledContent1
                  className="reportByMap_average"
                  style={{ marginTop: "30px" }}
                >
                  <StyledContentText>
                    Average Time To Complete
                  </StyledContentText>
                  <StyledContentCount>{time}</StyledContentCount>
                </StyledContent1>
                <StyledContent1
                  className="reportByMap_status"
                  style={{ marginTop: "30px" }}
                >
                  <StyledContentText>Status</StyledContentText>
                  <StyledContentCount>Active</StyledContentCount>
                </StyledContent1>
              </StyledContent>
            </Col>
          </Row>
        </StyledMapContainer>
        <br />
      </StyledDashboardContainer>
    </>
  );
}

export default Index;
