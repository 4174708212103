import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "antd";
import TextField from "./InnerItems/TextField";
import { BackButton, BackButtonText, NextButton } from "../styles/main.styles";
import DateField from "./InnerItems/DateField";
import RadioField from "./InnerItems/RadioField";
import Surface from "./InnerItems/Surface";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LocationField from "./InnerItems/LocationField";
import CustomSelector from "./InnerItems/selector";
import { useLocation } from "react-router-dom";

const phoneRegex = /^[0-9]+$/;
const maxTelephoneLength = 15;

const validatePhoneNumber = (_, value, callback) => {
  if (value && value.length !== maxTelephoneLength) {
    callback(`Telephone number must be ${maxTelephoneLength} digits.`);
  } else {
    callback();
  }
  // if (!value) {
  //   return Promise.reject("Please enter valid Telephone No");
  // }
  // if (!phoneRegex.test(value)) {
  //   return Promise.reject("Please enter a valid phone number.");
  // }
  return Promise.resolve();
};

function StepSite({ data, setStepNumber, setData }) {
  const { state } = useLocation();

  console.log("state....", state);
  const navigate = useNavigate();
  const formatDate = (date) => {
    return date ? moment(date) : null;
  };

  const formattedState = state
    ? { ...state, date: formatDate(state.date) }
    : data;
  const nextStep = (values) => {
    setData(values);
    window.scrollTo(0, 0);
    setStepNumber(1);
  };
  const onFinishFailed = (errorInfo) => {};
  const handleKeyPress = (event) => {
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Form
        onFinish={nextStep}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 0 }}
        style={{ width: "100%" }}
        wrapperCol={{ span: 24 }}
        initialValues={state !== "null" ? formattedState : data}
        autoComplete="off"
      >
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <DateField
              number={8}
              heading={"Date"}
              name="date"
              message={"Please enter Date"}
              star={true}
              description={"Enter the date you submitted this request"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={1}
              heading={"Customer ID"}
              name="customer_id"
              placeholder={"Enter customer id"}
              message={"Please enter customer id"}
              // star={true}
              description={"Enter enter customer id"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={2}
              heading={"Engineer Onsite"}
              name="engineer_on_site"
              placeholder={"Enter Engineer"}
              message={"Please enter Engineer"}
              // star={true}
              description={"Enter Engineer Onsite"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={3}
              heading={"Engineer Contact No:"}
              name="engineer_contact_no"
              placeholder={"Enter Engineer contact no here"}
              // star={true}
              message={"Please enter valid contact No"}
              description={"Enter onsite engineers contact number"}
              maxLength={maxTelephoneLength}
              onClick={handleKeyPress}
              rule={[
                {
                  // required: true,
                  message: "Please enter valid Contact No",
                  validator: validatePhoneNumber,
                },
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={4}
              heading={"Post Code:"}
              name="post_code"
              placeholder={"Enter Post Code"}
              message={"Please enter post code"}
              // star={true}
              description={"Enter Post Code"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={5}
              heading={"Customers address/road name:"}
              name="customer_address"
              placeholder={"Enter Customers address/road name"}
              message={"Please enter Customers address/road name"}
              // star={true}
              description={"Enter Customers address/road name"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={6}
              heading={"XY Coordinates:"}
              name="xy_coordinates"
              placeholder={"2762.62, 67778.32"}
              message={"Please enter XY Coordinates"}
              // star={true}
              description={"Enter XY Coordinates"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={7}
              heading={"PIA Job NO:"}
              name="pia_job_no"
              placeholder={"Enter PIA Job NO"}
              message={"Please enter PIA Job NO"}
              // star={true}
              description={"Enter PIA Job NO"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={8}
              heading={"Detail Work Required:"}
              name="detail_work_required"
              placeholder={"Enter Detail Work"}
              message={"Please enter Detail Work"}
              // star={true}
              description={"Enter Detail Work"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={9}
              heading={"No of blockages:"}
              name="number_of_blockages"
              placeholder={"Enter No of blockages"}
              message={"Please enter No of blockages"}
              // star={true}
              description={"Enter No of blockages"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={10}
              heading={"Void Meterage:"}
              name="void_meterage"
              placeholder={"Enter Void Meterage"}
              message={"Please enter Void Meterage"}
              // star={true}
              description={"Enter Void Meterage"}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={11}
              heading={"New Track meterage"}
              name="new_track_meterage"
              placeholder={"Enter New Track meterage"}
              message={"Please enter New Track meterage"}
              // star={true}
              description={"Enter New Track meterage"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={12}
              heading={"Civil required footway meterage"}
              name="civils_required_footway_meterage"
              placeholder={"Enter footway meterage"}
              message={"Please enter footway meterage"}
              // star={true}
              description={"Enter footway meterage"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={13}
              heading={"Civil required carriage meterage"}
              name="civils_required_carriageway_meterage"
              placeholder={"Enter Civil required carriage meterage"}
              message={"Please enter Civil required carriage meterage"}
              // star={true}
              description={"Enter Civil required carriage meterage"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={14}
              heading={"Civil required verge/soft meterage"}
              name="civils_required_verge_meterage"
              placeholder={"Enter Civil required verge/soft meterage"}
              message={"Please enter Civil required verge/soft meterage"}
              // star={true}
              description={"Enter Civil required verge/soft meterage"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={15}
              heading={"Duct Section No:"}
              name="duct_section_number"
              placeholder={"Enter Duct section no"}
              message={"Please enter duct section no"}
              // star={true}
              description={"Enter duct section no"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={16}
              heading={"Percentage Bore Full: "}
              name="percentage_bore_full"
              placeholder={"Enter your answer"}
              message={"Please enter your answer"}
              // star={true}
              description={"Enter enter your answer"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={17}
              heading={"Number of toby Boxes required:"}
              name="number_of_toby_boxes_required"
              placeholder={"Enter your answer"}
              message={"Please enter your answer"}
              // star={true}
              description={"Enter enter your answer"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <TextField
              number={18}
              heading={"Number of chambers required:"}
              name="number_of_chambers_required"
              placeholder={"Enter your answer"}
              message={"Please enter your answer"}
              // star={true}
              description={"Enter enter your answer"}
            />
          </Col>
        </Row>
        {/* <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <RadioField
              number={3}
              heading={"worked Required"}
              name="work_required"
              placeholder={"Enter  here"}
              message={"Please select worked required in this cluster"}
              star={true}
              description={
                "Select whether this A55 is for Ancillary Works or Duct Blockage"
              }
              radioData={["Ancillary", "Blockage", "Desilt"]}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={5}
              heading={"Onsite Engineer"}
              name="onsite_eng"
              placeholder={"Enter onsite engineer here"}
              message={"Please enter Onsite Engineer"}
              star={true}
              description={"Enter details of onsite engineer"}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={7}
              heading={"PIA Order No"}
              name="pia_order_no"
              placeholder={"Enter PIA order no here"}
              message={"Please enter PIA Order No"}
              star={true}
              description={
                "Enter the Openreach Duct & Pole Sharing order number that this request refers to"
              }
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={1}
              heading={"site"}
              name="site"
              placeholder={"Enter site here"}
              message={"Please enter site"}
              star={true}
              description={""}
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={2}
              heading={"cluster"}
              name="area"
              placeholder={"Enter cluster here"}
              message={"Please enter cluster"}
              star={true}
              description={""}
              disabled={true}
            />
          </Col>
        </Row>
        <Row gutter={[48, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Surface
              number={9}
              heading={"Surface"}
              name="surface"
              placeholder={"Enter surface here"}
              message={"Please select a Surface"}
              star={true}
              description={
                "Please enter surface type eg F/W Slabs or C/W Tarmac etc Please also tick the 'Looks New' box if the surface looks newly laid in past 12 months as this may raise potential noticing issues"
              }
              // surface={data.surface}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <TextField
              number={10}
              heading={"Local Authority"}
              name="local_authority"
              placeholder={"Enter local authority here"}
              message={"Please enter Local Authority"}
              star={true}
              description={"Enter details of the Local Authority/Council"}
            />
            <div style={{ marginTop: "20px" }}>
              <LocationField
                number={11}
                heading={"Location"}
                name1="post_code_lat"
                name2="post_code_long"
                placeholder1={"Enter latitude of blockage"}
                placeholder2={"Enter Longitude of blockage"}
                message1={"Please enter latitude"}
                message2={"Please enter Longitude"}
                star={true}
                description={"Enter details of blockage location"}
                // onClick={handleKeyPress}
              />
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginRight: "30px" }}
          >
            <NextButton>
              <BackButton
                onClick={() => {
                  // navigate(data["link"]);
                  navigate(-1);
                }}
              >
                <BackButtonText>Back</BackButtonText>
              </BackButton>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  type="primary"
                  size={"large"}
                  htmlType="submit"
                  style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                >
                  Next
                </Button>
              </Form.Item>
            </NextButton>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default StepSite;
