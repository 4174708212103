import React, {useState} from "react";
import styled from "styled-components";
import {Button, Col, Input, Modal, Row, Select} from "antd";
import axios, {baseUrl, removeStorage} from "../../../utils/axios";
import {useNavigate} from "react-router-dom";

const UserModal = ({
                       isModalVisible,
                       setIsModalVisible,
                       setIsOrgModalVisible,
                       setIsSAModalVisible,
                       organizationName,
                       userLimit,
                       adminLimit,
                       editRecord,
                   }) => {
    const {Option} = Select;
    const getString = (str) => {
        return str ? str : "";
    };

    const regex = "^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|[1][012])-[0-9]{4}$";
    let [device, setDevice] = useState(editRecord);
    /////////////////////////////
    const [name, setName] = useState("");
    const [userId, setUserID] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [form, setForm] = useState("");
    const [role, setRole] = useState("Admin");
    let [status, setStatus] = useState(
        device ? getString(device.status) : "Active"
    );

    const navigate = useNavigate()
    const post = async (data) => {
        try {
            await axios
                .post(baseUrl + "/organization", data)
                .then((response) => {
                    alert("Added/Updated Successfully");
                    setIsModalVisible(false);
                    setIsSAModalVisible(false);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        removeStorage()
                        navigate("/login")
                    }
                    alert("something went wrong");
                });
        } catch (err) {

            alert("something went wrong");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            org_name: organizationName,
            admin_user_no: adminLimit,
            user_no: userLimit,
            user_id: userId,
            user_name: name,
            email,
            password,
        };
        post(data);
    };

    const handleBack = () => {
        setIsModalVisible(false);
        setIsOrgModalVisible(true);
    };

    return (
        <Modal
            style={{
                marginTop: "30px",
                zIndex: "99999",
                background: "white",
                borderRadius: "15px",
            }}
            width="50%"
            title=""
            closable={false}
            visible={isModalVisible}
            footer=""
        >
            <form
                onSubmit={handleSubmit}
                style={{
                    marginBottom: "-20px",
                }}
            >
                <Row gutter={15}>
                    <Col span={24} style={{textAlign: "left"}}>
                        <div style={{display: "flex", justifyContent: "left"}}>
                            <p style={{fontSize: "15px", fontWeight: "bolder"}}>
                                {device ? "+ Edit" : "+ Add"} User
                            </p>
                            {/* <Switch
                checked={status === "Active"}
                onChange={(checked, e) =>
                  setStatus(checked ? "Active" : "Inactive")
                }
              /> */}
                        </div>
                    </Col>
                    <Col span={12}>
                        <InputWrapper>
                            <div>
                                User ID: &nbsp;<span style={{color: "red"}}>*</span>
                            </div>
                            <StyledInput
                                value={userId}
                                onChange={(e) => setUserID(e.target.value)}
                                required
                            />
                        </InputWrapper>
                    </Col>
                    <Col span={12}>
                        <InputWrapper>
                            <div>
                                Name: &nbsp;<span style={{color: "red"}}>*</span>
                            </div>
                            <StyledInput
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </InputWrapper>
                    </Col>
                    <Col span={12}>
                        <InputWrapper>
                            <div>
                                Email Address: &nbsp;<span style={{color: "red"}}>*</span>
                            </div>
                            <StyledInput
                                value={email}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </InputWrapper>
                    </Col>

                    <Col span={12}>
                        <InputWrapper>
                            <div>
                                Password: &nbsp;<span style={{color: "red"}}>*</span>
                            </div>
                            <StyledInput
                                value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </InputWrapper>
                    </Col>
                    {/* <Col span={8}>
            <InputWrapper>
              <div>
                Form: &nbsp;<span style={{ color: "red" }}>*</span>
              </div>
              <Select
                value={form}
                style={{ width: "100%", border: "0px solid red" }}
                onChange={(value) => {
                  setForm(value);
                }}
              >
                <Option value="Admin">Admin</Option>
                <Option value="User">User</Option>
              </Select>
            </InputWrapper>
          </Col> */}
                    {/* <Col span={8}>
            <InputWrapper>
              <div>
                Role: &nbsp;<span style={{ color: "red" }}>*</span>
              </div>
              <Select
                value={role}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setRole(value);
                }}
              >
                <Option value="Admin">Admin</Option>
                <Option value="User">User</Option>
              </Select>
            </InputWrapper>
          </Col> */}

                    <Col span={24} style={{textAlign: "center"}}>
                        <div style={{height: "20px"}}></div>
                        <StyledButton color={"red"} onClick={handleBack}>
                            Back
                        </StyledButton>
                        &nbsp; &nbsp;{" "}
                        <StyledSubmitButton color={"#3D9F46"} type="submit" value="+ Add"/>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const StyledInput = styled(Input)`
  height: 2rem;
`;

const InputWrapper = styled.div`
  font-size: 12px;
  white-space: nowrap;
  /* display: flex;
  justify-content: space-between; */
  padding-bottom: 10px;
`;

const StyledSubmitButton = styled(Input)`
  font-size: 11px;
  font-weight: bolder;
  width: 15%;
  /* font-family: Montserrat-Regular; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;

  &:focus,
  &:hover {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    color: white;
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  height: 27px;
  font-size: 11px;
  font-weight: bolder;
  width: 15%;
  /* font-family: Montserrat-Regular; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
  border-radius: 5px;

  &:focus,
  &:hover {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    color: white;
    opacity: 0.8;
  }
`;

export default UserModal;
