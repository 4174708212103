import styled from "styled-components";
export const ProjectOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
export const ProjectInner = styled.div`
  width: 650px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  border: 1px solid #5c8246;
  display: flex;
  flex-direction: column;
  /* gap:4%; */
  justify-content: space-between;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 3%;
`;
export const ProjectTitle = styled.div`
  display: flex;
  /* margin-top: 5%; */
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.04em;
  color: #000000;
`;
export const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2%;
  border: 1px solid red;
  height: 100%;
  width: 100%;
`;
export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
  gap: 2%;
  height: 20%;
  width: 65%;
`;
export const DateContainer = styled.div`
  display: flex;
  width: 20%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #5c8246;
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.01);
  border-radius: 8px 0px 0px 8px;
`;
export const DateText = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;
export const ProjectDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 0px 8px 8px 0px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  padding-left: 4%;
`;
export const LineStyle = styled.div`
  width: 550px;
  border: 0.1px solid #d0d0d0;
  /* margin-top: 3%; */
`;
export const ProjButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content:flex-end; */
  gap: 3%;
`;
export const ProjButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 16px; */
  width: 140px;
  height: 45px;
  background: none;
  border-radius: 8px;
  border: 1px solid #5c8246;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #5c8246;
  letter-spacing: 0.02em;
  &:hover {
    border: none;
    background: #5c8246;
    color: #ffffff;
  }
  &:active {
    border: none;
    background: #5c8246;
    color: #ffffff;
  }
`;
export const FormDiv = styled.div`
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  margin-left: 2%;
  margin-right: 2%;
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 7px;
`;
export const RadioInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const SubmitFlex = styled.div`
  display: flex;
  justify-content: center;
`;
export const FormLabel = styled.label`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0.03em;
`;
export const StyledSubmitButton = styled.button`
  display: flex;
  /* padding: 10px 24px; */
  align-items: center;
  justify-content: center;
  width: 99px;
  height: 42px;
  background: #5c8246;
  border-radius: 8px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: none;
  letter-spacing: 0.04em;
`;
export const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 8px;
  /* color: #CCCCCC; */
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;
export const StyledSelectInput = styled.select`
  padding-left: 10px;
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 8px;
  /* color:#c5c5cd; */
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  letter-spacing: 0.04em;
  justify-content: center;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
`;
export const StyledTagsInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border-radius: 6px;
  margin-top: 10px;
`;
export const StyledTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
`;
export const DragFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
export const DragText = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  display: flex;
  flex-direction: row;
  gap: 4px;
  letter-spacing: 0.04em;
`;
