import styled from "styled-components";
export const StyledHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const StyledTitle = styled.p`
  padding-left: 10px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #292d32;
  margin-bottom: 20px;
`;
export const StyledBox = styled.div`
  margin-top: 50px;
  box-sizing: border-box;
  // display: flex;
  //   flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 281px;
  height: 70px;
  background: linear-gradient(270deg, #3d9f46 0%, #7fbe42 100%);
  border: 1px solid #7fbe42;
  border-radius: 5px;
  transition-duration: 0.2s;
  cursor: pointer;
`;
export const StyledIcon = styled.img`
  margin-right: 15px;
  height: 28px;
  width: 28px;
`;
export const StyledLabel = styled.p`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-top: 13px;
  letter-spacing: 0.03em;
  color: white;
`;
