import React, { useEffect, useState } from "react";
import "../../../css/Dashboard.css";
import clipboard from "../../../image/clipboard-text.svg";
import BarChart from "./components/barChart";
import { Col, Row } from "antd";
import axios, { baseUrl, removeStorage } from "../../../utils/axios";
import LineChart from "./components/lineChart";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  StyledCard2,
  StyledCard3,
  StyledCardText,
  StyledDashboardContainer,
  StyledGraphCard,
  StyledHeading,
  StyledIcon,
  StyledNumber,
  StyledTimeContainer,
} from "./styles/main.styles.js";

function Index() {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [cardsData, setCardsData] = useState(null);
  const [currentMonthData, setCurrentMonthData] = useState({});
  const [previousMonthData, setPreviousMonthData] = useState({});
  const [yearData, setYearData] = useState({});
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState("month");
  const [timeSelected, setTimeSelected] = useState("week");

  useEffect(() => {
    (async () => {
      await axios
        .get(baseUrl + "/organization")
        .then((response) => {
          setOrganizations(response.data);
          setSelectedOrganization(response.data[0]);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    })();
  }, []);

  useEffect(() => {}, [currentMonthData]);

  useEffect(() => {
    if (selectedOrganization) {
      (async () => {
        await axios
          .get(baseUrl + "/organization/cards/" + selectedOrganization?.org_id)
          .then((response) => {
            setCardsData(response.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeStorage();
              navigate("/login");
            }
          });
        await axios
          .get(
            baseUrl +
              "/organization/currentMonth/" +
              selectedOrganization?.org_id
          )
          .then((response) => {
            setCurrentMonthData(response.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeStorage();
              navigate("/login");
            }
          });

        await axios
          .get(
            baseUrl +
              "/organization/previousMonth/" +
              selectedOrganization?.org_id
          )
          .then((response) => {
            setPreviousMonthData(response.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeStorage();
              navigate("/login");
            }
          });

        await axios
          .get(baseUrl + "/organization/year/" + selectedOrganization?.org_id)
          .then((response) => {
            setYearData(response.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeStorage();
              navigate("/login");
            }
          });
      })();
    }
  }, [selectedOrganization]);

  const handleOrgClick = async (org) => {
    setSelectedOrganization(org);
    await axios
      .get(baseUrl + "/organization/" + org?.org_id)
      .then((response) => {
        localStorage.setItem("selected_org", JSON.stringify(response.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeStorage();
          navigate("/login");
        }
      });
  };
  return (
    <>
      <StyledDashboardContainer>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <StyledCard2>
              {organizations
                ? organizations.map((org, key) => (
                    <StyledCard3
                      key={key}
                      selected={org.org_id === selectedOrganization.org_id}
                      onClick={() => handleOrgClick(org)}
                    >
                      {org?.org_name}
                    </StyledCard3>
                  ))
                : null}
            </StyledCard2>
          </Col>
          <Col span={20}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <StyledCard style={{ margin: "0" }}>
                  <StyledIcon>
                    <img src={clipboard} height="40px" width="40px" />
                  </StyledIcon>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ textAlign: "center" }}>
                    <StyledCardText>Admin</StyledCardText>
                    <StyledNumber>{cardsData?.admin}</StyledNumber>
                  </div>
                </StyledCard>
              </Col>
              <Col span={6}>
                <StyledCard style={{ margin: "0" }}>
                  <StyledIcon>
                    <img src={clipboard} height="40px" width="40px" />
                  </StyledIcon>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ textAlign: "center" }}>
                    <StyledCardText>Admin Limit</StyledCardText>
                    <StyledNumber>{cardsData?.admin_limit}</StyledNumber>
                  </div>
                </StyledCard>
              </Col>
              <Col span={6}>
                <StyledCard style={{ margin: "0" }}>
                  <StyledIcon>
                    <img src={clipboard} height="40px" width="40px" />
                  </StyledIcon>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ textAlign: "center" }}>
                    <StyledCardText>User</StyledCardText>
                    <StyledNumber>{cardsData?.user}</StyledNumber>
                  </div>
                </StyledCard>
              </Col>
              <Col span={6}>
                <StyledCard style={{ margin: "0" }}>
                  <StyledIcon>
                    <img src={clipboard} height="40px" width="40px" />
                  </StyledIcon>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ textAlign: "center" }}>
                    <StyledCardText>User Limit</StyledCardText>
                    <StyledNumber>{cardsData?.user_limit}</StyledNumber>
                  </div>
                </StyledCard>
              </Col>
              <Col span={12}>
                <StyledGraphCard style={{ padding: "20px" }}>
                  <StyledHeading>Current Month</StyledHeading>
                  <div>
                    <BarChart
                      data={currentMonthData}
                      statusSelected={statusSelected}
                    />
                  </div>
                </StyledGraphCard>
              </Col>
              <Col span={12}>
                <StyledGraphCard style={{ padding: "20px" }}>
                  <StyledHeading>Previous Month</StyledHeading>
                  <div>
                    <BarChart
                      data={previousMonthData}
                      statusSelected={statusSelected}
                    />
                  </div>
                </StyledGraphCard>
              </Col>
              <Col span={24}>
                <StyledTimeContainer style={{ padding: "20px", margin: "0" }}>
                  <StyledHeading>Last 12 Month</StyledHeading>
                  <br />
                  <LineChart data={yearData} timeSelected={timeSelected} />
                </StyledTimeContainer>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
      </StyledDashboardContainer>
    </>
  );
}

export default Index;
