import React, { useEffect, useState } from "react";
import "./addlocation.css";
import loc from "../../../image/location-add.svg";
import { Col, Modal, Row, Space, Spin, Radio, Button } from "antd";

import Swal from "sweetalert2";
import axios, { baseUrl, header, removeStorage } from "../../../utils/axios";
import { BackwardOutlined } from "@ant-design/icons";
import TextField from "../../user/siteForm/items/InnerItems/TextField";
import RadioField from "../../user/siteForm/items/InnerItems/RadioField";
import {
  StyledAddLocBtn,
  StyledButton,
  StyledInput,
  StyledInputBox,
  StyledLabel,
  StyledLocationContainer,
  StyledLocBody,
  StyledLocHeading,
  StyledSelectInput,
  StyledTagList,
  StyledTagsInput,
} from "./styles/main.styles";
import { useNavigate } from "react-router-dom";

function AddLocation() {
  const navigate = useNavigate();
  const [val, setVal] = useState(false);
  const [tags, setTags] = useState([]);
  const [site, setSite] = useState([]);
  const [formSelected, setFormSelected] = useState("");
  const [siteSelected, setSiteSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    exchange: "",
    tel_no: "",
    local_auth: "",
    percentage_bore_full: "",
    survey_required: "",
    pia_noi: "",
    duct_type: "",
  });
  console.log(formData, "formData");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "" && !tags.includes(event.target.value)) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const getArea = async () => {
    if (formSelected !== "" && siteSelected !== "") {
      const user = localStorage.getItem("A55_Portal");
      const result = await axios
        .get(
          baseUrl +
            "/get_area_by_site?user_id=" +
            user +
            "&form=" +
            formSelected +
            "&site=" +
            siteSelected
        )
        .then((res) => {
          const Data = res.data["area"];
          setTags(Data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    getArea();
  }, [siteSelected]);
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };
  const HandleFormSelection = (e) => {
    const form = e.target.value;
    setFormSelected(form);
    setSiteSelected("");
    const siteForm = async () => {
      setLoading(true);
      const user = localStorage.getItem("A55_Portal");
      const result = await axios
        .get(
          baseUrl + "/get_sites_by_form?user_id=" + user + "&form=" + form,
          header
        )
        .then((res) => {
          console.log(res, "sites");
          setLoading(false);
          setSite(res.data["site"]);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    };
    siteForm();
  };
  const AddLoc = ({ values, e }) => {
    console.log(values, "valuesss");
    // e.preventDefault();
    const user = localStorage.getItem("A55_Portal");
    const postLocation = async () => {
      const user = localStorage.getItem("A55_Portal");
      if (formSelected === "") {
        openSweetAlert("Select Form", "error");
      } else if (siteSelected === "") {
        openSweetAlert("Add Site", "error");
      } else if (tags.length === 0) {
        openSweetAlert("Add tags", "error");
      }
      // else if (formData.exchange == "") {
      //   openSweetAlert("Enter exchange", "error");
      // } else if (formData.tel_no == "") {
      //   openSweetAlert("Enter telephone no", "error");
      // } else if (formData.local_auth == "") {
      //   openSweetAlert("Local authority", "error");
      // } else if (formData.percentage_bore_full == "") {
      //   openSweetAlert("Enter percentage bore full", "error");
      // } else if (formData.survey_required == "") {
      //   openSweetAlert("Enter traffic lights", "error");
      // } else if (formData.pia_noi == "") {
      //   openSweetAlert("Enter pia order no", "error");
      // } else if (formData.duct_type == "") {
      //   openSweetAlert("Enter duct type", "error");
      // }
      else {
        setLoading(true);
        let data = {
          form: formSelected,
          site: siteSelected,
          area: tags,
          user_id: user,
          exchange: formData.exchange,
          tel_no: formData.tel_no,
          local_auth: formData.local_auth,
          percentage_bore_full: formData.percentage_bore_full,
          survey_required: formData.survey_required,
          pia_noi: formData.pia_noi,
          duct_type: formData.duct_type,
        };
        let data2 = {
          form: formSelected,
          site: siteSelected,
          area: tags,
          user_id: user,
        };
        console.log(data, "tags data");
        const result = await axios
          .post(
            baseUrl + "/post_location",
            formSelected == "A55" ? data : data2,
            header
          )
          .then((res) => {
            console.log(res, "location res");
            if (res.status == "200") {
              localStorage.setItem("locationData", JSON.stringify(data));
              openSweetAlert("Location Added Successfully", "success");
              setLoading(false);
              setSite([]);
              setTags([]);
              setSiteSelected("");
              setFormSelected("");
              setVal(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              removeStorage();
              navigate("/login");
            } else {
              openSweetAlert("Something Went Wrong!", "error");
            }
          });
      }
    };
    postLocation();
  };
  console.log(formSelected, "formSelectedformSelectedformSelected");
  return (
    <>
      <StyledLocationContainer>
        <Row>
          <Col>
            <img src={loc} width="23px" height="23px" />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <StyledLocHeading>Add Location</StyledLocHeading>
          </Col>
        </Row>
        <StyledLocBody>
          <Row style={{ marginTop: "0px" }}>
            <Col xs={24} md={24} lg={24}>
              <StyledInputBox>
                <StyledLabel>Select Form</StyledLabel>
                <StyledSelectInput
                  name="forms"
                  value={formSelected}
                  onChange={HandleFormSelection}
                >
                  <option value="" disabled selected hidden>
                    Choose Form
                  </option>
                  <option value="A55">A55 Blockage</option>
                  <option value="net">Network Adjustment</option>
                  <option value="D pole">D - Pole</option>
                  <option value="raise">Raise a Case</option>
                  <option value="tree-cutting">Tree Cutting</option>
                  <option value="hyper-optics">Hyper Optics</option>
                </StyledSelectInput>
              </StyledInputBox>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24} md={24} lg={24}>
              <StyledInputBox>
                {val !== true ? (
                  <>
                    <StyledLabel>Site Details</StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <StyledSelectInput
                        name="sites"
                        value={siteSelected}
                        onChange={(e) => {
                          setSiteSelected(e.target.value);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Choose a Site
                        </option>
                        {site.map((val, key) => {
                          return (
                            <option
                              style={{ position: "absolute", zIndex: "999" }}
                              value={val}
                            >
                              {val}
                            </option>
                          );
                        })}
                      </StyledSelectInput>
                      <StyledButton
                        onClick={() => {
                          setVal(true);
                          setTags([]);
                          setSiteSelected("");
                        }}
                      >
                        + Add Site
                      </StyledButton>
                    </div>
                  </>
                ) : (
                  <div style={{ marginTop: "-40px" }}></div>
                )}
                {val ? (
                  <StyledInputBox style={{ marginTop: "40px" }}>
                    <StyledLabel>Enter Site</StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <StyledInput
                        type="text"
                        placeholder="Enter Site Name"
                        value={siteSelected}
                        onChange={(e) => {
                          setSiteSelected(e.target.value);
                        }}
                      />
                      <StyledButton onClick={() => setVal(false)}>
                        Cancel
                      </StyledButton>
                    </div>
                  </StyledInputBox>
                ) : null}
              </StyledInputBox>
              {/* {site === [] ? ( */}
              {/* {val !== true ? (
                  <StyledButton
                    onClick={() => {
                      setVal(true);
                      setTags([]);
                      setSiteSelected("");
                    }}
                  >
                    + Add Site
                  </StyledButton>
                ) : (
                  <StyledButton onClick={() => setVal(false)}>
                    Cancel
                  </StyledButton>
                )} */}
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={24}>
              <StyledInputBox>
                <StyledLabel>Enter Area</StyledLabel>
                <StyledInput
                  type="text"
                  placeholder="Enter Area Name | Press Enter to add the Tags"
                  onKeyUp={(event) =>
                    event.key === "Enter" ? addTags(event) : null
                  }
                />
                <StyledTagsInput>
                  <StyledTagList>
                    {tags.map((tag, index) => (
                      <li className="tag" key={index}>
                        {console.log(tags, "tagssss")}
                        <span style={{ marginTop: "3px" }}>{tag}</span>
                        <span
                          className="tag-close-icon"
                          onClick={() => removeTags(index)}
                        >
                          x
                        </span>
                      </li>
                    ))}
                  </StyledTagList>
                </StyledTagsInput>
              </StyledInputBox>
            </Col>
          </Row>
          {formSelected == "A55" ? (
            <Row style={{ marginTop: "0px" }}>
              <Col
                xs={12}
                style={{ marginBottom: "30px", paddingRight: "5px" }}
              >
                <StyledLabel>Exchange</StyledLabel>
                <StyledInput
                  name="exchange"
                  type="text"
                  placeholder="Enter exchange"
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "30px" }}>
                <StyledLabel>Telephone No</StyledLabel>
                <StyledInput
                  name="tel_no"
                  type="text"
                  placeholder="Enter telephone no"
                  onChange={handleInputChange}
                />
              </Col>
              <Col
                xs={12}
                style={{ marginBottom: "30px", paddingRight: "5px" }}
              >
                <StyledLabel>Local Authority</StyledLabel>
                <StyledInput
                  name="local_auth"
                  type="text"
                  placeholder="Enter local authority"
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "30px" }}>
                <StyledLabel>PIA Order No</StyledLabel>
                <StyledInput
                  name="pia_noi"
                  type="text"
                  placeholder="Enter PIA order no"
                  onChange={handleInputChange}
                />
              </Col>

              <Col
                xs={24}
                lg={12}
                style={{ marginBottom: "30px", paddingRight: "5px" }}
              >
                <StyledLabel>Percentage Bore Full</StyledLabel>
                <br />
                <Radio.Group
                  name="percentage_bore_full"
                  onChange={(e) =>
                    handleRadioChange(e.target.name, e.target.value)
                  }
                  value={formData.percentage_bore_full}
                  style={{
                    background: "rgba(255, 255, 255, 0.98)",
                    border: "1px solid rgba(164, 164, 164, 0.4)",
                    borderRadius: "5px",
                    padding: "13px",
                    width: "100%",
                  }}
                >
                  <Radio value={"0-50%"}>0-50%</Radio>
                  <Radio value={"51-90%"}>51-90%</Radio>
                  <Radio value={"91-100%"}>91-100%</Radio>
                </Radio.Group>
              </Col>

              <Col xs={24} lg={12} style={{ marginBottom: "30px" }}>
                <StyledLabel>Duct Type</StyledLabel>
                <br />

                <Radio.Group
                  name="duct_type"
                  // onChange={handleRadioChange}
                  onChange={(e) =>
                    handleRadioChange(e.target.name, e.target.value)
                  }
                  value={formData.duct_type}
                  style={{
                    background: "rgba(255, 255, 255, 0.98)",
                    border: "1px solid rgba(164, 164, 164, 0.4)",
                    borderRadius: "5px",
                    padding: "13px",
                    width: "100%",
                  }}
                >
                  <Radio value={"PVC"}>PVC</Radio>
                  <Radio value={"Steel"}>Steel</Radio>
                  <Radio value={"Other..."}>Other</Radio>
                </Radio.Group>
              </Col>

              <Col xs={24} sm={24} lg={12} style={{ marginBottom: "30px" }}>
                <StyledLabel>Traffic Lights</StyledLabel>
                <br />

                <Radio.Group
                  name="survey_required"
                  onChange={(e) =>
                    handleRadioChange(e.target.name, e.target.value)
                  }
                  value={formData.survey_required}
                  style={{
                    background: "rgba(255, 255, 255, 0.98)",
                    border: "1px solid rgba(164, 164, 164, 0.4)",
                    borderRadius: "5px",
                    padding: "13px",
                    width: "100%",
                  }}
                >
                  <Radio value={"Survey Required"}>Survey Required</Radio>
                  <Radio value={"2 Way"}>2 Way</Radio>
                  <Radio value={"3 Way"}>3 Way</Radio>
                  <Radio value={"4 Way"}>4 Way</Radio>
                </Radio.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row style={{ marginTop: "1%" }}>
            <Col xs={1} md={1} lg={24}>
              <StyledAddLocBtn onClick={AddLoc}>Add</StyledAddLocBtn>
            </Col>
          </Row>
        </StyledLocBody>
      </StyledLocationContainer>
      <Modal visible={loading} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </>
  );
}

export default AddLocation;
