import React from "react";
import Swal from "sweetalert2";

import {
  ImageDiv,
  ImageText,
  ImageUpload,
  ImageUploadButton,
  StyleDescText,
  StyledNumber,
  StyleNText,
  StyleNumberDiv,
  StyleRedStar,
  StyleTextDiv,
  UploadButton,
  UploadText,
} from "../../styles/main.styles";
import { baseUrl } from "../../../../../utils/axios";
import imp from "../../../../../image/upload.svg";
import Dragger from "antd/es/upload/Dragger";

function ImageField({
  number, // Not related to Antd
  heading, // Not related to Antd
  description, // Not related to Antd
  star, // Not related to Antd
  name, // key through which backend will access file
  multiple, // to upload more than one file at same time use true
  fileList, // useState (list) contains json object of file uploaded
  setFileList, // useState (list) to set file list
  api, // api call baseurl
  setResponse, // set response of api use sate
  message, // not related to antd
  image, // not related to antd
  maxCount, // maximum number of file that can be used
  setSpin, // not related to antd
  response, // not related to antd
  errorr,
}) {
  console.log(fileList, "file list");
  const props = {
    name: name, // name  apx_view
    multiple: multiple, // true, false
    fileList: fileList, // useStatue (...)
    listType: "picture", // picture, file
    maxCount: maxCount, // number file
    action: baseUrl + api, // baseUrl POST FORM DATA

    onChange(info) {
      const isImage = info.file.type.startsWith("image/");
      const isAllowedExtension = /\.(jpg|jpeg|png)$/i.test(info.file.name);
      //       //
      console.log(info);
      setSpin(true);
      const { status } = info.file;
      let newFileList = [...info.fileList];
      if (status && isAllowedExtension) {
        setFileList(newFileList);
        if (maxCount == "1") {
          if (status !== "uploading") {
            setSpin(false);
            if (response) {
              console.log("upload res", response);
              response[fileList.length - 1] = info.file.response; // api response
              setResponse(response);
            } else {
              setResponse(info.file.response);
            }
          }
          if (status === "done") {
            setSpin(false);
          }
        } else {
          if (status !== "uploading") {
            setSpin(false);
            // if (response) {
            //   console.log("upload res", response);
            //   response[fileList.length - 1] = info.file.response; // api response
            //   setResponse(response);
            // } else {
            //   setResponse(info.file.response);
            // }
            if (status === "done") {
              // Collecting responses in an array
              const newResponse = [...(response || []), info.file.response];
              setResponse(newResponse);
            }
          }
          if (status === "done") {
            setSpin(false);
          }
        }
      } else if (status === "error" || !isImage || !isAllowedExtension) {
        Swal.fire({
          title: "You can only upload JPG or PNG files!",
          type: "error",
          allowOutsideClick: false,
        });
        setSpin(false);
      }
    },

    // onDrop(e) {},
  };
  return (
    <StyleTextDiv>
      <StyleNumberDiv>
        <StyledNumber>{number}</StyledNumber>
        <StyleNText> {heading}</StyleNText>
        {star ? <StyleRedStar>*</StyleRedStar> : null}
      </StyleNumberDiv>
      <StyleNumberDiv style={{ marginBottom: 15 }}>
        <StyleDescText>{description}</StyleDescText>
      </StyleNumberDiv>
      <ImageDiv className="imgdiv">
        <img
          src={image}
          alt={"Google"}
          style={{ margin: 10, borderRadius: 10, width: "98%" }}
        />
        <ImageUpload>
          <Dragger {...props} fileList={fileList}>
            <ImageUploadButton>
              <UploadButton>
                <img src={imp} alt={"upload"} />
                <UploadText>Upload</UploadText>
              </UploadButton>
            </ImageUploadButton>
          </Dragger>
        </ImageUpload>
        <ImageText>{message}</ImageText>
      </ImageDiv>
    </StyleTextDiv>
  );
}

export default ImageField;

// import React, { useState } from "react";
// import Swal from "sweetalert2";
// import Dragger from "antd/es/upload/Dragger";
// import {
//   ImageDiv,
//   ImageText,
//   ImageUpload,
//   ImageUploadButton,
//   StyleDescText,
//   StyledNumber,
//   StyleNText,
//   StyleNumberDiv,
//   StyleRedStar,
//   StyleTextDiv,
//   UploadButton,
//   UploadText,
// } from "../../styles/main.styles";
// import { baseUrl } from "../../../../../utils/axios";
// import imp from "../../../../../image/upload.svg";

// function ImageField({
//   number, // Not related to Antd
//   heading, // Not related to Antd
//   description, // Not related to Antd
//   star, // Not related to Antd
//   name, // key through which backend will access file
//   multiple, // to upload more than one file at same time use true
//   fileList, // useState (list) contains json object of file uploaded
//   setFileList, // useState (list) to set file list
//   api, // api call baseurl
//   setResponse, // set response of api use state
//   message, // not related to antd
//   image, // not related to antd
//   maxCount, // maximum number of file that can be used
//   setSpin, // not related to antd
//   response, // not related to antd
//   errorr,
// }) {
//   console.log(fileList, "file list");

//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   const props = {
//     name: name,
//     multiple: multiple,
//     fileList: fileList,
//     listType: "picture",
//     maxCount: maxCount,
//     action: baseUrl + api,

//     onChange(info) {
//       const isImage = info.file.type.startsWith("image/");
//       const isAllowedExtension = /\.(jpg|jpeg|png)$/i.test(info.file.name);
//       console.log(info);
//       setSpin(true);
//       const { status } = info.file;
//       let newFileList = [...info.fileList];

//       if (status && isAllowedExtension) {
//         setFileList(newFileList);

//         if (status !== "uploading") {
//           setSpin(false);

//           if (status === "done") {
//             const uploadedFile = {
//               uid: info.file.uid,
//               name: info.file.name,
//               status: info.file.status,
//               response: info.file.response,
//               url: info.file.response.url,
//             };
//             setUploadedFiles((prevFiles) => [...prevFiles, uploadedFile]);

//             if (multiple) {
//               setResponse((prevResponse) => [
//                 ...(prevResponse || []),
//                 info.file.response,
//               ]);
//             } else {
//               setResponse(info.file.response);
//             }
//           }
//         }
//       } else if (status === "error" || !isImage || !isAllowedExtension) {
//         Swal.fire({
//           title: "You can only upload JPG or PNG files!",
//           icon: "error",
//           allowOutsideClick: false,
//         });
//         setSpin(false);
//       }
//     },

//     onRemove(file) {
//       setUploadedFiles((prevFiles) =>
//         prevFiles.filter((item) => item.uid !== file.uid)
//       );
//       setFileList((prevList) =>
//         prevList.filter((item) => item.uid !== file.uid)
//       );
//       setResponse((prevResponse) =>
//         prevResponse
//           ? prevResponse.filter((item) => item.url !== file.response.url)
//           : []
//       );
//     },
//   };

//   return (
//     <StyleTextDiv>
//       <StyleNumberDiv>
//         <StyledNumber>{number}</StyledNumber>
//         <StyleNText> {heading}</StyleNText>
//         {star ? <StyleRedStar>*</StyleRedStar> : null}
//       </StyleNumberDiv>
//       <StyleNumberDiv style={{ marginBottom: 15 }}>
//         <StyleDescText>{description}</StyleDescText>
//       </StyleNumberDiv>
//       <ImageDiv className="imgdiv">
//         <img
//           src={image}
//           alt={"Google"}
//           style={{ margin: 10, borderRadius: 10, width: "98%" }}
//         />
//         <ImageUpload>
//           <Dragger {...props} fileList={fileList}>
//             <ImageUploadButton>
//               <UploadButton>
//                 <img src={imp} alt={"upload"} />
//                 <UploadText>Upload</UploadText>
//               </UploadButton>
//             </ImageUploadButton>
//           </Dragger>
//         </ImageUpload>
//         <ImageText>{message}</ImageText>
//       </ImageDiv>
//     </StyleTextDiv>
//   );
// }

// export default ImageField;
