import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Items/Header";
import { StyledBody } from "./styles/main.styles";
import moment from "moment/moment";
import TextField from "./Items/TextField";
import DateField from "./Items/DateField";
import LocationField from "./Items/LocationField";
import RadioField from "./Items/RadioField";
import Picture2 from "../../../image/Pic1.png";
import ImageField from "./Items/ImageField";
import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import tick from "../../../image/animation.gif";
import { baseUrl } from "../../../utils/axios";
import {
  BackButton,
  BackButtonText,
  NextButton,
} from "../siteForm/styles/main.styles";
import Swal from "sweetalert2";

import { BackwardOutlined } from "@ant-design/icons";
import axios from "axios";

const form = localStorage.getItem("form_selected");
const site = localStorage.getItem("siteSelectedValue");
const area = localStorage.getItem("selectedValue");
// console.log(site, area, "site and area");
const DPoleForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const location = useLocation();
  // const { site, area } = location.state || {};
  const d1 = new Date();
  const [startTime, setTime] = useState([0, 0, 0]);
  useEffect(() => {
    setTime([d1.getHours(), d1.getMinutes(), d1.getSeconds()]);
  }, []);

  if (state && Object.keys(state).includes("date_evidence_collected")) {
    state["date_evidence_collected"] =
      state["date_evidence_collected"] === ""
        ? ""
        : moment(state["date_evidence_collected"]);
  }

  const [data, setData] = useState(
    state ? state : { form_selected: localStorage.getItem("form_selected") }
  );

  console.log(state, "state");
  const [userID, setUserID] = useState(localStorage.getItem("A55_Portal"));
  const [pic1, setPic1] = useState(state ? [state.pic_one] : []);
  const [pic2, setPic2] = useState(state ? [state["picTwo"]] : []);
  const [pic3, setPic3] = useState(state ? [state["picThird"]] : []);
  const [pic4, setPic4] = useState(state ? [state["picFourth"]] : []);
  const [pic5, setPic5] = useState(state ? [state["picFifth"]] : []);
  const [pic6, setPic6] = useState(state ? [state["picSixth"]] : []);
  const [pic7, setPic7] = useState(state ? [state["picSeventh"]] : []);
  const [pic8, setPic8] = useState(state ? [state["picEight"]] : []);
  console.log(pic1, "pic1 in state");
  const [pic1Response, setPic1Response] = useState(["", ""]);
  const [pic2Response, setPic2Response] = useState(["", ""]);
  const [pic3Response, setPic3Response] = useState(["", ""]);
  const [pic4Response, setPic4Response] = useState(["", ""]);
  const [pic5Response, setPic5Response] = useState(["", ""]);
  const [pic6Response, setPic6Response] = useState(["", ""]);
  const [pic7Response, setPic7Response] = useState(["", ""]);
  const [pic8Response, setPic8Response] = useState(["", ""]);

  const [messageApi, contextHolder] = message.useMessage();
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(
    state === null || state["date"] === ""
      ? "Successfully submitted your response"
      : "Successfully edited your response"
  );

  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };

  useEffect(() => {}, []);

  const formRef = useRef();

  const edit = localStorage.getItem("edit");

  const respId = localStorage.getItem("respId");
  console.log(pic1, "pic 1");

  const nextStep = async (value) => {
    console.log(value, "valuesssss");
    const site = localStorage.getItem("siteSelectedValue");
    const area = localStorage.getItem("selectedValue");

    try {
      const formValues = await formRef.current.validateFields();

      const formattedDate = moment(
        formValues.date_evidence_collected,
        "YYYY-M-D"
      )
        .utc()
        .format("YYYY-M-D");

      if (state) {
        // localStorage.removeItem("edit");
        console.log(edit, "edit");
        // updateNetwork?resp_id=44
        console.log(pic1[0], "ppppiccc 1");

        if (pic1[0] == "") {
          openSweetAlert("upload picture 1", "error");
        } else if (pic2[0] == "") {
          openSweetAlert("upload picture 2", "error");
        } else if (pic3[0] == "") {
          openSweetAlert("upload pictur 3", "error");
        } else if (pic4[0] == "") {
          openSweetAlert("upload picture 4", "error");
        } else if (pic5[0] == undefined) {
          openSweetAlert("upload picture 5", "error");
        } else if (pic6[0] == undefined) {
          openSweetAlert("upload picture 6", "error");
        } else if (pic7[0] == undefined) {
          openSweetAlert("upload pictur 7", "error");
        } else if (pic8[0] == undefined) {
          openSweetAlert("upload picture 8", "error");
        } else {
          const formData = {
            ...formValues,
            pic_one: pic1[0].response,
            picTwo: pic2[0].response,
            picThird: pic3[0].response,
            picFourth: pic4[0].response,
            picfifth: pic5[0].response,
            picSixth: pic6[0].response,
            picSeventh: pic7[0].response,
            picEight: pic8[0].response,
            site: site,
            area: area,
            date: formattedDate,
            user_id: userID,
            resp_id: respId,
          };
          const d1 = new Date(); // End time
          formData["end_time"] = [
            d1.getHours(),
            d1.getMinutes(),
            d1.getSeconds(),
          ];

          const d2 = new Date(); // Start time
          formData["start_time"] = [
            d2.getHours(),
            d2.getMinutes(),
            d2.getSeconds(),
          ];

          await axios
            .post(baseUrl + "/post_dpole", formData)
            .then((res) => {
              localStorage.removeItem("edit");

              console.log(res, "response");
              openSweetAlert(res.data.out, "success");

              navigate("/SelectForm");
            })
            .catch((err) => {
              if (err.response.status === 401) {
                navigate("/SelectForm");
                console.log(err);
              }
            });
        }
      } else {
        var dt = new Date();
        console.log(pic1[0], "ppppiccc 1");
        const uploadedPics = [pic5[0], pic6[0], pic7[0], pic8[0]].filter(
          (pic) => pic !== undefined
        );
        if (pic1[0] == undefined) {
          openSweetAlert("upload picture for A55 Diagram", "error");
        } else if (pic2[0] == undefined) {
          openSweetAlert("upload picture for DP/CP Pole Number", "error");
        } else if (pic3[0] == undefined) {
          openSweetAlert("upload picture for Burn Markings", "error");
        } else if (pic4[0] == undefined) {
          openSweetAlert("upload picture for Full Length Photo", "error");
        } else if (uploadedPics.length < 3) {
          openSweetAlert(
            "Upload at least three pictures out of the Evidence photos",
            "error"
          );
        } else {
          const formData = {
            ...formValues,
            pic_one: pic1[0].response,
            picTwo: pic2[0].response,
            picThird: pic3[0].response,
            picFourth: pic4[0].response,
            picfifth: pic5[0] ? pic5[0].response : null,
            picSixth: pic6[0] ? pic6[0].response : null,
            picSeventh: pic7[0] ? pic7[0].response : null,
            picEight: pic8[0] ? pic8[0].response : null,
            date_evidence_collected: formattedDate,
            start_time: startTime,
            end_time: [dt.getHours(), dt.getMinutes(), dt.getSeconds()],
            site: site,
            area: area,
            user_id: userID,
          };
          console.log(formData, "form data");

          await axios
            .post(baseUrl + "/post_dpole", formData)
            .then((res) => {
              console.log(res, "response");
              openSweetAlert("Form Submitted Successfully", "success");
              navigate("/SelectForm");
            })
            .catch((err) => {
              if (err.response.status === 401) {
                navigate("/SelectForm");
                console.log(err);
              }
            });
        }
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const error = (errorMsg) => {
    messageApi.open({
      type: "error",
      content: errorMsg,
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleKeyPress = (event) => {
    const isValidInput = /^[0-9\b]+$/.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Button className="back_btn" key="cancel" onClick={handleGoBack}>
        <span>Go Back</span>
      </Button>
      {contextHolder}
      <Header form_name={data.form_selected} />
      <StyledBody>
        <Form
          ref={formRef}
          onFinish={nextStep}
          onFinishFailed={() => {}}
          labelCol={{ span: 0 }}
          style={{ width: "100%" }}
          wrapperCol={{ span: 24 }}
          initialValues={data}
          autoComplete="off"
        >
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={1}
                heading={"PIANOI REF"}
                name="pia_noi_ref"
                placeholder={"Enter pianoi ref of your Project here"}
                message={"Please enter pianoi ref"}
                star={true}
                // description={"Enter Exchange of your Project"}
                // onChange={(value) => handleFieldChange("exchange", value)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <DateField
                number={2}
                heading={"DATE EVIDANCE COLLECTED"}
                name="date_evidence_collected"
                message={"Please enter date evidance collected"}
                star={true}
                // description={"Enter the date you submitted this request"}
                // onChange={(value) => handleFieldChange("date", value)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={3}
                heading={"pole number"}
                name="pole_no"
                placeholder={"Enter pole number here"}
                message={"Please enter pole number"}
                star={true}
                // description={"Enter OR Reference of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={4}
                heading={"Exchange Area"}
                name="exchange"
                placeholder={"Enter exchange area here"}
                message={"Please enter exchange area"}
                star={true}
                // description={"Enter NOI of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={6}
                heading={"Eastings"}
                name="eastings"
                placeholder={"Enter eastings here"}
                message={"Please enter eastings"}
                star={true}
                // description={"Enter issue of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={6}
                heading={"Northings"}
                name="northings"
                placeholder={"Enter northings here"}
                message={"Please enter northings"}
                star={true}
                // description={"Enter issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={7}
                heading={"address and postcode"}
                name="address"
                placeholder={"Enter your Project address and postcode here"}
                message={"Please enter address and postcode"}
                star={true}
                // description={"Enter CP Name of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={8}
                heading={"Confirmed Pole Status"}
                name="pole_status"
                placeholder={"Enter your Project confirmed pole status here"}
                message={"Please enter confirmed pole status"}
                star={true}
                // description={"Enter ID pole of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* <TextField
                number={9}
                heading={"Existing Pole Size/Class: light/medium/stout"}
                name="pole_size"
                placeholder={"Enter Existing Pole Size of your Project here"}
                message={"Please enter Existing Pole Size"}
                star={true}
              /> */}
              <RadioField
                number={9}
                heading={"Existing Pole Size/Class:"}
                name="pole_size"
                placeholder={"Enter select Existing Pole Size"}
                message={"Please select Existing Pole Size"}
                star={true}
                radioData={["light", "medium", "stout"]}
                // description={
                //   "Indicate whether works will be on private property"
                // }
                // onChange={(value) => handleFieldChange("third_issue", value)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={10}
                heading={"Total Existing DWs"}
                name="existing_dw"
                placeholder={"Enter total existing DWs of your Project here"}
                message={"Please enter total existing DWs"}
                star={true}
                // description={"Enter Grid Reference of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={11}
                heading={"immediate forecast"}
                name="immediate_forecast"
                placeholder={"Enter your Project immediate forecast here"}
                message={"Please enter immediate forecast"}
                star={true}
                // description={"Enter Address of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={5}
                heading={"Issue"}
                name="issue"
                placeholder={"Enter issue here"}
                message={"Please enter issue"}
                star={true}
                // description={"Enter issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={13}
                heading={"Stay Fitted"}
                name="stay_fitted"
                placeholder={"Enter stay fitted here"}
                message={"Please enter stay fitted"}
                star={true}
                // description={"Enter DP ID of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={14}
                heading={"Dimensions of Equipment"}
                name="dimensions"
                placeholder={"Enter dimensions of equipment here"}
                message={"Please enter dimensions of equipment"}
                star={true}
                // description={"Enter second issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={15}
                heading={"Tree Cutting Required"}
                name="tree_cutting_required"
                placeholder={"Enter tree cutting required here"}
                message={"Please enter tree cutting required"}
                star={true}
                // description={"Enter Issue Note of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={16}
                heading={"Tree Cutting Completed to OR Standards"}
                name="tree_cutting_completed"
                placeholder={
                  "Enter tree cutting completed to OR standards here"
                }
                message={"Please enter tree cutting completed to OR standards"}
                star={true}
                // description={"Enter Furniture Issue of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={17}
                heading={"Leadin Job"}
                name="leadin_job"
                placeholder={"Enter Leadin job here"}
                message={"Please enter Leadin job"}
                star={true}
                // description={"Enter Forcost Wires of your Project"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextField
                number={18}
                heading={"Description/Notes"}
                name="description"
                placeholder={"Enter description/notes here"}
                message={"Please enter description/notes"}
                star={true}
                // description={"Enter Exciting Wires of your Project"}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <LocationField
                number={12}
                heading={"Location"}
                name1="post_code_lat"
                name2="post_code_long"
                placeholder1={"Enter latitude of blockage"}
                placeholder2={"Enter Longitude of blockage"}
                message1={"Please enter latitude"}
                message2={"Please enter Longitude"}
                // star={true}
                onClick={handleKeyPress}
                // description={"Enter details of blockage location"}
                // onChange1={(value) => handleFieldChange("post_code_lat", value)}
                // onChange2={(value) =>
                //   handleFieldChange("post_code_long", value)
                // }
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <RadioField
                number={19}
                heading={"all recommended avils avoidance completed? y/n"}
                name="civil_avoidance"
                placeholder={"Enter select one avils avoidance"}
                message={"Please select one avils avoidance"}
                star={true}
                radioData={["Yes", "No"]}
                // description={
                //   "Indicate whether works will be on private property"
                // }
                // onChange={(value) => handleFieldChange("third_issue", value)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={20}
                heading={"A55 Diagram"}
                description={"Please upload Picture for A55 diagram"}
                star={true}
                name={`pic1`}
                multiple={false}
                fileList={pic1}
                setFileList={setPic1}
                api={`/post_dpole_pic1/${pic1.length + 1}/${userID}`}
                response={pic1Response}
                setResponse={setPic1Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={21}
                heading={"DP/CP Pole Number"}
                description={"Please upload Picture for DP/CP Pole Number"}
                star={true}
                name={`pic2`}
                multiple={false}
                fileList={pic2}
                setFileList={setPic2}
                api={`/post_dpole_pic2/${pic2.length + 1}/${userID}`}
                response={pic2Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={22}
                heading={"Burn Markings"}
                description={"Please upload Picture for Burn Markings"}
                star={true}
                name={`pic3`}
                multiple={false}
                fileList={pic3}
                setFileList={setPic3}
                api={`/post_dpole_pic3/${pic3.length + 1}/${userID}`}
                response={pic3Response}
                setResponse={setPic3Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={23}
                heading={"Full Length Photo"}
                description={"Please upload Picture for Full Length Photo"}
                star={true}
                name={`pic4`}
                multiple={false}
                fileList={pic4}
                setFileList={setPic4}
                api={`/post_dpole_pic4/${pic4.length + 1}/${userID}`}
                response={pic4Response}
                setResponse={setPic4Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={24}
                heading={"Evidence Photo 1"}
                description={"Please upload Evidence Photo 1"}
                star={true}
                name={`pic5`}
                multiple={false}
                fileList={pic5}
                setFileList={setPic5}
                api={`/post_dpole_pic5/${pic5.length + 1}/${userID}`}
                response={pic5Response}
                setResponse={setPic5Response}
                message={
                  "File number limit: 1     Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic1", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={25}
                heading={"Evidence Photo 2"}
                description={"Please upload Evidence Photo 2"}
                star={true}
                name={`pic6`}
                multiple={false}
                fileList={pic6}
                setFileList={setPic6}
                api={`/post_dpole_pic6/${pic6.length + 1}/${userID}`}
                response={pic6Response}
                setResponse={setPic2Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic2", fileList)}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} style={{ marginBottom: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={26}
                heading={"Evidence Photo 3"}
                description={"Please upload Evidence Photo 3"}
                star={true}
                name={`pic7`}
                multiple={false}
                fileList={pic7}
                setFileList={setPic7}
                api={`/post_dpole_pic7/${pic7.length + 1}/${userID}`}
                response={pic7Response}
                setResponse={setPic7Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic3", fileList)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <ImageField
                number={27}
                heading={"Evidence Photo 4"}
                description={"Please upload Evidence Photo 4"}
                star={true}
                name={`pic8`}
                multiple={false}
                fileList={pic8}
                setFileList={setPic8}
                api={`/post_dpole_pic8/${pic8.length + 1}/${userID}`}
                response={pic8Response}
                setResponse={setPic8Response}
                message={
                  "File number limit: 1      Single file size limit: 1GB      Allowed file types: PNG,JPG "
                }
                image={Picture2}
                setSpin={setSpin}
                maxCount={1}
                // onChange={(fileList) => handleFieldChange("pic4", fileList)}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginRight: "30px" }}
            >
              <NextButton>
                <BackButton
                  // onClick={() => {
                  //   console.log("hello");
                  //   navigate(data["link"]);
                  // }}
                  onClick={handleGoBack}
                >
                  <BackButtonText>Back</BackButtonText>
                </BackButton>
                <Form.Item wrapperCol={{}}>
                  <Button
                    // onClick={() => {
                    //     console.log(formData, "formData");
                    //   navigate(data["link"]);
                    // }}
                    className="next_btn_network"
                    // onClick={nextStep}
                    type="primary"
                    size={"large"}
                    htmlType="submit"
                    style={{ minWidth: 170, height: 55, borderRadius: 5 }}
                  >
                    Next
                  </Button>
                </Form.Item>
              </NextButton>
            </Col>
          </Row>
        </Form>
        <Modal visible={spin} footer={false} closable={false} centered={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        </Modal>
        <Modal
          visible={success}
          footer={false}
          closable={false}
          bodyStyle={{ backgroundColor: "#fff" }}
        >
          <div className="i-modal-box">
            <img src={tick} width="200px" />
            <div className="i-modal-head-text">{response}</div>
            <div className="i-modal-text">Thank you for filling the form</div>
            <Link to="/SelectForm" id="sf-link-tag">
              <button id="i-done-btn">Done</button>
            </Link>
          </div>
        </Modal>
      </StyledBody>
    </>
  );
};

export default DPoleForm;
