import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Upload,
} from "antd";
import "../../css/SiteForm.css";
import logo from "../../image/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../css/CableForm.css";
import comments from "../../image/Comments.png";
import line from "../../image/Line.png";
import satview from "../../image/Satview.png";
import APXview from "../../image/Apxview.png";
import chamber from "../../image/Cham1.jpg";
import { UploadOutlined } from "@ant-design/icons";
import "../../css/Measurement.css";
import "../../css/ImageForm.css";
import tick from "../../image/animation.gif";
import axios, { baseUrl, header, removeStorage } from "../../utils/axios";
import Swal from "sweetalert2";
import { DragFlex, DragText } from "./styles/main.styles";
import imp from "./Images/import.svg";

function SiteForm() {
  const navigate = useNavigate();
  const openSweetAlert = (title, type) => {
    Swal.fire({
      title,
      type,
      allowOutsideClick: false,
      confirmButtonColor: "#66B127",
    });
  };
  const d1 = new Date();
  const [startTime, setTime] = useState([0, 0, 0]);
  useEffect(() => {
    setTime([d1.getHours(), d1.getMinutes(), d1.getSeconds()]);
  }, []);
  const [respID, setRespID] = useState(-1);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [site, setSite] = useState("");
  const [cluster, setCluster] = useState("");
  const [workRequired, setWorkRequired] = useState("Ancillary");
  const [surfaceRadio, setSurfaceRadio] = useState("Tarmac");
  const [surfaceCheck, setSurfaceCheck] = useState(false);
  const [prvtProperty, setPrvtProperty] = useState("Yes");
  const [trafficLight, setTrafficLight] = useState("Survey Required");
  const [percentage, setPercentage] = useState("0-50%");
  const [ductType, setDuctType] = useState("PVC");
  const [measurementType, setMeasurementType] = useState("soft");
  const [exchange, setExchange] = useState("");
  const [onsiteEngineer, setOnsiteEngineer] = useState("");
  const [telephoneNum, setTelephoneNum] = useState("");
  const [PIAOrderNo, setPIAOrderNo] = useState("");
  const [myDate, setmyDate] = useState("");
  const [surface, setSurface] = useState([]);
  const [localAuthority, setLocalAuthority] = useState("");
  const [CP_DFE_REF, setCP_DFE_REF] = useState("");
  const [O_S_Ref, setO_S_Ref] = useState("");

  const [No_Of_Cables, setNo_Of_Cables] = useState("");
  const [Proposed_Cable, setProposed_Cable] = useState("");
  const [Sub_Duct_Diameter, setSub_Duct_Diameter] = useState("");
  const [Duct_Section_No, setDuct_Section_No] = useState("");

  const [Comments, setComments] = useState("");
  const [Box_A_Type, setBox_A_Type] = useState("");
  const [Box_B_Type, setBox_B_Type] = useState("");
  const [Bore, setBore] = useState("");

  const [L1_Soft, setL1_Soft] = useState(0);
  const [L1_Softlength, setL1_Softlength] = useState("Meter");
  const [L1_footway, setL1_footway] = useState(0);
  const [L1_footwayLength, setL1_footwayLength] = useState("Meter");

  const [L1_Carriageway, setL1_Carriageway] = useState(0);
  const [L1_CarriagewayLength, setL1_CarriagewayLength] = useState("Meter");
  const [L2_Soft, setL2_Soft] = useState(0);
  const [L2_Softlength, setL2_Softlength] = useState("Meter");

  const [L2_footway, setL2_footway] = useState(0);
  const [L2_footwayLength, setL2_footwayLength] = useState("Meter");

  const [L2_Carriageway, setL2_Carriageway] = useState(0);
  const [L2_CarriagewayLength, setL2_CarriagewayLength] = useState("Meter");

  const [L3_Soft, setL3_Soft] = useState(0);
  const [L3_Softlength, setL3_Softlength] = useState("Meter");

  const [L3_footway, setL3_footway] = useState(0);
  const [L3_footwayLength, setL3_footwayLength] = useState("Meter");

  const [L3_Carriageway, setL3_Carriageway] = useState(0);
  const [L3_CarriagewayLength, setL3_CarriagewayLength] = useState("Meter");

  const [Chamber_1_No, setChamber_1_No] = useState("");
  const [Chamber_2_No, setChamber_2_No] = useState("");
  const [picOne, setPicOne] = useState([]); // 1
  const [picTwo, setPicTwo] = useState([]); // 1
  const [picThird, setPicThird] = useState([]); // 4
  const [picFourth, setPicFourth] = useState([]); // 4
  const [link, setLink] = useState("/SelectArea");
  const [model, showModel] = useState(false);
  console.log(ch1, "ch11");

  useEffect(() => {
    const type = localStorage.getItem("A55_Portal_type");
    if (type === "Admin") {
      showModel(true);
      const resp_id = localStorage.getItem("resp_id");
      setLink("/FormFile");
      axios
        .get(baseUrl + "/get_a55_record_edit?resp_id=" + resp_id, header)
        .then((res) => {
          const data = res.data;
          setRespID(resp_id);
          setComments(data["blockageComments"]);
          setBore(data["bore"]);
          setBox_A_Type(data["box_A_type"]);
          setBox_B_Type(data["box_B_type"]);
          setChamber_1_No(data["chamber1"]);
          setChamber_2_No(data["chamber2"]);
          setCluster(data["cluster"]);
          setCP_DFE_REF(data["cp_def_ref"]);
          var now = new Date(data["date"]);
          var d_y = ("0" + now.getDate()).slice(-2);
          var mh = ("0" + (now.getMonth() + 1)).slice(-2);
          var td = now.getFullYear() + "-" + mh + "-" + d_y;
          setmyDate(td);
          setDuct_Section_No(data["duct_section_no"]);
          setDuctType(data["duct_type"]);
          setExchange(data["exchange"]);
          if (data["l1_soft"] !== "0") {
            setMeasurementType("soft");
          } else if (data["l1_footway"] !== "0") {
            setMeasurementType("footway");
          } else setMeasurementType("carriageway");
          setL1_Carriageway(data["l1_carriageway"]);
          setL1_footway(data["l1_footway"]);
          setL1_Soft(data["l1_soft"]);
          setL2_Carriageway(data["l2_carriageway"]);
          setL2_footway(data["l2_footway"]);
          setL2_Soft(data["l2_soft"]);
          setL3_Carriageway(data["l3_carriageway"]);
          setL3_footway(data["l3_footway"]);
          setL3_Soft(data["l3_soft"]);
          setLocalAuthority(data["local_authority"]);
          setNo_Of_Cables(data["no_of_cables_in_bore"]);
          setOnsiteEngineer(data["onsite_eng"]);
          setO_S_Ref(data["os_ref"]);
          setPercentage(data["percentage_bore_full"]);
          setPIAOrderNo(data["pia_order_no"]);
          setPrvtProperty(data["private_property"]);
          setProposed_Cable(data["proposed_cable"]);
          setSub_Duct_Diameter(data["proposed_sub_duct_diameter"]);
          setSite(data["site"]);
          const sur = data["surface"];
          setSurfaceRadio(sur[0]);
          setSurfaceCheck(sur[1] === "new");
          setTelephoneNum(data["telephone_no"]);
          setTrafficLight(data["traffic_light"]);
          setWorkRequired(data["work_required"]);
          setPicOne(data["google_sat"]);
          setApx(data["google_sat"][0].key);
          setPicTwo(data["apx"]);
          setGis(data["apx"][0].key);
          setPicThird(data["pic3"]);
          setCH1([
            data["pic3"][0].key,
            data["pic3"][1].key,
            data["pic3"][2].key,
            data["pic3"][3].key,
          ]);
          setPicFourth(data["pic4"]);
          setCH1([
            data["pic4"][0].key,
            // data.ch_2_img_1,
            // data.ch_2_img_2,
            // data.ch_2_img_3,
            // data.ch_2_img_4

            data["pic4"][1].key,
            data["pic4"][2].key,
            data["pic4"][3].key,
          ]);
          setLat(data["lat"]);
          setLng(data["lng"]);
          showModel(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
          showModel(false);
        });
    } else {
      setSite(localStorage.getItem("siteSelectedValue"));
      setCluster(localStorage.getItem("selectedValue"));
    }
  }, []);

  const step1Check = () => {
    return (
      site !== "" &&
      cluster !== "" &&
      lat !== "" &&
      lng !== "" &&
      exchange !== "" &&
      onsiteEngineer !== "" &&
      telephoneNum !== "" &&
      PIAOrderNo !== "" &&
      myDate !== "" &&
      localAuthority !== ""
    );
  };

  const { Dragger } = Upload;

  const [apx, setApx] = useState("");
  const apx_props = {
    name: "apx_view",
    multiple: false,
    fileList: picOne,
    listType: "picture",
    action: baseUrl + "/post_a55_apx",
    onChange(info) {
      const { status } = info.file;
      let newFileList = [...info.fileList];
      setPicOne(newFileList);
      if (status !== "uploading") {
        setApx(info.file.response);
      }
      if (status === "done") {
      } else if (status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const [gis, setGis] = useState("");
  const gis_props = {
    name: "gis_view",
    multiple: false,
    fileList: picTwo,
    listType: "picture",
    action: baseUrl + "/post_a55_gis",
    onChange(info) {
      const { status } = info.file;
      let newFileList = [...info.fileList];
      setPicTwo(newFileList);
      if (status !== "uploading") {
        setGis(info.file.response);
      }
      if (status === "done") {
      } else if (status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const [ch1, setCH1] = useState(["", "", "", ""]);
  console.log(ch1, "ch 1");
  const ch1_props = {
    name: "ch1_view",
    multiple: false,
    maxCount: 4,
    fileList: picThird,
    listType: "picture",
    action: baseUrl + `/post_a55_ch1/${picThird.length + 1}`,
    onChange(info) {
      const { status } = info.file;
      let newFileList = [...info.fileList];
      setPicThird(newFileList);
      if (status !== "uploading") {
        ch1[picThird.length - 1] = info.file.response;
        setCH1(ch1);
      } else if (status === "error") {
      }
    },
    onDrop(e) {
      setCH1(["", "", "", ""]);
      setPicThird([]);
    },
  };

  const [ch2, setCH2] = useState(["", "", "", ""]);
  const ch2_props = {
    name: "ch2_view",
    multiple: false,
    maxCount: 4,
    fileList: picFourth,
    listType: "picture",
    action: baseUrl + `/post_a55_ch2/${picFourth.length + 1}`,
    onChange(info) {
      const { status } = info.file;
      let newFileList = [...info.fileList];
      setPicFourth(newFileList);
      if (status !== "uploading") {
        ch2[picFourth.length - 1] = info.file.response;
        setCH2(ch2);
      } else if (status === "error") {
      }
    },
    onDrop(e) {
      setCH1(["", "", "", ""]);
      setPicFourth([]);
    },
  };

  const handlePicOneUpload = ({ fileList }) => {
    setPicOne(fileList);
  };

  const handlePicTwoUpload = ({ fileList }) => {
    setPicTwo(fileList);
  };
  const handlePicThirdUpload = ({ fileList }) => {
    setPicThird(fileList);
  };
  const handlePicFourthUpload = ({ fileList }) => {
    setPicFourth(fileList);
  };

  const onChangeMeasurementType = (e) => {
    switch (measurementType) {
      case "soft":
        setL1_footway(0);
        setL2_footway(0);
        setL3_footway(0);

        setL1_footwayLength("Meter");
        setL2_footwayLength("Meter");
        setL3_footwayLength("Meter");

        setL1_Carriageway(0);
        setL2_Carriageway(0);
        setL3_Carriageway(0);

        setL1_CarriagewayLength("Meter");
        setL2_CarriagewayLength("Meter");
        setL3_CarriagewayLength("Meter");

      case "footway":
        setL1_Soft(0);
        setL2_Soft(0);
        setL3_Soft(0);

        setL1_Softlength("Meter");
        setL2_Softlength("Meter");
        setL3_Softlength("Meter");

        setL1_Carriageway(0);
        setL2_Carriageway(0);
        setL3_Carriageway(0);

        setL1_CarriagewayLength("Meter");
        setL2_CarriagewayLength("Meter");
        setL3_CarriagewayLength("Meter");

      case "carriageway":
        setL1_Soft(0);
        setL2_Soft(0);
        setL3_Soft(0);

        setL1_Softlength("Meter");
        setL2_Softlength("Meter");
        setL3_Softlength("Meter");

        setL1_footway(0);
        setL2_footway(0);
        setL3_footway(0);

        setL1_footwayLength("Meter");
        setL2_footwayLength("Meter");
        setL3_footwayLength("Meter");

      default:
        break;
    }
    setMeasurementType(e.target.value);
  };

  const handleUpload = async () => {
    if (picThird.length !== 4 || picFourth.length !== 4) {
      openSweetAlert(
        "Photo chamber 1 and photo chamber 2 should have 4 images",
        "error"
      );
    } else if (!step4Check()) {
      openSweetAlert("Fill form first", "error");
    } else {
      setIsModalVisible(true);
      const userid = localStorage.getItem("A55_Portal");
      const d2 = new Date();
      const data = {
        start_time: startTime,
        end_time: [d2.getHours(), d2.getMinutes(), d2.getSeconds()],
        user_id: userid,
        site: site,
        area: cluster,
        work_required: workRequired,
        exchange: exchange,
        onsite_eng: onsiteEngineer,
        telephone_no: telephoneNum,
        pia_order_no: PIAOrderNo,
        date: myDate,
        local_authority: localAuthority,
        surface: [surfaceRadio, surfaceCheck ? 1 : 0],
        cp_def_ref: CP_DFE_REF,
        os_ref: O_S_Ref,
        private_property: prvtProperty,
        traffic_light: trafficLight,
        percentage_bore_full: percentage,
        no_of_cables_in_bore: No_Of_Cables,
        proposed_cable: Proposed_Cable,
        proposed_sub_duct_diameter: Sub_Duct_Diameter,
        duct_section_no: Duct_Section_No,
        duct_type: ductType,
        blockageComments: Comments,
        box_A_type: Box_A_Type,
        box_B_type: Box_B_Type,
        bore: Bore,
        l1_soft: L1_Soft,
        l1_soft_type: L1_Softlength,
        l2_soft: L2_Soft,
        l2_soft_type: L2_Softlength,
        l3_soft: L3_Soft,
        l3_soft_type: L3_Softlength,
        l1_footway: L1_footway,
        l1_footway_type: L1_footwayLength,
        l2_footway: L2_footway,
        l2_footway_type: L2_footwayLength,
        l3_footway: L3_footway,
        l3_footway_type: L3_footwayLength,
        l1_carriageway: L1_Carriageway,
        l1_Carriageway_type: L1_CarriagewayLength,
        l2_carriageway: L2_Carriageway,
        l2_Carriageway_type: L2_CarriagewayLength,
        l3_carriageway: L3_Carriageway,
        l3_Carriageway_type: L3_CarriagewayLength,
        chamber1: Chamber_1_No,
        chamber2: Chamber_2_No,
        // post_code_lat: lat,
        lat: lat,
        // post_code_long: lng,
        lang: lng,
        apx_view: apx,
        googleSatellite: gis,
        ch_1_img_1: ch1[0],
        ch_1_img_2: ch1[1],
        ch_1_img_3: ch1[2],
        ch_1_img_4: ch1[3],
        ch_2_img_1: ch2[0],
        ch_2_img_2: ch2[1],
        ch_2_img_3: ch2[2],
        ch_2_img_4: ch2[3],
      };
      if (respID !== -1) {
        data["resp_id"] = respID;
      }

      await axios
        .post(baseUrl + "/post_a55_resp", data)
        .then((res) => {})
        .catch((err) => {
          if (err.response.status === 401) {
            removeStorage();
            navigate("/login");
          }
        });
    }
  };

  const onChangeWorkReq = (e) => {
    setWorkRequired(e.target.value);
  };
  const onChangeSurfaceRadio = (e) => {
    setSurfaceRadio(e.target.value);
  };
  const onChangePrvtProperty = (e) => {
    setPrvtProperty(e.target.value);
  };
  const onChangePercentage = (e) => {
    setPercentage(e.target.value);
  };
  const onChangeDuctType = (e) => {
    setDuctType(e.target.value);
  };
  const onChangeTrafficLight = (e) => {
    setTrafficLight(e.target.value);
  };

  const { Step } = Steps;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = async (e) => {
    e.preventDefault();
    await handleUpload();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrent((prev) => prev + 1);
  };
  const [current, setCurrent] = useState(0);
  const step2Check = () => {
    return (
      CP_DFE_REF !== "" &&
      O_S_Ref !== "" &&
      No_Of_Cables !== "" &&
      Proposed_Cable !== "" &&
      Sub_Duct_Diameter !== "" &&
      Duct_Section_No !== "" &&
      Comments !== ""
    );
  };
  const step3Check = () => {
    return Box_A_Type !== "" && Box_B_Type !== "" && Bore !== "";
  };

  const step4Check = () => {
    return (
      picOne.length !== 0 &&
      picTwo.length !== 0 &&
      Chamber_1_No !== "" &&
      Chamber_2_No !== ""
    );
  };
  const onChange = (cur) => {
    if (current === 0) {
      if (step1Check() && cur === 1) {
        setCurrent(cur);
        window.scrollTo(0, 0);
      }
    } else if (current === 1) {
      if (cur < current) {
        setCurrent(cur);
        window.scrollTo(0, 0);
      } else if (step2Check() && cur === 2) {
        setCurrent(cur);
        window.scrollTo(0, 0);
      }
    } else if (current === 2) {
      if (cur < current) {
        setCurrent(cur);
        window.scrollTo(0, 0);
      } else if (step3Check()) {
        setCurrent(cur);
        window.scrollTo(0, 0);
      }
    } else {
      setCurrent(cur);
      window.scrollTo(0, 0);
    }
  };
  const handleSteps = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);
  return (
    <div
      style={{ paddingLeft: "25px", paddingRight: "25px" }}
      className="s-main"
    >
      <div className="s-header-section">
        <div className="s-Netslogo">
          <img src={logo} width="110px" height="63px" />
        </div>
        <div className="s-heading-1" id={"scrollup"}>
          A55 Blockage Forms
        </div>
        <div className="s-heading-2">Please fill in one form per blockage</div>
        <div className="s-step">
          <Steps
            current={current}
            onChange={onChange}
            labelPlacement="vertical"
            style={{ color: "green" }}
            size="large"
          >
            <Step title="Site" />
            <Step title="Cabling" />
            <Step title="Measurement" />
            <Step title="Images" />
          </Steps>
        </div>
      </div>
      {current === 0 ? (
        <div className="s-form-section">
          <form onSubmit={handleSubmit}>
            <div className="s-row-1">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">1</div>
                    </div>
                    <div className="s-question">Site</div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="s-input-fields"
                    >
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Site"
                        value={site}
                        readOnly
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">2</div>
                    </div>
                    <div className="s-question">Cluster</div>
                    <div className="s-input-fields">
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Cluster"
                        value={cluster}
                        readOnly
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* ---------------------------------------------------------- */}
            <div style={{ marginTop: "15px" }}>
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">3</div>
                    </div>
                    <div className="s-question">
                      Latitude "range = [-90 to 90]"&nbsp;
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="s-input-fields"
                    >
                      <input
                        id="s-in"
                        type="number"
                        placeholder="Latitude"
                        value={lat}
                        onChange={(e) => {
                          if (
                            parseInt(e.target.value) > -90 &&
                            parseInt(e.target.value) < 90
                          )
                            setLat(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">4</div>
                    </div>
                    <div className="s-question">
                      Longitude "range = [-180 to 180]"&nbsp;
                    </div>
                    <div className="s-input-fields">
                      <input
                        id="s-in"
                        type="number"
                        step="0.01"
                        placeholder="Longitude"
                        value={lng}
                        onChange={(e) => setLng(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <br />
            <div className="s-row-2">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">5</div>
                    </div>
                    <div className="s-question">Work Required</div>
                    <div className="s-description">
                      Select whether this A55 is for Ancillary works or duct
                      Blockage
                    </div>
                    <Radio.Group
                      style={{ marginTop: "15px" }}
                      onChange={onChangeWorkReq}
                      value={workRequired}
                    >
                      <Row>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Ancillary"
                          >
                            Ancillary
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginLeft: "5px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Blockage"
                          >
                            Blockage
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">6</div>
                    </div>
                    <div className="s-question">Exchange</div>
                    <div className="s-description">Enter exchange name</div>
                    <div className="s-input-fields">
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={exchange}
                        onChange={(e) => {
                          setExchange(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <br />
            <div className="s-row-3">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">7</div>
                    </div>
                    <div className="s-question">Onsite Engineer</div>
                    <div className="s-description">
                      Enter details of onsite engineer
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="s-input-fields"
                    >
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={onsiteEngineer}
                        onChange={(e) => {
                          setOnsiteEngineer(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">8</div>
                    </div>
                    <div className="s-question">Telephone No</div>
                    <div className="s-description">
                      Enter onsite engineers telephone number
                    </div>
                    <div className="s-input-fields">
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={telephoneNum}
                        onChange={(e) => {
                          setTelephoneNum(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <br />
            <div className="s-row-4">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">9</div>
                    </div>
                    <div className="s-question">PIA Order No</div>
                    <div className="s-description">
                      Enter the OpenReach Duct and Pole Sharing order number
                      that this request refers to.
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="s-input-fields"
                    >
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={PIAOrderNo}
                        onChange={(e) => {
                          setPIAOrderNo(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">10</div>
                    </div>
                    <div className="s-question">Date</div>
                    <div className="s-description">
                      Enter the date you submitted this request
                    </div>
                    <div className="s-input-fields">
                      <input
                        id="s-date-input"
                        type="date"
                        value={myDate}
                        onChange={(e) => {
                          setmyDate(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <br />
            <div className="s-row-5">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="s-question-number-div">
                      <div className="s-question-number">11</div>
                    </div>
                    <div className="s-question">Surface</div>
                    <div className="s-description">
                      Please enter surface type eg F/W Slabs or C/W Tarmac etc.
                      Please also tick the "Looks New" box if the surface looks
                      new laid in past 12 months as this may raise potential
                      noticing issues.
                    </div>
                    <Radio.Group
                      style={{ marginTop: "15px" }}
                      onChange={onChangeSurfaceRadio}
                      value={surfaceRadio}
                    >
                      <Row style={{ marginBottom: "10px" }}>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Tarmac"
                          >
                            Tarmac
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Footway Slabs"
                          >
                            Footway Slabs
                          </Radio>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Carriageway"
                          >
                            Carriageway
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio
                            style={{
                              width: "155px",
                              marginLeft: "5px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Soft"
                          >
                            Soft
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                    <Row>
                      <Col span={24}>
                        <Checkbox
                          checked={surfaceCheck}
                          style={{
                            // width: "150px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "5px",
                          }}
                          onChange={(e) => setSurfaceCheck(e.target.checked)}
                        >
                          Looks New (Tick if surface is new)
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-2">
                    <div className="s-question-number-div">
                      <div className="s-question-number">12</div>
                    </div>
                    <div className="s-question">Local Authority</div>
                    <div className="s-description">
                      Enter details of the Local Authority/Council
                    </div>
                    <div className="s-input-fields">
                      <input
                        id="s-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={localAuthority}
                        onChange={(e) => {
                          setLocalAuthority(e.target.value);
                        }}
                        required={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="s-row-6">
              <div className="s-button-flex">
                <Link id="s-link-tag" to={link}>
                  <div className="s-back-button">
                    <div className="s-back-text">Back</div>
                  </div>
                </Link>
                <button type="submit" className="s-next-button">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : null}
      {current === 1 ? (
        <div style={{ margin: "0px" }} className="form-section">
          <form onSubmit={handleSubmit}>
            <div className="row-1">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number">1</div>
                    </div>
                    <div className="question">CP DFE Ref</div>
                    <div className="description">
                      This field only applies if a DFE has been instigated.
                      Enter the CPs authorisation ref if know else enter N/A
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="input-fields"
                    >
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={CP_DFE_REF}
                        onChange={(e) => {
                          setCP_DFE_REF(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-2">
                    <div className="question-number-div">
                      <div className="question-number">2</div>
                    </div>
                    <div className="question">O/S Ref</div>
                    <div className="description">
                      Enter the Ordnance Survey reference if known else enter
                      N/A
                    </div>
                    <div className="input-fields">
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={O_S_Ref}
                        onChange={(e) => {
                          setO_S_Ref(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("O_S_Ref", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="row-2">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number">3</div>
                    </div>
                    <div className="question">Private Property</div>
                    <div className="description">
                      Indicate whether works will be on private property
                    </div>
                    <Radio.Group
                      onChange={onChangePrvtProperty}
                      value={prvtProperty}
                    >
                      <Row>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Yes"
                          >
                            Yes
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px", // marginLeft: "5px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="No"
                          >
                            No
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "170px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Don't Know"
                          >
                            Don't Know
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-2">
                    <div className="question-number-div">
                      <div className="question-number">4</div>
                    </div>
                    <div className="question">Traffic Lights</div>
                    <div className="description">
                      Indicate whether traffic lights will be required while
                      completing the works
                    </div>

                    <Radio.Group
                      onChange={onChangeTrafficLight}
                      value={trafficLight}
                    >
                      <Row>
                        <Col span={6}>
                          <Radio
                            style={{
                              width: "145px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Survey Required"
                          >
                            Survey Required
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio
                            style={{
                              width: "115px",
                              marginLeft: "20px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="2 Way"
                          >
                            2 Way
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio
                            style={{
                              width: "115px",
                              marginLeft: "10px",

                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="3 Way"
                          >
                            3 Way
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio
                            style={{
                              width: "115px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="None"
                          >
                            None
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="row-3">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number">5</div>
                    </div>
                    <div className="question">Percentage Bore Full</div>

                    <Radio.Group
                      style={{ marginTop: "15px" }}
                      onChange={onChangePercentage}
                      value={percentage}
                    >
                      <Row>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="0-50%"
                          >
                            0-50%
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px", // marginLeft: "5px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="51-90%"
                          >
                            51-90%
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "170px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="91-100%"
                          >
                            91-100%
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                    {/* <div className="box">
                      <div className="box-1">
                        <div className="Radio-div">
                          <input id="radio-input" type="radio" />
                        </div>
                        <div className="radio-text-div">0-50%</div>
                      </div>
                      <div className="box-2">
                        <div className="Radio-div">
                          <input id="radio-input" type="radio" />
                        </div>
                        <div className="radio-text-div">51%-91%</div>
                      </div>
                      <div className="box-3">
                        <div className="Radio-div">
                          <input id="radio-input" type="radio" />
                        </div>
                        <div className="radio-text-div">91%-100%</div>
                      </div>
                    </div> */}
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-2">
                    <div className="question-number-div">
                      <div className="question-number">6</div>
                    </div>
                    <div className="question">No Of Cables in Bore</div>
                    <div className="input-fields">
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={No_Of_Cables}
                        onChange={(e) => {
                          setNo_Of_Cables(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("No_Of_Cables", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="row-4">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number">7</div>
                    </div>
                    <div className="question">Proposed Cable</div>
                    <div className="description">Cable type eg 50/0.5 PET</div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="input-fields"
                    >
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Proposed_Cable}
                        onChange={(e) => {
                          setProposed_Cable(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("Proposed_Cable", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-2">
                    <div className="question-number-div">
                      <div className="question-number">8</div>
                    </div>
                    <div className="question">Proposed Sub Duct Diameter</div>
                    <div className="description">
                      Please provide Diameter in 'millimeters'
                    </div>
                    <div className="input-fields">
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Sub_Duct_Diameter}
                        onChange={(e) => {
                          setSub_Duct_Diameter(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("Sub-Duct_Diameter", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="row-5">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number">9</div>
                    </div>
                    <div className="question">Duct Section No</div>
                    <div className="input-fields">
                      <input
                        id="in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Duct_Section_No}
                        onChange={(e) => {
                          setDuct_Section_No(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("Duct_Section_No", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="ques-2">
                    <div className="question-number-div">
                      <div className="question-number-double">10</div>
                    </div>
                    <div className="question">Duct Type</div>
                    <div className="description">
                      Indicate whether works will be on which Duct Type
                    </div>
                    <Radio.Group
                      style={{ marginTop: "15px" }}
                      onChange={onChangeDuctType}
                      value={ductType}
                    >
                      <Row>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="PVC"
                          >
                            PVC
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "155px", // marginLeft: "5px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Steel"
                          >
                            Steel
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio
                            style={{
                              width: "170px",
                              marginRight: "5px",
                              borderRadius: "8px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                              padding: "5px",
                            }}
                            value="Other"
                          >
                            Other
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="row-6">
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <div className="ques-1">
                    <div className="question-number-div">
                      <div className="question-number-double">11</div>
                    </div>
                    <div className="question">Blockage Comments</div>
                    <div className="comment-desc">
                      Please insert the nearest address to the location and
                      provide coordinates of the Blockage point from APX. Please
                      see the example photo for the format.
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", marginTop: "55px" }}
                    className="comment-area"
                  >
                    <textarea
                      rows={5}
                      type="text"
                      id="ta"
                      placeholder="Enter your answer"
                      value={Comments}
                      onChange={(e) => {
                        setComments(e.target.value);
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <div style={{ float: "Right" }}>
                    <img src={comments} width="240px" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={24}>
                  <div style={{ float: "right" }} className="button-flex">
                    <div
                      className="back-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrent((prev) => prev - 1);
                      }}
                    >
                      <div className="back-text">
                        <a style={{ textDecoration: "none", color: "#7FBE42" }}>
                          Back
                        </a>
                      </div>
                    </div>

                    <button type="submit" className="s-next-button">
                      Next
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      ) : null}
      {current === 2 ? (
        <div className="m-form-section">
          <form onSubmit={handleSubmit}>
            <div className="m-row-1">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="m-ques-1">
                    <div className="m-question-number-div">
                      <div className="m-question-number">1</div>
                    </div>
                    <div className="m-question">Box A - type</div>
                    <div className="m-description">
                      Insert box type eg JUF 4 or JRC 12 if duct terminates
                      above ground with a duct bend insert Pole , BLDG or CAB as
                      appropriate.
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="m-input-fields"
                    >
                      <input
                        id="m-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Box_A_Type}
                        onChange={(e) => {
                          setBox_A_Type(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("Box_A_Type", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="m-ques-2">
                    <div className="m-question-number-div">
                      <div className="m-question-number">2</div>
                    </div>
                    <div className="m-question">Box B - Type</div>
                    <div className="m-description">
                      Insert box type eg JUF 4 or JRC 12 if duct terminates
                      above ground with a duct bend insert Pole , BLDG or CAB as
                      appropriate.
                    </div>
                    <div className="m-input-fields">
                      <input
                        id="m-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Box_B_Type}
                        onChange={(e) => {
                          setBox_B_Type(e.target.value);
                        }}
                        // onChange={(e) =>
                        //   handleInputs("Box_B_Type", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="m-row-2">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="m-ques-1">
                    <div className="m-question-number-div">
                      <div className="m-question-number">3</div>
                    </div>
                    <div className="m-question">Bore</div>
                    <div className="m-description">
                      Insert box type eg JUF 4 or JRC 12 if duct terminates
                      above ground with a duct bend insert Pole , BLDG or CAB as
                      appropriate.
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="m-input-fields"
                    >
                      <input
                        id="m-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Bore}
                        onChange={(e) => {
                          setBore(e.target.value);
                        }}
                        // onChange={(e) => handleInputs("Bore", e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="m-row-3">
              <Row>
                <Col xs={24} md={24} lg={24}>
                  <div className="m-ques-1">
                    <div className="m-question-number-div">
                      <div className="m-question-number">4</div>
                    </div>
                    <div className="m-question">Measurement Type</div>
                    {/* //---------------------------------------------------------------------- */}
                    <div>
                      <Radio.Group
                        style={{ marginTop: "15px" }}
                        onChange={onChangeMeasurementType}
                        value={measurementType}
                      >
                        <Row>
                          <Col span={8}>
                            <Radio
                              style={{
                                width: "155px",
                                marginRight: "5px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(0,0,0,0.1)",
                                padding: "5px",
                              }}
                              value="soft"
                            >
                              Soft
                            </Radio>
                          </Col>
                          <Col span={8}>
                            <Radio
                              style={{
                                width: "155px", // marginLeft: "5px",
                                marginRight: "5px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(0,0,0,0.1)",
                                padding: "5px",
                              }}
                              value="footway"
                            >
                              Footway
                            </Radio>
                          </Col>
                          <Col span={8}>
                            <Radio
                              style={{
                                width: "170px",
                                marginRight: "5px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(0,0,0,0.1)",
                                padding: "5px",
                              }}
                              value="carriageway"
                            >
                              Carriageway
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </div>
                    {/* //---------------------------------------------------------------------- */}
                    <div className="m-description-1">
                      Distance L1 is from first box/pole to blockage and is by
                      surface types.The total of these three surface types added
                      together give the section length, by entering by surface
                      type replacement cost can be estimated.
                    </div>
                    <div className="m-image">
                      <img src={line} width="100%" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="meas-inputs">
              <div className="m-row-2">
                <Row>
                  <Col xs={24} md={8} lg={8}>
                    <div style={{ marginRight: "15px" }}>
                      <div className="m-head-text">L1 Measurement</div>
                      {measurementType === "soft" ? (
                        <>
                          <div className="m-ques-1">
                            {" "}
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p className="numberBG">5</p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L1 Soft
                              </div>
                            </div>
                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L1_Soft}
                                required
                                onChange={(e) => {
                                  setL1_Soft(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L1_Softlength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL1_Softlength(value);
                                  }}
                                >
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>{" "}
                          </div>
                        </>
                      ) : null}

                      {measurementType === "footway" ? (
                        <>
                          <div className="m-ques-1">
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p className="numberBG">5</p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L1 Footway
                              </div>
                            </div>
                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L1_footway}
                                required
                                onChange={(e) => {
                                  setL1_footway(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L1_footwayLength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL1_footwayLength(value);
                                  }}
                                >
                                  {/* <select id="m-select"> */}
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {measurementType === "carriageway" ? (
                        <>
                          <div className="m-ques-1">
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p className="numberBG">5</p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L1 Carriageway
                              </div>
                            </div>

                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L1_Carriageway}
                                required
                                onChange={(e) => {
                                  setL1_Carriageway(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L1_CarriagewayLength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL1_CarriagewayLength(value);
                                  }}
                                >
                                  {/* <select id="m-select"> */}
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <div style={{ marginRight: "15px" }}>
                      <div className="m-ques-1-2">
                        <div className="m-head-text">L2 Measurement</div>
                      </div>

                      {measurementType === "soft" ? (
                        <>
                          <div className="m-ques-22">
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p className="numberBG">6</p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L2 Soft
                              </div>
                            </div>
                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L2_Soft}
                                required
                                onChange={(e) => {
                                  setL2_Soft(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L2_Softlength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL2_Softlength(value);
                                  }}
                                >
                                  {/* <select id="m-select"> */}
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {measurementType === "footway" ? (
                        <>
                          <div className="m-ques-22">
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p className="numberBG">6</p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L2 Footway
                              </div>
                            </div>
                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L2_footway}
                                required
                                onChange={(e) => {
                                  setL2_footway(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L2_footwayLength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL2_footwayLength(value);
                                  }}
                                >
                                  {/* <select id="m-select"> */}
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {measurementType === "carriageway" ? (
                        <>
                          <div className="m-ques-22">
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <p
                                className="numberBG"
                                style={{ padding: "3px" }}
                              >
                                6
                              </p>
                              <div
                                style={{ display: "flex", marginLeft: "15px" }}
                                className="m-question"
                              >
                                L2 Carriageway
                              </div>
                            </div>

                            <div className="m-description">
                              Measurement in 'Meters'
                            </div>
                            <div className="m-dropdown-fields">
                              <input
                                id="m-in-3"
                                type="number"
                                placeholder="Enter your answer"
                                value={L2_Carriageway}
                                required
                                onChange={(e) => {
                                  setL2_Carriageway(e.target.value);
                                }}
                              />
                              <div className="m-in-4">
                                <Select
                                  value={L2_CarriagewayLength}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    setL2_CarriagewayLength(value);
                                  }}
                                >
                                  {/* <select id="m-select"> */}
                                  <option value="Meter"> Meter</option>
                                  <option value="Centimeter">
                                    {" "}
                                    Centimeter
                                  </option>
                                  <option value="Inches"> Inches</option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <div className="m-ques-1-3">
                      <div className="m-head-text">L3 Measurement</div>
                    </div>

                    {measurementType === "soft" ? (
                      <>
                        <div className="m-ques-33">
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            <p className="numberBG" style={{ padding: "3px" }}>
                              7
                            </p>
                            <div
                              style={{ display: "flex", marginLeft: "15px" }}
                              className="m-question"
                            >
                              L3 Soft
                            </div>
                          </div>

                          <div className="m-description">
                            Measurement in 'Meters'
                          </div>
                          <div className="m-dropdown-fields">
                            <input
                              id="m-in-3"
                              type="number"
                              placeholder="Enter your answer"
                              value={L3_Soft}
                              required
                              onChange={(e) => {
                                setL3_Soft(e.target.value);
                              }}
                            />
                            <div className="m-in-4">
                              <Select
                                value={L3_Softlength}
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  setL3_Softlength(value);
                                }}
                              >
                                {/* <select id="m-select"> */}
                                <option value="Meter"> Meter</option>
                                <option value="Centimeter"> Centimeter</option>
                                <option value="Inches"> Inches</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {measurementType === "footway" ? (
                      <>
                        <div className="m-ques-33">
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            <p className="numberBG" style={{ padding: "3px" }}>
                              7
                            </p>
                            <div
                              style={{ display: "flex", marginLeft: "15px" }}
                              className="m-question"
                            >
                              L3 Footway
                            </div>
                          </div>

                          <div className="m-description">
                            Measurement in 'Meters'
                          </div>
                          <div className="m-dropdown-fields">
                            <input
                              id="m-in-3"
                              type="number"
                              placeholder="Enter your answer"
                              value={L3_footway}
                              required
                              onChange={(e) => {
                                setL3_footway(e.target.value);
                              }}
                            />
                            <div className="m-in-4">
                              <Select
                                value={L3_footwayLength}
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  setL3_footwayLength(value);
                                }}
                              >
                                {/* <select id="m-select"> */}
                                <option value="Meter"> Meter</option>
                                <option value="Centimeter"> Centimeter</option>
                                <option value="Inches"> Inches</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {measurementType === "carriageway" ? (
                      <>
                        <div className="m-ques-33">
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            <p className="numberBG" style={{ padding: "3px" }}>
                              7
                            </p>
                            <div
                              style={{ display: "flex", marginLeft: "15px" }}
                              className="m-question"
                            >
                              L3 Carriageway
                            </div>
                          </div>

                          <div className="m-description">
                            Measurement in 'Meters'
                          </div>
                          <div className="m-dropdown-fields">
                            <input
                              id="m-in-3"
                              type="number"
                              placeholder="Enter your answer"
                              value={L3_Carriageway}
                              required
                              onChange={(e) => {
                                setL3_Carriageway(e.target.value);
                              }}
                            />
                            <div className="m-in-4">
                              <Select
                                value={L3_CarriagewayLength}
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  setL3_CarriagewayLength(value);
                                }}
                              >
                                {/* <select id="m-select"> */}
                                <option value="Meter"> Meter</option>
                                <option value="Centimeter"> Centimeter</option>
                                <option value="Inches"> Inches</option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="m-row-6">
              <div className="m-button-flex">
                <div
                  className="m-back-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrent((prev) => prev - 1);
                  }}
                >
                  <div className="m-back-text">
                    <a
                      style={{ textDecoration: "none", color: "#7FBE42" }}
                      href=""
                    >
                      Back
                    </a>
                  </div>
                </div>

                <button type="submit" className="s-next-button">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : null}
      {current === 3 ? (
        <div className="i-form-section">
          <form onSubmit={showModal}>
            <div className="i-row-1">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div style={{ marginRight: "10px" }} className="s-ques-1">
                    <div className="i-question-number-div">
                      <div className="i-question-number">1</div>
                    </div>
                    <div className="i-question">
                      Google Satellite View Images
                    </div>
                    <div className="i-description">
                      Please upload the requested images
                    </div>
                    <div className="i-image-input">
                      <div style={{ padding: 0 }}>
                        <img
                          src={satview}
                          height="150px"
                          width="100%"
                          style={{ padding: 0 }}
                        />
                      </div>
                      <div className="i-upload-file-div">
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          size="large"
                        >
                          <Dragger {...apx_props} fileList={picOne}>
                            <DragFlex>
                              <img src={imp} />
                              <DragText>
                                {/* <p style={{ color: "black" }}>drag or</p> */}
                                <p style={{ color: " #5C8246" }}>
                                  {" "}
                                  Upload file
                                </p>
                              </DragText>
                            </DragFlex>
                          </Dragger>
                        </Space>
                      </div>
                      <div className="i-info-div">
                        <div className="i-info-text">File number limit: 1</div>
                        <div className="i-info-text">
                          Single file size limit: 1GB
                        </div>
                        <div className="i-info-text">
                          Allowed file types: PNG,JPG
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="s-ques-1">
                    <div className="i-question-number-div">
                      <div className="i-question-number">2</div>
                    </div>
                    <div className="i-question">APX View Images</div>
                    <div className="i-description">
                      Upload an image of the section from APX or take a photo of
                      your Laptop/Tablet screen of APX{" "}
                    </div>
                    <div className="i-image-input">
                      <div style={{ padding: 0 }}>
                        <img
                          src={APXview}
                          height="150px"
                          width="100%"
                          style={{ padding: 0 }}
                        />
                      </div>
                      <div className="i-upload-file-div">
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          size="large"
                        >
                          <Dragger {...gis_props} fileList={picTwo}>
                            <DragFlex>
                              <img src={imp} />
                              <DragText>
                                {/* <p style={{ color: "black" }}>drag or</p> */}
                                <p style={{ color: " #5C8246" }}>
                                  {" "}
                                  Upload file
                                </p>
                              </DragText>
                            </DragFlex>
                          </Dragger>
                        </Space>
                      </div>
                      <div className="i-info-div">
                        <div className="i-info-text">File number limit: 1</div>
                        <div className="i-info-text">
                          Single file size limit: 1GB
                        </div>
                        <div className="i-info-text">
                          Allowed file types: PNG,JPG
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="i-row-2">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="i-ques-1">
                    <div className="i-head-text">Chamber 1</div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="i-ques-2">
                    <div className="i-head-text">Chamber 2</div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="i-row-3">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div className="i-ques-1">
                    <div className="i-question-number-div">
                      <div className="i-question-number">3</div>
                    </div>
                    <div className="i-question">Chamber 1 Number</div>
                    <div className="i-description">
                      Please insert the Chamber Number/ID e.g.JC:JUF:T6203091-
                      1271
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      className="i-input-fields"
                    >
                      <input
                        id="i-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Chamber_1_No}
                        onChange={(e) => setChamber_1_No(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="i-ques-2">
                    <div className="i-question-number-div">
                      <div className="question-number">4</div>
                    </div>
                    <div className="i-question">Chamber 2 Number</div>
                    <div className="i-description">
                      Please insert the Chamber Number/ID e.g.JC:JUF:T6203091-
                      1271
                    </div>
                    <div className="i-input-fields">
                      <input
                        id="i-in"
                        type="text"
                        placeholder="Enter your answer"
                        value={Chamber_2_No}
                        onChange={(e) => setChamber_2_No(e.target.value)}
                        // onChange={(e) =>
                        //   handleInputs("Chamber_2_No", e.target.value)
                        // }
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="i-row-4">
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <div style={{ marginRight: "10px" }} className="i-ques-1">
                    <div className="i-question-number-div">
                      <div className="i-question-number">5</div>
                    </div>
                    <div className="i-question">Chamber 1 Images</div>
                    <div className="i-description">
                      Please upload the requested images
                    </div>
                    <div className="i-image-input">
                      <div style={{ padding: 0 }}>
                        <img
                          src={chamber}
                          height="150px"
                          width="100%"
                          style={{ padding: 0 }}
                        />
                      </div>
                      <div className="i-upload-file-div">
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          size="large"
                        >
                          <Dragger {...ch1_props} fileList={picThird}>
                            <DragFlex>
                              <img src={imp} />
                              <DragText>
                                {/* <p style={{ color: "black" }}>drag or</p> */}
                                <p style={{ color: " #5C8246" }}>
                                  {" "}
                                  Upload file
                                </p>
                              </DragText>
                            </DragFlex>
                          </Dragger>
                        </Space>
                      </div>
                      <div className="i-info-div">
                        <div className="i-info-text">File number limit: 4</div>
                        <div className="i-info-text">
                          Single file size limit: 1GB
                        </div>
                        <div className="i-info-text">
                          Allowed file types: PNG,JPG
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="i-ques-2">
                    <div className="i-question-number-div">
                      <div className="i-question-number">6</div>
                    </div>
                    <div className="i-question">Chamber 2 Images</div>
                    <div className="i-description">
                      Please upload the requested images{" "}
                    </div>
                    <div className="i-image-input">
                      <div style={{ padding: 0 }}>
                        <img
                          src={chamber}
                          height="150px"
                          width="100%"
                          style={{ padding: 0 }}
                        />
                      </div>
                      <div className="i-upload-file-div">
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          size="large"
                        >
                          <Dragger {...ch2_props} fileList={picFourth}>
                            <DragFlex>
                              <img src={imp} />
                              <DragText>
                                {/* <p style={{ color: "black" }}>drag or</p> */}
                                <p style={{ color: " #5C8246" }}>
                                  {" "}
                                  Upload file
                                </p>
                              </DragText>
                            </DragFlex>
                          </Dragger>
                        </Space>
                      </div>
                      <div className="i-info-div">
                        <div className="i-info-text">File number limit: 4</div>
                        <div className="i-info-text">
                          Single file size limit: 1GB
                        </div>
                        <div className="i-info-text">
                          Allowed file types: PNG,JPG
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="i-row-6">
              <div className="i-button-flex">
                <div
                  className="i-back-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrent((prev) => prev - 1);
                  }}
                >
                  <div className="i-back-text">
                    <a
                      style={{ textDecoration: "none", color: "#7FBE42" }}
                      href=""
                    >
                      Back
                    </a>
                  </div>
                </div>
                <input id="n-submit-btn" type="submit" value="Submit" />
                <Modal
                  visible={isModalVisible}
                  footer={false}
                  closable={false}
                  bodyStyle={{ backgroundColor: "#fff" }}
                >
                  <div className="i-modal-box">
                    <img src={tick} width="200px" />
                    <div className="i-modal-head-text">
                      Submitted Successfully
                    </div>
                    <div className="i-modal-text">
                      Thank you for filling the form
                    </div>
                    <Link to="/SelectForm" id="sf-link-tag">
                      <button id="i-done-btn">Done</button>
                    </Link>
                  </div>
                </Modal>
              </div>
            </div>
          </form>
        </div>
      ) : null}
      <Modal visible={model} footer={false} closable={false} centered={true}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      </Modal>
    </div>
  );
}

export default SiteForm;
